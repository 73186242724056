import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState } from 'react';
import { FieldErrors, FieldValues, Path, UseFormRegister, UseFormResetField, UseFormSetValue } from 'react-hook-form';

type TextFieldProps<TFormValues extends FieldValues, DefaultValue> = {
  id: Path<TFormValues>;
  label: string;
  placeholder: string;
  append?: React.ReactNode;
  register: UseFormRegister<TFormValues>;
  resetField: UseFormResetField<TFormValues>;
  errors?: FieldErrors<FieldValues>;
  type?: string;
  defaultValue?: DefaultValue;
  setValue?: UseFormSetValue<TFormValues>;
  max?: number;
};
const IconStyle = 'cursor-pointer absolute top-[50%]  right-2 text-grey10 translate-y-[-50%] !text-lg';
const TextField = <TFormValues extends FieldValues, DefaultValue>({
  id,
  label,
  placeholder = '請輸入',
  append,
  register,
  resetField,
  errors,
  type,
  defaultValue,
  setValue,
  max
}: TextFieldProps<TFormValues, DefaultValue>) => {
  const [hasValue, setHasValue] = useState(false);
  const handleResetField = (id: Path<TFormValues>) => {
    resetField(id);
    setHasValue(false);
  };
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (type === 'number') {
      if (max && parseInt(e.target.value) > max) {
        e.target.value = max.toString();
      }
      // 禁止輸入數字以外的文字
      if (/^[0-9]*$/.test(value) === false) {
        e.target.value = value.replace(/[^0-9]/g, '');
      }
    }
    return e.target.value ? setHasValue(true) : setHasValue(false);
  };
  useEffect(() => {
    if (defaultValue && setValue) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setValue(id, defaultValue as any);
      setHasValue(true);
    }
  }, []);
  return (
    <div>
      <div>
        <label className="text-grey30">{label}</label>
      </div>
      <div className="relative">
        <input
          {...register(id)}
          type="text"
          placeholder={placeholder}
          className="border border-white30 rounded-lg w-full p-3 hover:border-blue20 focus:border-blue20 focus:shadow focus:shadow-[0_0_8px_0_rgba(0, 145, 233, 0.20)] relative"
          onChange={handleOnChange}
        />
        {hasValue && !append && <CloseIcon className={IconStyle} onClick={() => handleResetField(id)} />}
        {append && (
          <div className={IconStyle}>{hasValue ? <CloseIcon onClick={() => handleResetField(id)} /> : append}</div>
        )}
      </div>
      {errors && errors[id] && <p className="text-xms text-bright-red ml-4">{errors[id]?.message?.toString()}</p>}
    </div>
  );
};
export default TextField;
