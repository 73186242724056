import ModalContainer from 'react-modal-promise';
import { RouterProvider } from 'react-router-dom';

import Snackbar from './components/Kit/GlobalSnack';
import Modal from './components/Modal/UniversalModal';
import router from './router';

export default function App() {
  return (
    <div>
      <RouterProvider router={router} />
      <Modal />
      <ModalContainer />
      <Snackbar />
    </div>
  );
}
