import BookOutlinedIcon from '@mui/icons-material/BookOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import FavoriteRoundedIcon from '@mui/icons-material/FavoriteRounded';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
import classNames from 'classnames';
import parse from 'html-react-parser';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router';

import { useCarbonCreditStore } from '@/store/carbonCredit';
import { useWishListStore } from '@/store/wishList';
import { carbonColorClass, formatNumberByComma } from '@/util/helper';

import CustomButton from '../CustomButton';
import Navbar from '../Navbar';
import GoalsTile from './GoalsTile';
import ImgSlider from './ImgSlider';
import NavigationTabs from './NavigationTabs';
interface State extends SnackbarOrigin {
  open: boolean;
}
type DetailItemProps = {
  title: string;
  value: string | '';
  children?: React.ReactNode;
};
const DetailItem = ({ title, value, children }: DetailItemProps) => {
  return (
    <div
      className={classNames({
        hidden: !value
      })}
    >
      <p className="product-detail-title">{title}</p>
      <div className="flex text-white">
        <p className="text-lg font-bold tracking-[0.54px]">{value || ''}</p>
        {children}
      </div>
    </div>
  );
};
const detailItemContainer = 'grid grid-cols-3 gap-6 px-2';
const divider = 'h-[1px] bg-light-grey my-[30px] pt-[2.5px]';

const NewProductDetails = () => {
  const mapKey = useMemo(() => process.env.REACT_APP_GOOGLE_MAP_API_KEY, []);
  const [idInWishlist, setIdInWishlist] = useState<number>(0);
  const [isSnackOpen, setIsSnackOpen] = useState<State>({
    open: false,
    vertical: 'top',
    horizontal: 'center'
  });
  const [msg, setMsg] = useState<string>('');
  const { vertical, horizontal, open } = isSnackOpen;
  const param = useParams();
  const carbonCredit = useCarbonCreditStore((state) => state.carbonCredit);
  const getCarbonCredit = useCarbonCreditStore((state) => state.getCarbonCredit);
  const addToWhishList = useWishListStore((store) => store.addToWhishList);
  const deleteWishList = useWishListStore((store) => store.deleteWishList);
  const wishList = useWishListStore((store) => store.wishList);
  const getWishList = useWishListStore((store) => store.getWishList);
  const hanleOpen = () => {
    setIsSnackOpen({ ...isSnackOpen, open: true });
  };
  const handleClose = () => {
    setIsSnackOpen({ ...isSnackOpen, open: false });
  };
  const addLike = async (id: number, msg: string) => {
    await addToWhishList(id);
    setMsg(msg);
    hanleOpen();
  };
  const removeLike = async (id: number, msg: string) => {
    await deleteWishList(id);
    setMsg(msg);
    hanleOpen();
  };
  // console.log(carbonCredit);

  useEffect(() => {
    const idInWishlist = wishList.find((item) => param?.id && item.carbon_credit === +param?.id)?.id || 0;
    setIdInWishlist(idInWishlist);
  }, [wishList]);

  useEffect(() => {
    if (!param.id) return;
    getCarbonCredit(Number(param.id));
    getWishList();
  }, []);

  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const type = params.get('type');

  return (
    <div
      className={classNames('bg-no-repeat bg-cover bg-center h-screen min-h-[700px]', {
        "bg-[url('../public/images/products/green/bg-green.png')]": type === 'green' || !type,
        "bg-[url('../public/images/products/yellow/bg-yellow.png')]": type === 'yellow',
        "bg-[url('../public/images/products/blue/bg-blue.png')]": type === 'blue',
        "bg-[url('../public/images/products/other/bg-other.png')]": type === 'other'
      })}
    >
      <div className="relative py-4">
        <Navbar />
      </div>
      <div className="max-w-[1440px] mx-auto">
        <div className=" overflow-y-scroll overflow-x-hidden yellowScroll 2xl:h-[90vh] h-[87vh] mr-[17px] px-8">
          <img
            src="/scroller-icon.svg"
            alt="sacurn"
            className="w-[41.349px] h-[41.349px] absolute bottom-10 right-12 z-10"
            onClick={() => {
              if (
                document.getElementById('section-2') ||
                document.getElementById('section-3') ||
                document.getElementById('section-4')
              ) {
                document.getElementById('section-1')?.scrollIntoView({ behavior: 'smooth' });
              }
            }}
          />
          <div id="section-1">
            <div className="flex 2xl:gap-10 gap-5 mb-6">
              <div className="max-w-[300px]">
                {carbonCredit.impact_image && (
                  <img
                    src={carbonCredit.impact_image}
                    alt="sacurn"
                    className={classNames(
                      'h-full w-full object-cover border-[5px] rounded-[14px]',
                      carbonColorClass(carbonCredit.carbon_tag, 'border')
                    )}
                  />
                )}
              </div>

              <div className="font-istok-web w-full min-h-[203px] flex flex-col justify-between">
                <div>
                  <h2 className="2xl:text-[30px] text-[22px] font-bold text-white leading-normal font-istok-web mb-4">
                    {carbonCredit.name}
                  </h2>
                  <div className="flex text-white mb-3">
                    <LocationOnOutlinedIcon fontSize="small" className="mr-1" />
                    <span className="mr-4">Region</span>
                    <span className="font-bold"> {carbonCredit.location}</span>
                  </div>
                  <div className="flex text-white">
                    <BookOutlinedIcon fontSize="small" className="mr-1" />
                    <p className="mr-4">Carbon Credit Registry</p>
                    <p className="font-bold">{carbonCredit.registry}</p>
                    {carbonCredit?.registry_link && (
                      <a href={carbonCredit?.registry_link || ''} target="_blank">
                        <InsertLinkIcon className="cursor-pointer ml-2" />
                      </a>
                    )}
                  </div>
                </div>
                <div className="flex gap-4 justify-end">
                  {idInWishlist > 0 ? (
                    <CustomButton
                      className="bg-white rounded-lg py-3 px-4 !text-dark-grey border-white border w-fit self-end"
                      onClick={() => removeLike(idInWishlist, 'Removed from wishlist')}
                    >
                      Remove from Wish List
                      <FavoriteRoundedIcon className="ml-1" />
                    </CustomButton>
                  ) : (
                    <CustomButton
                      className="bg-pale-yellow rounded-lg py-3 px-4 !text-dark-grey border-white border w-fit self-end"
                      onClick={() => addLike(carbonCredit?.id, 'Added to wishlist')}
                    >
                      Add to Wish List
                      <FavoriteBorderOutlinedIcon className="ml-1" />
                    </CustomButton>
                  )}
                  <CustomButton
                    className="!bg-dark-grey rounded-lg py-3 px-4  border-white border w-fit self-end hover:!bg-grey30"
                    onClick={() => navigate(`/product-carbon/${param.id}`)}
                  >
                    Price
                    <PaidOutlinedIcon className="ml-1" />
                  </CustomButton>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="border bg-[#1b1b1b4d] border-pale-yellow pt-[33px] px-[30px]">
              <NavigationTabs active={1} className="mb-[21px]" />
              <div className="gap-[72px] flex items-center justify-center">
                <div className="h-[1px] bg-white w-[32%]"></div>
                <h3 className="text-[28px] leading-10 text-white font-bold my-4 text-nowrap">Project Description</h3>
                <div className="h-[1.3px] bg-white w-[32%]"></div>
              </div>
              <div className="text-white indent-9 text-justify font-istok-web text-xl font-normal leading-7 tracking-[1.4px] mb-10 parse-content">
                <p>{carbonCredit.information ? parse(carbonCredit.information) : ''}</p>
              </div>
              <div className={detailItemContainer}>
                <DetailItem title="Project ID" value={carbonCredit.project_code || ''}>
                  {carbonCredit.project_link && (
                    <a href={carbonCredit?.project_link || ''} target="__blank">
                      <InsertLinkIcon className="cursor-pointer ml-2" />
                    </a>
                  )}
                </DetailItem>
                <DetailItem title="Geographic Location" value={carbonCredit.location || ''} />
                <DetailItem title="Project Type" value={carbonCredit.type || ''} />
                <DetailItem title="Vintages" value={carbonCredit.vintage || ''} />
                <DetailItem
                  title="Validation-Verification Body"
                  value={carbonCredit.validation_verification_body || ''}
                />
                <DetailItem title="Project Developer" value={carbonCredit.developer || ''} />
              </div>

              <div className={divider} />

              <div className={detailItemContainer}>
                <DetailItem title="Standard" value={carbonCredit.standard || ''} />
                <DetailItem title="Methodology" value={carbonCredit.methodology || ''} />
                <DetailItem title="CORSIA Eligibility" value={carbonCredit.corsia_eligibility || ''} />
                <DetailItem title="Additional Certifications" value={carbonCredit.additional_certifications || ''} />
              </div>

              <div className={divider} />

              <div className={detailItemContainer}>
                <DetailItem title="Total Issuance" value={carbonCredit.volume_issued || ''} />
                <DetailItem
                  title="Estimated Annual Emission Reductions"
                  value={carbonCredit.avg_annual_emission_reduction || ''}
                />
                <DetailItem title="Crediting Period" value={carbonCredit.crediting_period || ''} />
              </div>

              <div className={divider} />

              <p className="product-detail-title mb-2">Satellite Image</p>

              {carbonCredit.map_url ? (
                <div className="w-full h-[600px]">
                  <iframe
                    loading="lazy"
                    style={{ border: 0, margin: 'auto', maxWidth: '100%', width: '100%', height: '100%' }}
                    allowFullScreen
                    src={`${carbonCredit.map_url}&key=${mapKey}`}
                  ></iframe>
                </div>
              ) : (
                <div className="w-full h-[700px]">
                  <img src={carbonCredit.map || ''} alt="impact" className="w-full h-full object-cover" />
                </div>
              )}

              <div id="section-3"></div>
              <NavigationTabs active={3} className="mt-15 mb-12" />
              <div className="max-w-[1000px] mx-auto">
                <ImgSlider images={carbonCredit.image_list || []} />
              </div>
              <h3 className="text-[22px] font-bold tracking-[0.75px] text-white text-center px-3 border-l-4 border-r-4 border-white w-fit mx-auto my-[33px]">
                This project contributes to {carbonCredit.sdg_list?.length || 0} Sustainable Development Goals
              </h3>
              <div className="grid grid-cols-2 mb-7 gap-y-5 gap-x-[12.4%] max-w-[1635px] mx-auto" id="section-4">
                {carbonCredit.sdg_list?.map((sdgItem) => (
                  <GoalsTile
                    key={sdgItem.id}
                    img={sdgItem.icon}
                    title={sdgItem.name}
                    description={sdgItem.desc}
                    content={sdgItem.content}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={handleClose}
        message={msg}
        key={vertical + horizontal}
      />
    </div>
  );
};

export default NewProductDetails;
