import { Tooltip } from '@material-ui/core';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { blue, grey } from '@mui/material/colors';
import classNames from 'classnames';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import NoticeScrollModal from '@/components/Modal/NoticeScrollModal';
// import { ExtendedCart as CartItemType } from '@/libs/api';
import { CheckoutResult, useCartStore } from '@/store/cart';
import { ModalType, useModalStore } from '@/store/modal';
import { useSnackbar } from '@/store/snackBar';
// import { DeleteCart, MinusRounded, PlusRounded } from '@/svg';
// import { OrderStatus } from '@/type';
// import { MIN_CART_QTY } from '@/util/constants';
import { formatNumberByComma } from '@/util/helper';
// import isValidNumber from '@/util/isValidNumber';
import { useInnerHeight } from '@/util/useInnerHeight';

import Navbar from '../components/Navbar';
import CartItem from './CartItem';

interface HeadingIProps {
  children: React.ReactNode;
}

const TAX_PERCENTAGE = 0.05;
const selectBtn =
  'py-2 px-4 flex items-center gap-1 bg-grey text-white rounded-[10px] cursor-pointer active:bg-dark-grey shadow-sm';
const orderItemText = 'flex justify-between text-grey my-4 leading-5 gap-2 text-sm xl:text-base';

const Cart = () => {
  const navigate = useNavigate();
  const { openSnackbar } = useSnackbar();

  const cartList = useCartStore((store) => store.cartList);
  const getCartList = useCartStore((store) => store.getCartList);
  const cartDetail = useCartStore((store) => store.cartDetail);
  const updateCartItemSelected = useCartStore((store) => store.updateCartItemSelected);
  const isSelectedAll = useCartStore((store) => store.isSelectedAll);
  const setAllCartItemSelect = useCartStore((store) => store.setAllCartItemSelect);
  const deleteSelectedCartItem = useCartStore((store) => store.deleteSelectedCartItem);
  const checkPassMinOrderThreshold = useCartStore((store) => store.checkPassMinOrderThreshold);
  const checkOutCart = useCartStore((store) => store.checkOutCart);

  const open = useModalStore((store) => store.open);
  const innerHeight = useInnerHeight();
  const [confirmModal, setConfirmModal] = useState(false);
  const [checkoutDetail, setCheckoutDetail] = useState<CheckoutResult['checkoutDetail']>(null);
  // const [isChecked, setIsChecked] = useState(false);
  // const [error, setError] = useState(false);

  const init = async () => {
    await getCartList();
    setAllCartItemSelect(true);
  };
  const handleConfirmModal = (open: boolean) => {
    setConfirmModal(open);
  };
  useEffect(() => {
    init();
  }, []);
  // TODO: SOS
  // useEffect(() => {
  //   console.log({ cartDetail });
  //   if (cartDetail && cartDetail?.product_list?.length > 0) return;
  //   if (cartList.length > 0 && !cartDetail) {
  //     console.log(123);

  //     setAllCartItemSelect(true);
  //     return;
  //   }
  // }, [cartList]);

  const taxPercentage = useMemo(() => {
    return (cartDetail?.tax_ratio || TAX_PERCENTAGE) * 100;
  }, []);
  const onDeleteCartItem = useCallback(() => {
    open(ModalType.DeleteCartItem, {
      buttons: [
        { text: '取消', isOutline: true },
        {
          text: '確定',
          onClick: () => {
            deleteSelectedCartItem();
            openSnackbar('購物車已清空');
          }
        }
      ]
    });
  }, [cartList]);
  const onCheckOut = async () => {
    const result = await checkOutCart();
    console.log({ result });
    if (result.isSuccess) {
      setCheckoutDetail(result.checkoutDetail);
      navigate(`/payment-information/${result.checkoutDetail?.id}`);
    }
  };
  return (
    <div className="cart bg-neutral-150 h-screen overflow-hidden">
      <Navbar className="h-[70px] !bg-navy-blue" />
      <div className="px-10">
        <div className="flex justify-between items-center my-4 xl:pr-5 w-[65%]">
          {cartList && cartList?.length > 0 && (
            <Link to="/">
              <button className="rounded-lg px-4 py-2 flex items-center justify-center border border-navy-blue text-navy-blue text-sm whitespace-nowrap">
                {/* <img src="/images/cart/ic_back.svg" width={16} height={14} className="mr-2.5" alt="sacurn" /> */}
                <ArrowBackIcon className="text-navy-blue mr-1" sx={{ fontSize: '20px' }} />
                繼續購物
              </button>
            </Link>
          )}
          <p className="text-3xl mx-4 whitespace-nowrap block text-navy-blue mr-auto">
            <span className="mr-3 font-bold">|</span>購物車
          </p>
          {cartList && cartList?.length > 0 && (
            <>
              <div
                className={classNames(selectBtn, 'ml-auto mr-2', {
                  '!bg-dark-grey': cartList.every((item) => item.selected === true)
                })}
                onClick={() => {
                  setAllCartItemSelect(!isSelectedAll());
                }}
              >
                {isSelectedAll() ? <CheckCircleIcon /> : <RadioButtonUncheckedIcon />}
                <span className="text-sm whitespace-nowrap">全選</span>
              </div>
              <div
                className={classNames(
                  selectBtn,
                  !cartList.some((item) => item.selected === true) && '!bg-white30 cursor-default shadow-none'
                )}
                onClick={onDeleteCartItem}
              >
                <DeleteOutlineOutlinedIcon />
                <span className="text-sm whitespace-nowrap">刪除選取</span>
              </div>
            </>
          )}
        </div>
        {cartList && cartList?.length > 0 ? (
          <div className="flex flex-row gap-6 pb-10">
            {/* Cart List */}
            <div
              className={classNames(
                'w-[65%]  min-w-[455px] pb-4 flex gap-4 flex-col yellowScroll yellowScrollHorizontal overflow-y-auto  rounded-[10px] pr-3'
              )}
              style={{ maxHeight: innerHeight - 160 }}
            >
              {cartList.map((item, index) => (
                <CartItem
                  key={item.id}
                  onSelectedChange={(selected: boolean) => {
                    updateCartItemSelected(item.id, index, selected);
                  }}
                  {...item}
                />
              ))}
            </div>
            {/* Order Info */}
            <div className="bg-white rounded-xl min-w-[340px] w-[35%] py-6 px-4">
              <div className="flex flex-col justify-between h-full">
                <div className="h-full">
                  <h2 className="text-lg font-bold">
                    訂單資訊 （共 {(cartDetail && cartDetail.product_list?.length) || 0} 件）
                  </h2>
                  <p className="text-xs xl:text-sm text-grey">商品需達到最低金額 $1000 USD 才能進行結帳。</p>
                  <hr className="border-b border-[#fafafa] my-3" />

                  {cartDetail ? (
                    <div className="overflow-auto pr-2" style={{ maxHeight: innerHeight - 390 }}>
                      {cartDetail.product_list?.map((product, idx) => {
                        return (
                          <div key={product.name + idx} className={orderItemText}>
                            <Tooltip title={product.name} placement="bottom" arrow>
                              <p className="w-[70%] line-clamp-2">{product.name}</p>
                            </Tooltip>
                            <p className="whitespace-nowrap">
                              {formatNumberByComma(product.amount.toString() as string)} USD
                            </p>
                          </div>
                        );
                      })}
                      <div className={orderItemText}>
                        <p className="w-[70%] line-clamp-2">手續費</p>
                        <p className="whitespace-nowrap">
                          {formatNumberByComma(cartDetail?.cost?.toString() as string)} USD
                        </p>
                      </div>
                      <div className={orderItemText}>
                        <p className="w-[70%] line-clamp-2">稅金5%</p>
                        <p className="whitespace-nowrap">
                          {formatNumberByComma(cartDetail?.tax?.toString() as string)} USD
                        </p>
                      </div>
                    </div>
                  ) : (
                    <div className="flex justify-center items-center h-full">
                      <span className="text-grey">您尚未選取商品</span>
                    </div>
                  )}
                </div>
                <div>
                  <hr className="border-b border-[#fafafa] my-3" />
                  <div className="flex justify-between">
                    <div>
                      <h2 className="text-lg font-bold">總金額</h2>
                      {/* <p className="text-xs xl:text-sm text-grey">單筆交易商品總金額需超過 1,000 USD</p> */}
                    </div>
                    <div>
                      <h2 className="text-xl font-bold">
                        {cartDetail?.total_amount
                          ? formatNumberByComma(cartDetail?.total_amount?.toString() as string)
                          : 0}
                        USD
                      </h2>
                    </div>
                  </div>
                  <hr className="border-b border-[#fafafa] my-3" />
                  <div className="text-center">
                    <button
                      onClick={async () => {
                        // if (!isChecked) return setError(true);
                        const isPass = await checkPassMinOrderThreshold();
                        if (isPass) {
                          handleConfirmModal(true);
                        } else {
                          open(ModalType.NotPassMinOrderThreshold);
                        }
                      }}
                      className={classNames('w-[60%] py-2 rounded-full text-white mx-auto my-2', {
                        ['bg-navy-blue']: cartDetail && cartDetail.product_list?.length,
                        ['bg-grey']: !cartDetail || cartDetail.product_list?.length === 0
                      })}
                    >
                      前往結帳
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="yellowScroll overflow-auto" style={{ maxHeight: innerHeight - 160 }}>
            <div className="flex flex-col justify-center items-center h-full gap-10 mt-20 ">
              <img src="/images/cart/emptyCart.png" />
              <span className="text-grey">尚未有商品加入購物車</span>
              <Link to="/">
                <button className="py-2 w-[200px] rounded-full text-white mx-auto my-2 bg-navy-blue hover:bg-blue20">
                  去商品頁逛逛
                </button>
              </Link>
            </div>
          </div>
        )}
      </div>
      <NoticeScrollModal
        isOpen={confirmModal}
        close={() => handleConfirmModal(false)}
        onCheckout={onCheckOut}
        buttonText="同意並結帳"
      />
    </div>
  );
};

export default Cart;

const Heading: FC<HeadingIProps> = ({ children }) => {
  return (
    <div className="border-l-8  border-l-pale-yellow pl-5 text-black 2xl:text-lg text-base font-bold">{children}</div>
  );
};
