import { ReactNode, useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { useCompanyStore } from '@/store/company';
import { CompanyStatus } from '@/type';

type ProtectedCompanyRegisteredRoute = {
  children: ReactNode;
};

export const ProtectedCompanyRegisteredRoute = ({ children }: ProtectedCompanyRegisteredRoute) => {
  const [isLoadedNewCompany, setLoadedNewCompany] = useState<boolean>(false);

  const company = useCompanyStore((state) => state.company);
  const getCompany = useCompanyStore((state) => state.getCompany);
  const getCompanyEmployee = useCompanyStore((state) => state.getCompanyEmployee);
  const location = useLocation();

  useEffect(() => {
    if (company?.status) {
      setLoadedNewCompany(true);
    } else {
      getCompanyEmployee();
      getCompany().then(() => setLoadedNewCompany(true));
    }
  }, []);

  if (isLoadedNewCompany) {
    if (!company || company.status?.toString() !== CompanyStatus.PassReview.toString()) {
      if (location.pathname !== '/company-registration') {
        return <Navigate to="/company-registration" />;
      }
    }

    return children;
  }
  return <></>;
};
