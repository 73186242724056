import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import HistoricalLayout from '@/components/Layout/HistoricalLayout';
import useOrder from '@/hooks/useOrder';
import { useOrderStore } from '@/store/order';
import { useTransactionSellStore } from '@/store/transactionSell';
import type { Filters } from '@/types/historical';
import { OrderStatus } from '@/types/historical';

const MyStore = () => {
  // API
  const transactionSellList = useTransactionSellStore((state) => state.transactionSellList);
  const updateTransactionSellListByFilters = useTransactionSellStore(
    (state) => state.updateTransactionSellListByFilters
  );
  //
  const { headersData, headers, transStatus, setHeaders } = useOrder();
  const { status } = useParams();
  const setPage = useOrderStore((state) => state.setPage);
  const getData = async (filter: Filters) => {
    console.log('mystore:get data');
    await updateTransactionSellListByFilters(filter);
  };

  useEffect(() => {
    setPage('my-store');
  }, []);

  useEffect(() => {
    const statusCode: string = status ? transStatus(status) : '0';
    updateTransactionSellListByFilters({
      status: statusCode
    });
    if (status === OrderStatus.HistoricalOrder.toString() && Array.isArray(headers)) {
      setHeaders([...headers, { title: '訂單狀態', key: 'orderStatus' }]);
    } else {
      setHeaders(headersData);
    }
  }, [status]);
  return (
    <HistoricalLayout title="我的賣場" color="green" headers={headers} orders={transactionSellList} getData={getData} />
  );
};
export default MyStore;
