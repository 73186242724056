import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import dateFormat from 'dateformat';
import dayjs from 'dayjs';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import * as yup from 'yup';

import Button from '@/components/Kit/Button';
import OutlineRounedSearchBar from '@/components/OutlineRounedSearchBar';
import { BASE_URL } from '@/constant';
import { OperationRecord } from '@/libs/api';
import { useHistoryStore } from '@/store/history';
import { TableBodyItem } from '@/types';
import calcRange from '@/util/calcRange';
import { useInnerHeight } from '@/util/useInnerHeight';

import CustomSelect from '../components/CustomSelect';
import CustomTable from '../components/CustomTable';
import DatePickerModal from '../components/DatePickerModal';
import Navbar from '../components/Navbar';
import formatDate from '../helpers/formatDate';

const schema = yup
  .object({
    keyword: yup.string().optional(),
    page: yup.number().positive().integer().min(1).optional(),
    range: yup.string().optional(),
    status: yup.string().optional(),
    user: yup.string().optional()
  })
  .required();

const filterTitleStyle = 'block text-sm font-medium leading-6 text-grey whitespace-nowrap';

const OperationRecordPage = () => {
  const innerHeight = useInnerHeight();
  const [open, setOpen] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([null, null]);
  const [startDate, endDate] = dateRange;
  const [operationRecordList, setOperationRecordList] = useState<OperationRecord[]>([]);
  const [data, setData] = useState<yup.InferType<typeof schema>>({
    keyword: '',
    page: 1,
    range: '',
    status: '',
    user: ''
  });

  const [statusOptions, userOptions, getHistoryOptions, getOperationHistoryList] = useHistoryStore((state) => [
    state.statusOptions,
    state.userOptions,
    state.getHistoryOptions,
    state.getOperationHistoryList
  ]);

  const onSubmit = async (args: yup.InferType<typeof schema>) => {
    const newData = {
      ...data,
      ...args
    };
    setData(newData);
    const operationRecordList = await getOperationHistoryList(
      '0',
      newData.keyword,
      newData.page,
      newData.range,
      newData.status,
      newData.user
    );
    setOperationRecordList(operationRecordList);
  };

  useEffect(() => {
    getHistoryOptions();

    const startDate = dayjs().subtract(14, 'day').toDate();
    const endDate = dayjs().toDate();
    onSubmit({
      range: calcRange(startDate, endDate)
    });
    setDateRange([startDate, endDate]);
  }, []);

  const tableBody: TableBodyItem[] = useMemo(() => {
    return operationRecordList.map((record) => ({
      id: record.id,
      time: dateFormat(record.created_at, 'yyyy/mm/dd HH:MM:ss'),
      prodName: record.product_name || '-',
      operator: record.username,
      unitPrice: `$${record.price}` || '-',
      quant: record.quantity?.toString() || '-',
      lumpsum: `$${record.total_amount}` || '-',
      action: record.action || '-'
      // remark: record.note || ''
    }));
  }, [operationRecordList]);

  return (
    <div className="w-screen relative h-screen overflow-hidden bg-neutral-150">
      {/* navbar */}
      <Navbar className="relative z-30 !bg-navy-blue h-[70px]" />
      <section className="px-9 pt-5">
        <div className="flex items-center mb-6">
          <h1 className="text-xl xl:text-2xl whitespace-nowrap text-navy-blue mr-4">
            <span className="mr-2 font-semibold">|</span>
            操作記錄
          </h1>
          {/* filters */}
          <div className="ml-auto flex items-center gap-6">
            {/* period */}
            <div className="flex items-center relative">
              {/* date picker modal */}
              {open && (
                <DatePickerModal
                  startDate={startDate}
                  endDate={endDate}
                  setDateRange={(dateList) => {
                    if (dateList[0] && dateList[1]) {
                      onSubmit({
                        range: calcRange(dateList[0], dateList[1])
                      });
                    }
                    setDateRange(dateList);
                  }}
                  setOpen={setOpen}
                  open={open}
                />
              )}
              <label htmlFor="period" className={filterTitleStyle}>
                期間:
              </label>
              <div
                className="flex items-center cursor-pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  setOpen(!open);
                }}
              >
                <div className="ml-[15px] text-dark-grey text-sm font-black mr-2">
                  {startDate && endDate ? (
                    <>
                      {formatDate(startDate)} - {formatDate(endDate)}
                    </>
                  ) : (
                    <>
                      {formatDate(new Date())} - {formatDate(new Date())}
                    </>
                  )}
                </div>
                <KeyboardArrowDownIcon fontSize="small" className="text-grey" />
              </div>
            </div>

            {/* status */}
            <div className="flex items-center relative">
              <label htmlFor="state" className={filterTitleStyle}>
                狀態:
              </label>
              <div className="ml-[15px]">
                <CustomSelect
                  options={statusOptions}
                  defaultValue=""
                  placeholder="請選擇操作者"
                  callback={(status) => {
                    onSubmit({ status });
                  }}
                />
              </div>
            </div>
            {/* operator */}
            <div className="flex items-center">
              <label htmlFor="operator" className={filterTitleStyle}>
                操作者:
              </label>
              <div className="ml-[15px]">
                <CustomSelect
                  options={userOptions}
                  defaultValue=""
                  placeholder="請選擇狀態"
                  callback={(user) => {
                    onSubmit({ user });
                  }}
                />
              </div>
            </div>
            {/* keyword */}
            <OutlineRounedSearchBar placeholder="請輸入碳權名稱或是關鍵字搜尋" getData={onSubmit} />
            {/* download */}
            <a
              href={`${BASE_URL}/trade/operation_record/?${new URLSearchParams({
                ..._.omitBy(data, _.isEmpty),
                download: '1'
              }).toString()}`}
              target="_blank"
              download
            >
              <Button className="!bg-grey flex items-center gap-1 hover:!bg-grey10 active:!bg-dark-grey text-sm !px-2 lg:!px-4">
                <FileDownloadOutlinedIcon fontSize="small" />
                Download
              </Button>
            </a>
          </div>
        </div>

        {/* record table */}
        <div
          className="yellowScroll h-[80vh] pr-3 overflow-auto overflow-x-hidden"
          style={{ height: innerHeight - 180 }}
        >
          <CustomTable tableHeadings={TABLE_HEAD} tableBody={tableBody} name="operation_page" />
        </div>
      </section>
    </div>
  );
};

export default OperationRecordPage;

const TABLE_HEAD = ['操作時間', '商品名稱', '操作者', '單價', '數量(噸)', '總金額', '狀態'];
