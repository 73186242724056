import { ApexOptions } from 'apexcharts';
import { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';

import { useInventoryStore } from '@/store/inventory';
import { useStockListStore } from '@/store/stockList';
import { AREA_TRANS } from '@/util/constants';

type SalesPieGraphProps = {
  activeButton: number;
  colors: string[];
};
const SalesPieGraph = ({ activeButton, colors }: SalesPieGraphProps) => {
  const [series, setSeries] = useState<number[]>([]);
  const [labels, setLabels] = useState<string[]>([]);
  const getCategoriesData = useInventoryStore((state) => state.getCategoriesData);
  const categoriesData = useInventoryStore((state) => state.categoriesData);
  const stockList = useStockListStore((store) => store.stockList);

  const options: ApexOptions = {
    states: {
      active: {
        filter: {
          type: 'none' /* none, lighten, darken */
        }
      }
    },
    plotOptions: {
      pie: {
        customScale: 1,
        dataLabels: {
          offset: -20
        }
      }
    },
    labels: labels,
    colors: colors,
    chart: {
      selection: {
        enabled: false
      },
      type: 'pie',
      width: 200,
      events: {
        animationEnd: function (ctx: ApexCharts) {
          ctx.toggleDataPointSelection(2);
        }
      }
    },
    dataLabels: {
      enabled: true,
      style: {
        fontWeight: 'normal'
      },
      formatter: function (val: string | number | number[], opt) {
        return Number(val) > 10 ? `${opt.w.globals.labels[opt.seriesIndex]} ${Number(val).toFixed(1)}%` : '';
      }
    },
    legend: {
      show: false
    },
    tooltip: {
      y: {
        formatter: function (val: number) {
          return val.toFixed(0) + '%';
        }
      }
    }
  };

  useEffect(() => {
    getCategoriesData();
  }, []);

  useEffect(() => {
    if (categoriesData?.category && activeButton === 1) {
      const categoriesDataKeys = Object.keys(categoriesData.category);
      const categoriesDataValues = Object.values(categoriesData.category);
      if (categoriesDataValues.length < 3) {
        const diff = 3 - categoriesDataValues.length;
        for (let i = 0; i < diff; i++) {
          categoriesDataValues.push(0);
        }
      }

      setSeries(categoriesDataValues.map((value) => Math.round(Number(value * 100))));
      setLabels(categoriesDataKeys);
    }
    if (categoriesData?.location && activeButton === 2) {
      let categoriesDataKeys = Object.keys(categoriesData.location);
      categoriesDataKeys = categoriesDataKeys.map((key) => AREA_TRANS[key as keyof typeof AREA_TRANS]);
      const categoriesDataValues = Object.values(categoriesData.location);
      if (categoriesDataValues.length < 3) {
        const diff = 3 - categoriesDataValues.length;
        for (let i = 0; i < diff; i++) {
          categoriesDataValues.push(0);
        }
      }
      setSeries(categoriesDataValues.map((value) => Math.round(Number(value * 100))));
      setLabels(categoriesDataKeys);
    }
  }, [categoriesData, activeButton]);

  return (
    <div>
      {/* graph */}
      <div className="donut">
        <Chart options={options} series={series} type="pie" width={300} />
      </div>
    </div>
  );
};

export default SalesPieGraph;
