import classNames from 'classnames';
import { DeepMap, FieldError, FieldValues, Path, UseFormRegister } from 'react-hook-form';

// import { UserInfoFormValues } from './UserInfoForm';
interface CustomInputIProps<TFormValues extends FieldValues> {
  id: Path<TFormValues>;
  register: UseFormRegister<TFormValues>;
  type: string;
  placeholder?: string;
  label?: string;
  className?: string;
  errors?: Partial<DeepMap<TFormValues, FieldError>>;
  isCreate?: boolean;
}

const CustomInput = <TFormValues extends FieldValues>({
  id,
  register,
  type,
  placeholder,
  label,
  className,
  errors,
  isCreate
}: CustomInputIProps<TFormValues>) => {
  return (
    <div
      className={classNames(
        'flex items-center min-[1600px]:gap-7.5 min-[1500px]:gap-6 min-[1300px]:gap-5.5 gap-5 max-w-max',
        {
          'mb-0.5': errors && errors[id]
        }
      )}
    >
      <p className="2xl:text-xl xl:text-lg text-base text-navy-blue text-right font-bold min-w-[84px] tracking-[0.6px] leading-normal font-istok-web">
        {label}
      </p>
      <div className={classNames('flex flex-col relative', { 'gap-0.5': errors && errors[id] })}>
        <input
          className={classNames(
            'font-istok-web rounded-full shadow-input-field h-[45px] 2xl:w-[296px] min-[1500px]:w-[260px] w-[215px] px-[23px] py-1 outline-none text-xl font-bold tracking-[0.6px] text-dark-grey',
            className
          )}
          {...register(id)}
          placeholder={placeholder}
          type={type}
        />
        {isCreate && label === '電話' && (
          <p className="text-[8px] 2.5xl:text-sm text-grey leading-normal font-normal">
            例：市話XX-XXXXXXXX 或手機XXXXXXXXXX
          </p>
        )}
        <div className="relative w-full">
          {errors && errors[id] && (
            <p className="min-[1500px]:text-xs min-[1200px]:text-xms text-xxs text-bright-red ml-4">
              {errors[id]?.message}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default CustomInput;
