import MemberLayout from '@/components/Layout/MemberLayout';
import MemberProfileUpdate from '@/components/v2/MemberProfileUpdate';

const MemberProfile = () => {
  return (
    <MemberLayout>
      <MemberProfileUpdate />
    </MemberLayout>
  );
};

export default MemberProfile;
