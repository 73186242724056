import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import classNames from 'classnames';
import React from 'react';

import { useModalStore } from '@/store/modal';
import { UniversalModalStatus } from '@/types';
export type UniversalModalProps = {
  status: UniversalModalStatus;
  icon?: string;
  headline?: string;
  title?: string;
  description: string;
  errorText?: string;
  buttons: {
    text: string;
    isOutline?: boolean;
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
    isNotCloseAfterClick?: boolean;
  }[];
};

function UniversalModal() {
  const { isOpen, data, close } = useModalStore();

  if (!data) return null;

  const { status, headline, icon, title, description, errorText, buttons } = data;

  const isLoading = status === UniversalModalStatus.Loading;

  return (
    <>
      {isOpen && (
        <div className="fixed top-0 left-0 z-[1500] w-full h-full bg-[#3D3D3D99] flex justify-center items-center">
          <div
            className={classNames('relative p-6 bg-white max-w-[600px] rounded-2xl flex flex-col', {
              'w-[477px]': status === UniversalModalStatus.Success,
              'h-[461px]': status === UniversalModalStatus.Success,
              '!max-w-[400px] min-w-[300px]': status === UniversalModalStatus.Base,
              'items-center': icon !== ''
            })}
          >
            {!isLoading && (
              // <img
              //   src="/images/products-page/ic_circle_close.svg"
              //   alt="close"
              //   width={32}
              //   height={32}
              //   className="absolute top-2.5 right-3 cursor-pointer"
              //   onClick={close}
              // />
              <CancelOutlinedIcon className="text-grey10 absolute top-6 right-6 cursor-pointer" onClick={close} />
            )}

            {headline && (
              <h2 className="text-[32px] leading-[38px] font-semibold mt-[24px] mb-[24px] tracking-wide">{headline}</h2>
            )}
            {icon && (
              <img src={icon} alt="icon" width={60} height={60} className={isLoading ? 'animate-spin' : 'mt-6'} />
            )}
            {title && <h3 className="mt-6 mb-2 text-xl text-dark-grey font-semibold ">{title}</h3>}

            {!isLoading && (
              <p
                className={classNames('text-dark-grey', {
                  'mt-[35px]': status === UniversalModalStatus.Success,
                  'mt-[24px]': status === UniversalModalStatus.Info
                })}
              >
                {description}
              </p>
            )}

            {!isLoading && errorText && <p className="text-bright-red mt-4">{errorText}</p>}
            {!isLoading && (
              <div className="flex justify-center gap-5 mt-6">
                {buttons.map((button) => {
                  return (
                    <button
                      key={button.text}
                      className={classNames('px-6 py-2 rounded-[60px] font-normal', {
                        'text-white bg-navy-blue': !button.isOutline,
                        'text-navy-blue bg-white border	border-current': !!button.isOutline
                      })}
                      onClick={(e) => {
                        button.onClick?.(e);
                        if (button.isNotCloseAfterClick) {
                          return;
                        }
                        close();
                      }}
                    >
                      {button.text}
                    </button>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default UniversalModal;
