import * as yup from 'yup';

const last_name = yup.string().required('姓名為必填項');
const position = yup.string().required('職稱名稱為必填');
const username = yup.string().required('帳號名稱為必填');
const password = yup.string().required('密碼為必填');
const email = yup.string().email('請輸入正確信箱格式').required('電子郵件為必填');
const tel = yup
  .string()
  .required('例如：0x-00001111 或 09xx-000111')
  .matches(/^(09\d{8}|0[1-8]\d{7,8})$/, '例如：0x-00001111 或 09xx-000111');
const tel_extension = yup.string().optional();
const group_name = yup.string().required('需要操作權限');
const confirm = yup
  .boolean()
  .default(false)
  .required('請務必確認勾選此框。')
  .test('is-true', '請務必確認勾選此框。', (value) => value === true);

export const loginSchema = yup
  .object({
    username: username,
    password: password
  })
  .required();
export const userInfoSchema = yup
  .object({
    username: username,
    last_name: last_name,
    position: position,
    email: email,
    tel: tel,
    tel_extension: tel_extension,
    group_name: group_name,
    confirm: confirm
  })
  .required();
export const memberProfileUpdateSchema = yup
  .object({
    username: username,
    last_name: last_name,
    position: position,
    email: email,
    tel: tel,
    tel_extension: tel_extension,
    group_name: group_name
  })
  .required();
export const accountInfoSchema = yup
  .object({
    last_name: last_name,
    position: position,
    email: email,
    tel: tel,
    tel_extension: tel_extension
  })
  .required();
