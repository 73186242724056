import dayjs from 'dayjs';
import { create } from 'zustand';

import { TransactionSellSerializers } from '@/libs/api';
import apiClient from '@/libs/api/client';
import type { Filters } from '@/types/historical';
import calcRange from '@/util/calcRange';

import { runTask } from './modal';

export type TransactionSellState = {
  transactionSellList: TransactionSellSerializers[];
  filters: Filters;
  updateTransactionSellListByFilters: (filters: Filters) => void;
  getTransactionSellList: (...arg: Parameters<typeof apiClient.trade.tradeTransactionSellList>) => void;
};

export const useTransactionSellStore = create<TransactionSellState>((set, get) => ({
  transactionSellList: [],
  filters: {
    range: undefined,
    status: '0'
  },
  updateTransactionSellListByFilters: (filters: Filters) => {
    set({
      filters: {
        ...get().filters,
        ...filters
      }
    });
    get().getTransactionSellList();
  },
  getTransactionSellList: async () => {
    await runTask(async () => {
      const filters = get().filters;
      if (!filters.range) {
        const startDate = dayjs().subtract(14, 'day').toDate();
        const endDate = dayjs().toDate();
        filters.range = `${calcRange(startDate, endDate)}`;
      }
      const response = await apiClient.trade.tradeTransactionSellList(
        filters.keyword,
        filters.page,
        filters.range,
        filters.status
      );
      set({ transactionSellList: response.results });
    });
  }
}));
