import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';

import CustomButton from '@/components/CustomButton';
import { useForgotPasswordStore } from '@/store/forgotPassword';
import { MEMBERS_TERMS } from '@/util/constants';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));
const PasswordRecoveryTermsModal = () => {
  const isTermsModalOpen = useForgotPasswordStore((state) => state.isTermsModalOpen);
  const setIsTermsModalOpen = useForgotPasswordStore((state) => state.setIsTermsModalOpen);
  const setIsAgreedToTerms = useForgotPasswordStore((state) => state.setIsAgreedToTerms);
  // const setIsDoNotAgreeTermsModalOpen = useForgotPasswordStore((state) => state.setIsDoNotAgreeTermsModalOpen);

  return (
    <div>
      <BootstrapDialog
        onClose={() => setIsTermsModalOpen(false)}
        aria-labelledby="customized-dialog-title"
        open={isTermsModalOpen}
        fullWidth={true}
        maxWidth="md"
        sx={{
          '& .MuiPaper-rounded': {
            borderRadius: '20px'
          }
        }}
      >
        <DialogContent>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center'
            }}
            className="font-istok-web"
          >
            <Typography
              sx={{ m: 0, color: '#525252', fontWeight: 700, fontSize: '18px', textAlign: 'center', pb: '15px' }}
              id="customized-dialog-title"
              className="font-istok-web text-dark-grey font-bold text-lg"
            >
              本網站之《服務條款》、《隱私政策》和《Cookie 政策》
            </Typography>
            <div className="yellowScroll overflow-y-scroll h-[350px] min-[1400px]:h-[600px]  w-full">
              <Box sx={{ background: '#F5F5F5', width: '98%', p: '11px' }}>
                <div className="text-dark-grey font-bold leading-5">
                  <p className="text-xs">
                    感謝您使用土星永續股份有限公司（下稱「本公司」）所提供的平台（下稱「本平台」）以進行本公司所提供之碳權憑證申購、銷售以及專案碳權抵換服務（下合稱「本服務」），您茲同意當您存取本公司網站（下稱「本網站」）並使用本服務時，
                    將受以下條款及條件（下稱「本服務條款」）所拘束。敬請詳閱本協議（定義如後，包含本服務條款），
                    如不同意本協議之內容，請勿註冊帳號、存取本網站或使用本服務。
                    <br />
                    <br />
                    請注意，在您使用本服務前，必先詳閱本公司個人資料保護政策（下稱「隱私權政策」）以及Cookie
                    政策（下稱「Cookie 政策」），如您對「隱私權政策」或「Cookie
                    政策」有疑義或無法接受，請先與本公司確認，切勿逕行使用本服務。您將與本公司訂立之協議包含本服務條款、隱私權政策、Cookie
                    政策（下合稱「本協議」）。本公司有權隨時變更、修訂或更新本協議內容；針對本服務，未來可能增訂額外的條款，如果您使用本服務，
                    則該等額外條款也會構成本協議之一部。若本協議有任何後續變更、修訂或更新的情形，如您不同意該等後續的變更、
                    修訂或更新條款，您仍可存取您的交易記錄和碳權憑證，但您可能無法繼續使用本公司提供之完整服務。
                    <br />
                    <br />
                    本公司與您之間同意以電子文件為表示方法，依本協議交換之電子文件，如其內容可完整呈現且可於日後取出供查驗者，其效力與書面文件相同。但依法令有強制規定排除適用者，不在此限。上述電子文件係以文字、聲音、影片、圖像、符號或其他資料，以電子之方式所製成足以表達意思表示之紀錄，而提供作電子處理者。本公司公司將記錄相關電子文件資料，如您發現提供之資訊有誤之情形，應立即通知本公司。
                  </p>

                  {MEMBERS_TERMS?.map((term) => (
                    <div key={term.id}>
                      <div className="flex gap-3 mt-4 mb-1 text-sm">
                        <p className="">{term.id}</p>
                        <p className="">{term.title}</p>
                      </div>
                      <div>
                        {term?.content?.map((x) => (
                          <div key={x.id} className="flex indent-5 mb-2 text-xs">
                            <p>{x.id}</p>
                            <p>{x.detail}</p>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </Box>
            </div>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '95%' }}>
              <Link
                className="rounded-md bg-white-smoke font-bold shadow-download-btn text-lg mt-3 px-10 self-end flex gap-2 items-center text-navy-blue"
                to="/pdf/password-recovery.pdf"
                target="_blank"
                download="土星_碳權平台會員服務條款、隱私政策、Cookie政策"
              >
                Download
                <img src="/v2/icon/download-icon.svg" alt="" />
              </Link>
            </Box>

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '30px',
                px: '10px',
                paddingTop: '10px',
                width: '100%'
              }}
            >
              <CustomButton
                variant="primary"
                className="rounded-full px-17 h-12 text-lg mt-3"
                onClick={() => {
                  setIsAgreedToTerms(true);
                  setIsTermsModalOpen(false);
                }}
              >
                確認
              </CustomButton>
              {/* <CustomButton
                variant="primary"
                className="rounded-full px-15 h-12 text-lg mt-3 !text-navy-blue bg-white border-2 "
                onClick={() => {
                  setIsDoNotAgreeTermsModalOpen(true);
                  setIsTermsModalOpen(false);
                }}
              >
                不同意
              </CustomButton> */}
            </Box>
          </Box>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
};

export default PasswordRecoveryTermsModal;

// const PASSWORD_RECOVERY_TERMS_AND_CONDITIONS = [
//   {
//     title: '一、隱私權保護政策的適用範圍',
//     content: [
//       {
//         text: '隱私權保護政策內容，包括本網站如何處理在您使用網站服務時收集到的個人識別資料。隱私權保護政策不適用於本網站以外的相關連結網站，也不適用於非本網站所委託或參與管理的人員。',
//         isListDisc: false
//       }
//     ]
//   },
//   {
//     title: ' 二、個人資料的蒐集、處理及利用方式',
//     content: [
//       {
//         text: ' 當您造訪本網站或使用本網站所提供之功能服務時，我們將視該服務功能性質，請您提供必要的個人資料，並在該特定目的範圍內處理及利用您的個人資料；非經您書面同意，本網站不會將個人資料用於其他用途。',
//         isListDisc: true
//       },
//       {
//         text: ' 本網站在您使用服務信箱、問卷調查等互動性功能時，會保留您所提供的姓名、電子郵件地址、聯絡方式及使用時間等。',
//         isListDisc: true
//       },
//       {
//         text: ' 於一般瀏覽時，伺服器會自行記錄相關行徑，包括您使用連線設備的 IP 位址、使用時間、使用的瀏覽器、瀏覽及點選資料記錄等，做為我們增進網站服務的參考依據，此記錄為內部應用，決不對外公佈。',
//         isListDisc: true
//       },
//       {
//         text: '  為提供精確的服務，我們會將收集的問卷調查內容進行統計與分析，分析結果之統計數據或說明文字呈現，除供內部研究外，我們會視需要公佈統計數據及說明文字，但不涉及特定個人之資料。',
//         isListDisc: true
//       },
//       {
//         text: '  您可以隨時向我們提出請求，以更正或刪除您的帳戶或本網站所蒐集的個人資料等隱私資訊。聯繫方式請見最下方聯繫管道。',
//         isListDisc: true
//       }
//     ]
//   },
//   {
//     title: ' 三、資料之保護',
//     content: [
//       {
//         text: ' 本網站主機均設有防火牆、防毒系統等相關的各項資訊安全設備及必要的安全防護措施，加以保護網站及您的個人資料採用嚴格的保護措施，只由經過授權的人員才能接觸您的個人資料，相關處理人員皆簽有保密合約，如有違反保密義務者，將會受到相關的法律處分。',
//         isListDisc: true
//       },
//       {
//         text: ' 如因業務需要有必要委託其他單位提供服務時，本網站亦會嚴格要求其遵守保密義務，並且採取必要檢查程序以確定其將確實遵守。',
//         isListDisc: true
//       }
//     ]
//   },
//   {
//     title: ' 四、網站對外的相關連結',
//     content: [
//       {
//         text: ' 本網站的網頁提供其他網站的網路連結，您也可經由本網站所提供的連結，點選進入其他網站。但該連結網站不適用本網站的隱私權保護政策，您必須參考該連結網站中的隱私權保護政策。',
//         isListDisc: false
//       }
//     ]
//   },
//   {
//     title: '五、與第三人共用個人資料之政策',
//     content: [
//       {
//         text: '本網站絕不會提供、交換、出租或出售任何您的個人資料給其他個人、團體、私人企業或公務機關，但有法律依據或合約義務者，不在此限。 前項但書之情形包括不限於：',
//         isListDisc: false
//       },
//       {
//         text: ' 經由您書面同意。',
//         isListDisc: true
//       },
//       {
//         text: '法律明文規定。',
//         isListDisc: true
//       },
//       {
//         text: '為免除您生命、身體、自由或財產上之危險。',
//         isListDisc: true
//       },
//       {
//         text: ' 與公務機關或學術研究機構合作，基於公共利益為統計或學術研究而有必要，且資料經過提供者處理或蒐集者依其揭露方式無從識別特定之當事人。',
//         isListDisc: true
//       },
//       {
//         text: ' 有利於您的權益。',
//         isListDisc: true
//       },
//       {
//         text: ' 本網站委託廠商協助蒐集、處理或利用您的個人資料時，將對委外廠商或個人善盡監督管理之責。',
//         isListDisc: true
//       }
//     ]
//   },
//   {
//     title: ' 六、Cookie 之使用 為了提供您最佳的服務，本網站會在您的電腦中放置並取用我們的',
//     content: [
//       {
//         text: ' Cookie，若您不願接受 Cookie 的寫入，您可在您使用的瀏覽器功能項中設定隱私權等級為高，即可拒絕 Cookie 的寫入，但可能會導致網站某些功能無法正常執行 。',
//         isListDisc: false
//       }
//     ]
//   },
//   {
//     title: '  七、隱私權保護政策之修正 本網站隱私權保護政策將因應需求隨時進行修正，修正後的條款將刊登於網站上。 ',
//     content: [
//       {
//         text: ' ',
//         isListDisc: false
//       }
//     ]
//   },
//   {
//     title:
//       ' 八、聯繫管道 對於本站之隱私權政策有任何疑問，或者想提出變更、移除個人資料之請求，請前往本站「聯絡我們」頁面提交表單。',
//     content: [
//       {
//         text: ' ',
//         isListDisc: false
//       }
//     ]
//   }
// ];
