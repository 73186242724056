import AddIcon from '@mui/icons-material/Add';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';

import { useEmployeeStore } from '@/store/employee';

import EnterpriseCard from './EnterpriseCard';

const EnterpriseAccountInfo = () => {
  const employeeList = useEmployeeStore((store) => store.employeeList);
  const getEmployeeList = useEmployeeStore((store) => store.getEmployeeList);

  useEffect(() => {
    getEmployeeList();
  }, []);

  return (
    // <div className="mt-4 overflow-y-auto yellowScroll pr-5 mr-5 2xl:pr-8 2:mr-8 2.5xl:pr-12 2.5xl:mr-14 flex justify-end xl:justify-center">
    <div className="overflow-y-auto yellowScroll 2xl:min-h-[80vh]">
      <div className=" grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 w gap-5 xl:gap-8 2.5xl:gap-[47px] max-h-[690px] 2.5xl:max-h-[720px] items-center">
        {employeeList.map((employee) => (
          <div className="px-2" key={employee.id}>
            <EnterpriseCard
              id={employee.id}
              title={employee.group_name}
              userName={employee.username}
              userEmail={employee.email}
              img={employee.photo || '/images/enterprise-account/user-icon.svg'}
              status={employee.status}
              statusText={employee.status_cht}
            />
          </div>
        ))}
        <div className="bg-white rounded-lg w-[210px]  2xl:w-[280px] aspect-[3/4] min-h-[280px] flex justify-center items-center shadow-enterprise-card ">
          <div className="flex flex-col items-center gap-11 mt-10">
            <Link to="/v2">
              <div className="border-2 border-dashed border-navy-blue rounded-full h-[90px] w-[90px] flex justify-center items-center">
                <div className="h-[70px] w-[70px] rounded-full bg-navy-blue flex justify-center items-center hover:cursor-pointer ">
                  <AddIcon className="text-white" />
                </div>
              </div>
            </Link>
            <h1 className="text-navy-blue font-bold text-xl">新增使用者</h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnterpriseAccountInfo;
