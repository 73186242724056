import MemberLayout from '@/components/Layout/MemberLayout';
import UserInfoForm from '@/components/v2/UserInfoForm';

const MemberCenter = () => {
  return (
    <MemberLayout>
      <UserInfoForm />
    </MemberLayout>
  );
};

export default MemberCenter;
