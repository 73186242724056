/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `0` - Not Reviewed
 * * `1` - Reviewing
 * * `2` - Reviewed Pass
 * * `3` - Documents Needed
 */
export enum ReissueEnum {
    '_0' = 0,
    '_1' = 1,
    '_2' = 2,
    '_3' = 3,
}
