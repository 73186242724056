import { ChangeEvent, useState } from 'react';
import { Controller, FieldValues, Path, useFormContext } from 'react-hook-form';
import { Link } from 'react-router-dom';

type FilesUploadFieldProps<T extends FieldValues> = {
  name: Path<T>;
  guideDownload: {
    fileLinkPath: string;
    text: string;
  };
  title: string;
  fileSizeLimit?: number; // MB
  acceptFileTypes?: string;
};

// File instance or File url
export type FileItem = File | string;

const FilesUploadField = <T extends FieldValues>(props: FilesUploadFieldProps<T>) => {
  const { name, title, guideDownload, fileSizeLimit, acceptFileTypes = 'image/jpeg, image/jpg, image/png' } = props;
  const [items, setItems] = useState<FileItem[]>([]);

  const {
    control,
    formState: { errors },
    setError,
    clearErrors,
    setValue
  } = useFormContext();

  const addFileItem = (file: File) => {
    if (fileSizeLimit && file.size > fileSizeLimit * 1024 * 1024) {
      setError(name, { type: 'custom', message: `${file.name} 檔案大小超過${fileSizeLimit}MB` });
      return;
    }
    if (items.some((item) => (item instanceof File ? item.name === file.name : false))) {
      setError(name, { type: 'custom', message: `${file.name} 檔案已經存在` });
      return;
    }
    const newItemList = [...items, file];
    setItems(newItemList);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setValue(name, newItemList as any);
    clearErrors(name);
  };

  const removeFileItem = (idx: number) => {
    const newItemList = items.filter((item, index) => index !== idx);
    setItems(newItemList);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setValue(name, newItemList as any);
    clearErrors(name);
  };

  return (
    <div className="flex flex-col max-w-[372px] max">
      <p
        className="upload-doc-text text-black min-[1500px]:text-base text-sm 2xl:whitespace-nowrap"
        dangerouslySetInnerHTML={{
          __html: title
        }}
      />
      <Link
        to={guideDownload.fileLinkPath}
        target="_blank"
        download={guideDownload.text}
        className="text-navy-blue underline cursor-pointer"
      >
        {guideDownload.text}
      </Link>
      <div className="flex flex-row flex-wrap max-w-[360px] gap-4 mt-2">
        <Controller
          name={name}
          control={control}
          render={({ field: { value } }) => {
            if (!Array.isArray(value)) return;
            return value.map((item: FileItem, index: number) => {
              return (
                <div
                  key={index}
                  className="flex flex-col justify-center rounded-xl border items-center border-silverstone h-23.2 w-26.7 relative"
                >
                  {/* add cfoss icon to delete item */}
                  <div
                    className="absolute top-0 right-2 font-semibold cursor-pointer"
                    onClick={() => removeFileItem(index)}
                  >
                    &#215;
                  </div>
                  <p className="text-[36px] text-black mt-2">{index + 1}</p>
                  {/* <label className="rounded-full bg-navy-blue p-0.5 px-5 text-xms text-white" htmlFor="get-file">
                      選擇
                    </label> */}
                </div>
              );
            });
          }}
        />

        <div className="flex flex-col rounded-xl border border-silverstone bg-light-trans-grey items-center justify-center h-23.2 w-26.7 relative">
          <img src="/images/operation-record/plus-icon.svg" alt="add new doc" />
          <input
            type="file"
            accept={acceptFileTypes}
            className="absolute scale-y-[1] max-w-full max-h-full top-0 bottom-0 left-0 opacity-0 cursor-pointer z-50"
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              e?.target?.files && e?.target?.files?.length > 0 && addFileItem(e.target.files[0]);
            }}
            onClick={(e) => (e.currentTarget.value = '')}
          />
        </div>
      </div>

      {errors[name]?.message && <p className="text-bright-red text-xs mt-2">{errors[name]?.message?.toString()}</p>}
    </div>
  );
};

export default FilesUploadField;
