import { use } from 'i18next';
import { useEffect } from 'react';

import FilterBar from '@/components/FilterBar';
import Navbar from '@/components/Navbar';
import LayoutSwitch from '@/components/Products/LayoutSwitch';
import WishListProdCard from '@/components/WishListProdCard';
import WishlistSwitch from '@/components/WishlistSwitch';
import { WatchList } from '@/libs/api';
import { useWishListStore } from '@/store/wishList';
import { CarbonTag } from '@/type';

const WishList = () => {
  const filters = useWishListStore((state) => state.filters);
  const wishList = useWishListStore((state) => state.wishList);
  const getWishListWithFilter = useWishListStore((state) => state.getWishListWithFilter);
  const updateWishListByFilters = useWishListStore((state) => state.updateWishListByFilters);

  const initFilters = async () => {
    filters.tag = CarbonTag.White;
    await updateWishListByFilters({ tag: CarbonTag.White });
  };

  useEffect(() => {
    getWishListWithFilter();
    initFilters();
  }, []);

  return (
    <div className="bg-[url('../public/images/wishlist/background.png')] bg-no-repeat bg-cover h-screen ">
      <Navbar className="pt-4 mb-8" />
      {/* <WishlistSwitch /> */}
      <div className="px-6 2xl:px-8">
        <LayoutSwitch filters={filters} updateFilter={updateWishListByFilters} />
        <FilterBar />
      </div>

      <div className="grid grid-cols-2 gap-6.5 max-h-[72vh] overflow-y-auto yellowScroll overflow-x-hidden pr-5 3xl:max-w-full mx-auto px-5 3xl:mx-2">
        {wishList.map((product: WatchList) => {
          return <WishListProdCard key={product.id} product={product} />;
        })}
      </div>
    </div>
  );
};

export default WishList;
