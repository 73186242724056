export const UnFilledRadio = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="19" viewBox="0 0 17 19" fill="none">
    <path
      d="M15.9219 9.2998C15.9219 13.7336 12.542 17.2213 8.50049 17.2213C4.45899 17.2213 1.07903 13.7336 1.07903 9.2998C1.07903 4.86599 4.45899 1.37835 8.50049 1.37835C12.542 1.37835 15.9219 4.86599 15.9219 9.2998Z"
      stroke="#525252"
      strokeWidth="2.15709"
    />
  </svg>
);
export const FilledRadio = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
    <circle cx="9" cy="9.2998" r="7.92145" stroke="#525252" strokeWidth="2.15709" />
    <circle cx="8.99947" cy="9.29977" r="5.04" fill="#525252" />
  </svg>
);

export const RatingStar = ({ color }: { color: string }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="15" viewBox="0 0 17 15" fill="none">
      <path
        d="M3.43673 13.885C3.57209 13.0999 3.71647 12.2697 3.86085 11.4395C3.93304 11.0154 4.00523 10.5913 4.09547 10.1671C4.18571 9.75204 4.11352 9.4001 3.77062 9.09329C2.8953 8.29919 2.11022 7.38778 1.19881 6.62978C1.02736 6.4854 0.892005 6.32297 0.819814 6.11542C0.666408 5.64618 0.919075 5.21303 1.44246 5.1228C2.15534 4.99646 2.86823 4.8972 3.58111 4.78891C4.13157 4.7077 4.68202 4.62648 5.2415 4.55429C5.64758 4.50015 5.92731 4.30162 6.10779 3.92262C6.61313 2.86683 7.14554 1.82909 7.66892 0.78232C7.8494 0.421366 8.01183 0.03334 8.49912 0.0513877C8.96836 0.0694354 9.15786 0.439414 9.33834 0.791344C9.86172 1.79299 10.3039 2.83976 10.8363 3.84141C11.0709 4.28358 11.3868 4.50917 11.874 4.58136C13.0381 4.73477 14.1932 4.92427 15.3482 5.07768C15.7362 5.13182 16.0611 5.24913 16.1874 5.64618C16.3138 6.04323 16.0882 6.33199 15.8084 6.59368C15.0234 7.34266 14.2744 8.10969 13.4622 8.82258C12.9298 9.29182 12.8306 9.82423 12.9479 10.4739C13.1464 11.5658 13.3179 12.6667 13.5074 13.7677C13.5525 14.0113 13.5435 14.2369 13.4171 14.4444C13.2276 14.7693 12.8486 14.8956 12.4786 14.7152C11.9553 14.4715 11.4499 14.1918 10.9446 13.9301C10.3219 13.6052 9.69027 13.2984 9.06762 12.9645C8.6435 12.7389 8.25547 12.757 7.84037 12.9736C6.85677 13.4969 5.86415 14.0113 4.87152 14.5257C4.76324 14.5798 4.6369 14.6339 4.53764 14.6971C4.04133 15.0039 3.38259 14.6339 3.43673 13.894V13.885Z"
        fill={color}
      />
    </svg>
  );
};

export const MinusRounded = ({ onClick }: { onClick?: () => void }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="26" height="27" viewBox="0 0 26 27" fill="none" onClick={onClick}>
    <path
      d="M17.9189 12.1284C18.2893 12.1284 18.5623 12.1959 18.7377 12.3309C18.8937 12.4771 18.9717 12.7078 18.9717 13.0228L18.9717 13.3434C18.9717 13.6472 18.8937 13.8722 18.7377 14.0184C18.5623 14.1647 18.2893 14.2378 17.9189 14.2378L8.0927 14.2378C7.72227 14.2378 7.45907 14.1647 7.3031 14.0184C7.12763 13.8722 7.03989 13.6472 7.03989 13.3434L7.03989 13.0228C7.03989 12.7078 7.12763 12.4771 7.3031 12.3309C7.45907 12.1959 7.72227 12.1284 8.0927 12.1284L17.9189 12.1284Z"
      fill="black"
    />
    <path
      d="M0.702712 13.1827C0.702713 6.37383 6.21209 0.856234 13.0056 0.856234C19.7992 0.856235 25.3086 6.37384 25.3086 13.1827C25.3086 19.9917 19.7992 25.5093 13.0056 25.5093C6.21209 25.5093 0.702712 19.9917 0.702712 13.1827Z"
      stroke="#B3B4B4"
      strokeWidth="1.36331"
    />
  </svg>
);

export const PlusRounded = ({ onClick }: { onClick?: () => void }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none" onClick={onClick}>
    <path
      d="M13.6953 6.6665C13.9768 6.6665 14.1829 6.70678 14.3136 6.78735C14.4342 6.86792 14.4945 7.00388 14.4945 7.19523L14.4945 11.9991L19.3048 11.9991C19.4958 11.9991 19.6365 12.0495 19.727 12.1502C19.8074 12.2509 19.8477 12.4372 19.8477 12.7091L19.8477 12.8904C19.8477 13.1825 19.8024 13.404 19.7119 13.5551C19.6215 13.7163 19.4858 13.7968 19.3048 13.7968L14.4945 13.7968L14.4945 18.6158C14.4945 18.7971 14.4342 18.9331 14.3136 19.0237C14.1829 19.1143 13.9768 19.1597 13.6953 19.1597L13.5144 19.1597C12.9816 19.1597 12.7152 18.9784 12.7152 18.6158L12.7152 13.7968L7.93506 13.7968C7.75411 13.7968 7.61839 13.7314 7.52792 13.6004C7.43744 13.4796 7.39221 13.2782 7.39221 12.9962L7.39221 12.8149C7.39221 12.2711 7.57316 11.9991 7.93506 11.9991L12.7152 11.9991L12.7152 7.19523C12.7152 7.00388 12.7805 6.86792 12.9112 6.78735C13.0318 6.70678 13.2329 6.6665 13.5144 6.6665L13.6953 6.6665Z"
      fill="black"
    />
    <path
      d="M1.57869 13.1827C1.57869 6.37383 7.08807 0.856234 13.8816 0.856234C20.6752 0.856235 26.1846 6.37384 26.1846 13.1827C26.1846 19.9917 20.6752 25.5093 13.8816 25.5093C7.08806 25.5093 1.57869 19.9917 1.57869 13.1827Z"
      stroke="#B3B4B4"
      strokeWidth="1.36331"
    />
  </svg>
);

export const DeleteCart = ({ onClick }: { onClick?: () => void }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="29" viewBox="0 0 24 29" fill="none" onClick={onClick}>
    <path
      d="M3.94547 26.5014C4.05873 27.4178 4.83094 28.0973 5.75761 28.0973H18.6384C19.5548 28.0973 20.3373 27.4074 20.4505 26.5014L23.3129 6.68551H1.08332L3.94547 26.5014ZM15.7964 10.2118H16.929V22.7744H15.7964V10.2118ZM11.6367 10.2118H12.7692V22.7744H11.6367V10.2118ZM7.46667 10.2118H8.59923V22.7744H7.46667V10.2118Z"
      fill="#888888"
    />
    <path
      d="M22.5818 2.61228H16.9189V2.43725C16.9189 1.23257 16.0437 0.254395 14.9729 0.254395H9.41294C8.34214 0.254395 7.46694 1.23257 7.46694 2.43725V2.61228H1.80403C1.10389 2.61228 0.527344 3.18887 0.527344 3.88897V4.94952H23.8583V3.88897C23.8583 3.18883 23.2819 2.61228 22.5818 2.61228Z"
      fill="#888888"
    />
  </svg>
);

export const CarbonStandardIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="35" height="31" viewBox="0 0 35 31" fill="none">
    <path
      d="M17.5826 21.1329C17.8526 20.6637 18.1098 20.2191 18.3656 19.7734C20.0109 16.9023 21.6559 14.0312 23.3008 11.1597C25.1396 7.94994 26.978 4.73982 28.8167 1.53007C28.9857 1.23532 29.1622 0.944726 29.323 0.645811C29.4232 0.459272 29.5577 0.37376 29.7725 0.374517C31.2492 0.379814 32.7259 0.373003 34.2026 0.379814C34.6398 0.381706 34.7709 0.625757 34.5459 1.03554C34.1163 1.81877 33.6682 2.59179 33.2273 3.36897C31.3653 6.6525 29.5029 9.93603 27.6408 13.2196C25.9527 16.1966 24.2657 19.1744 22.5775 22.1514C21.0509 24.844 19.5238 27.5361 17.9968 30.2286C17.9656 30.2835 17.9397 30.341 17.9052 30.3936C17.7016 30.705 17.3835 30.7042 17.1878 30.3811C16.973 30.0262 16.7747 29.6607 16.5678 29.3008C14.9735 26.5311 13.3789 23.7614 11.7843 20.9917C11.2067 19.9887 10.6287 18.9856 10.0544 17.9806C9.79642 17.5289 9.81895 17.4865 10.344 17.488C11.8079 17.4925 13.2696 17.3998 14.7335 17.3976C15.1339 17.3968 15.3408 17.5425 15.5312 17.8694C16.1013 18.8475 16.6985 19.8097 17.2885 20.7761C17.3591 20.8915 17.4113 21.025 17.5826 21.1325V21.1329Z"
      fill="#FFD600"
    />
    <path
      d="M13.936 0.394986C18.2648 0.394986 22.5939 0.398013 26.9226 0.392338C27.6493 0.391581 27.4912 0.502823 27.238 0.945899C26.6954 1.896 26.1591 2.85025 25.6344 3.81057C25.5082 4.04175 25.3475 4.14316 25.0936 4.11932C24.9944 4.11024 24.8934 4.11819 24.7935 4.11819C17.4618 4.11819 10.1305 4.11819 2.79878 4.11819C2.69851 4.11819 2.59786 4.1091 2.49871 4.1197C2.21291 4.14959 2.04053 4.02397 1.90007 3.77159C1.41071 2.8915 0.90145 2.02389 0.400076 1.15098C0.356511 1.07492 0.306562 0.999628 0.278019 0.917521C0.196147 0.681794 0.296797 0.478229 0.53941 0.425635C0.696018 0.391581 0.862392 0.396122 1.02426 0.395743C5.32819 0.39423 9.63212 0.394608 13.936 0.394608V0.394986Z"
      fill="#FFD600"
    />
    <path
      d="M13.8295 6.07698C17.1202 6.07698 20.4108 6.07585 23.7015 6.08039C23.8803 6.08039 24.1229 5.99147 24.2198 6.18557C24.3001 6.34638 24.1203 6.50644 24.0365 6.65703C23.5411 7.54772 23.0428 8.43614 22.5451 9.32532C22.4959 9.4131 22.4404 9.49786 22.3976 9.58867C22.283 9.83348 22.0979 9.94888 21.8274 9.92618C21.7403 9.91899 21.6524 9.9258 21.5649 9.9258C16.41 9.9258 11.255 9.91975 6.10007 9.93602C5.62649 9.93753 5.34782 9.79564 5.12099 9.373C4.60384 8.4089 4.03787 7.47129 3.49218 6.52271C3.44824 6.44627 3.40317 6.37022 3.36749 6.29C3.3213 6.18595 3.36373 6.11141 3.47152 6.09779C3.60747 6.08076 3.74568 6.07774 3.88276 6.07774C7.19859 6.0766 10.514 6.07698 13.8299 6.07698H13.8295Z"
      fill="#FFD600"
    />
    <path
      d="M13.854 11.8865C16.081 11.8865 18.3081 11.8858 20.5348 11.8888C20.6802 11.8888 20.8732 11.8222 20.9581 11.9588C21.058 12.1192 20.8894 12.2592 20.8135 12.3962C20.3132 13.2982 19.8081 14.1976 19.3037 15.0974C19.2361 15.2181 19.1621 15.335 19.0942 15.4557C19.0119 15.6022 18.8846 15.6661 18.7231 15.6703C18.6104 15.6733 18.4982 15.6756 18.3855 15.6756C15.3701 15.6756 12.3551 15.6756 9.33972 15.6756C9.27701 15.6756 9.21429 15.671 9.15194 15.6756C8.88492 15.6967 8.71629 15.5832 8.58109 15.343C8.00761 14.3232 7.4161 13.3141 6.83135 12.3008C6.7938 12.2354 6.75436 12.1707 6.72244 12.1026C6.66761 11.9849 6.69502 11.9035 6.83473 11.8941C6.94702 11.8861 7.05969 11.8869 7.17236 11.8869C9.39944 11.8869 11.6265 11.8869 13.8532 11.8869L13.854 11.8865Z"
      fill="#FFD600"
    />
  </svg>
);

export const RadioButtonOutline = ({ onClick }: { onClick: () => void }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none" onClick={onClick}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.2207 11.9937C10.1782 11.9937 12.5757 9.59616 12.5757 6.63867C12.5757 3.68119 10.1782 1.28367 7.2207 1.28367C4.26322 1.28367 1.8657 3.68119 1.8657 6.63867C1.8657 9.59616 4.26322 11.9937 7.2207 11.9937ZM7.2207 13.0137C10.7415 13.0137 13.5957 10.1595 13.5957 6.63867C13.5957 3.11786 10.7415 0.263672 7.2207 0.263672C3.69989 0.263672 0.845703 3.11786 0.845703 6.63867C0.845703 10.1595 3.69989 13.0137 7.2207 13.0137Z"
      fill="#3F84AE"
    />
  </svg>
);

export const RadioButtonFilled = ({ onClick }: { onClick?: () => void }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="13" height="14" viewBox="0 0 13 14" fill="none" onClick={onClick}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.4707 11.9937C9.42819 11.9937 11.8257 9.59616 11.8257 6.63867C11.8257 3.68119 9.42819 1.28367 6.4707 1.28367C3.51322 1.28367 1.1157 3.68119 1.1157 6.63867C1.1157 9.59616 3.51322 11.9937 6.4707 11.9937ZM6.4707 13.0137C9.99152 13.0137 12.8457 10.1595 12.8457 6.63867C12.8457 3.11786 9.99152 0.263672 6.4707 0.263672C2.94989 0.263672 0.0957031 3.11786 0.0957031 6.63867C0.0957031 10.1595 2.94989 13.0137 6.4707 13.0137Z"
      fill="#3F84AE"
    />
    <ellipse cx="6.47039" cy="6.63836" rx="3.57" ry="3.57" fill="#3F84AE" />
  </svg>
);

export const RadioButtonGreyFilled = ({ onClick }: { onClick: () => void }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none" onClick={onClick}>
      <circle cx="9.58594" cy="9.85352" r="7.92145" stroke="#525252" strokeWidth="2.15709" />
      <circle cx="9.5859" cy="9.85445" r="5.04" fill="#525252" />
    </svg>
  );
};

export const RadioButtonGreyOutline = ({ onClick }: { onClick: () => void }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none" onClick={onClick}>
      <circle cx="9.58594" cy="9.85352" r="7.92145" stroke="#525252" strokeWidth="2.15709" />
    </svg>
  );
};

export const RadioButtonWhiteOutline = ({ onClick }: { onClick?: () => void }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" onClick={onClick}>
    <circle cx="7.92773" cy="8.01172" r="6.6578" stroke="white" strokeWidth="1.2469" />
  </svg>
);

export const RadioButtonWhiteFilled = ({ onClick }: { onClick?: () => void }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 15 16" fill="none" onClick={onClick}>
    <circle cx="7.36523" cy="8.01172" r="6.6578" stroke="white" strokeWidth="1.2469" />
    <circle cx="7.36453" cy="8.01199" r="4.56863" fill="white" />
  </svg>
);

export const EyeCross = ({ onClick }: { onClick?: () => void }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none" onClick={onClick}>
    <path
      d="M16.6227 17.2899C15.0686 18.4745 13.1764 19.1307 11.2227 19.1627C4.85902 19.1627 1.22266 11.8899 1.22266 11.8899C2.35347 9.78257 3.92187 7.9414 5.82266 6.48994M9.31357 4.83539C9.93932 4.68892 10.58 4.6157 11.2227 4.61721C17.5863 4.61721 21.2227 11.8899 21.2227 11.8899C20.6708 12.9223 20.0127 13.8942 19.259 14.7899M13.1499 13.8172C12.9002 14.0852 12.5992 14.3001 12.2646 14.4491C11.9301 14.5982 11.5689 14.6784 11.2027 14.6848C10.8365 14.6913 10.4728 14.6239 10.1332 14.4867C9.79362 14.3496 9.48513 14.1454 9.22615 13.8864C8.96718 13.6275 8.76301 13.319 8.62585 12.9794C8.48868 12.6398 8.42132 12.276 8.42778 11.9099C8.43424 11.5437 8.51439 11.1825 8.66345 10.848C8.81251 10.5134 9.02743 10.2123 9.29538 9.96266"
      stroke="#525252"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.22266 1.88965L21.2227 21.8896"
      stroke="#525252"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
