import React from 'react';

import MemberLayout from '@/components/Layout/MemberLayout';

import PlatformInstructions from './PlatformInstructions';

const PlatformUsage = () => {
  return (
    <MemberLayout>
      <PlatformInstructions />
    </MemberLayout>
  );
};

export default PlatformUsage;
