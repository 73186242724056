import classNames from 'classnames';
import { Fragment, useState } from 'react';

import { TableBodyItem } from '@/types';
import { formatNumberByComma } from '@/util/helper';

interface IProps {
  name: string;
  tableHeadings: string[];
  tableBody: TableBodyItem[];
}
const thStyle = 'py-3 px-2 text-sm font-normal text-grey text-left';
const tdStyle = 'py-3 px-2 text-sm xl:text-base text-dark-grey min-w-[120px] font-bold';

const CustomTable = ({ tableHeadings, tableBody, name }: IProps) => {
  const [expandedRowIndex, setExpandedRowIndex] = useState<number | null>(null);
  const [staticRowsVisible, setStaticRowsVisible] = useState<boolean>(false);

  return (
    <table className="min-w-full custom-table">
      <thead className="sticky -top-1 z-10">
        <tr className="!bg-neutral-150">
          {tableHeadings.map((item, index) => (
            <th scope="col" className={classNames(thStyle)} key={item}>
              {item}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {tableBody.map((item, index) => (
          <Fragment key={item.id}>
            <tr className="bg-white row group h-[95px]">
              <td className={classNames(tdStyle, 'w-[200px]')}>
                <span>{('time' in item && item.time) || ('orderNumber' in item && item.orderNumber)}</span>
              </td>
              <td className={classNames(tdStyle, 'w-[450px]')}>{item.prodName}</td>
              <td className={classNames(tdStyle)}>
                {('operator' in item && item.operator) || ('buysell' in item && item.buysell)}
              </td>
              <td className={classNames(tdStyle)}>{'$'.concat(formatNumberByComma(item.unitPrice.slice(1)))}</td>
              <td className={classNames(tdStyle)}>{formatNumberByComma(item.quant)}</td>
              <td className={classNames(tdStyle)}>{'$'.concat(formatNumberByComma(item.lumpsum.slice(1)))}</td>
              <td className={classNames(tdStyle)}>
                {('action' in item && item.action) || ('orderStatus' in item && item.orderStatus)}
              </td>
            </tr>

            {expandedRowIndex === index && staticRowsVisible && (
              <tr className="bg-white dropdown-row  h-[95px]">
                <td></td>
                <td colSpan={7} className="px-4 dropdown-td xl:px-8">
                  {name === 'historical_order' && (
                    <div className="flex items-center gap-10 w-full">
                      {/* date */}
                      <div className="flex items-center">
                        <img
                          src="/images/historical-order/Ellipse.png"
                          width={20}
                          height={8}
                          alt="Ellipse"
                          className="mr-1 w-3 h-3"
                        />
                        <span className="text-grey text-xl font-normal">交易日期</span>
                        <span className="text-black font-medium text-xl ml-[10px]">2023/5/10</span>
                      </div>
                      {/* member id */}
                      <div className="flex items-center">
                        <img
                          src="/images/historical-order/Ellipse.png"
                          width={20}
                          height={8}
                          alt="Ellipse"
                          className="mr-1 w-3 h-3"
                        />
                        <span className="text-grey text-xl font-normal">會員代號</span>
                        <span className="text-black font-medium text-xl ml-[10px]">Mr12345678</span>
                      </div>
                      {/* transaction status */}
                      <div className="flex items-center">
                        <img
                          src="/images/historical-order/Ellipse.png"
                          width={20}
                          height={8}
                          alt="Ellipse"
                          className="mr-1 w-3 h-3"
                        />
                        <span className="text-grey text-xl font-normal">交易狀況</span>
                        <span className="text-black font-medium text-xl ml-[10px]">逾期未付款</span>
                      </div>
                    </div>
                  )}
                </td>
              </tr>
            )}
          </Fragment>
        ))}
      </tbody>
    </table>
  );
};

export default CustomTable;
