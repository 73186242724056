import SelectField from './SelectField';

const issueTypeList = ['初發', '補發', '換發'];
const regionCityLocation = [
  '台北市',
  '新北市',
  '基隆市',
  '新竹市',
  '桃園市',
  '新竹縣',
  '宜蘭縣',
  '臺中市',
  '苗栗縣',
  '彰化縣',
  '南投縣',
  '雲林縣',
  '高雄市',
  '臺南市',
  '嘉義市',
  '嘉義縣',
  '屏東縣',
  '澎湖縣',
  '花蓮縣',
  '臺東縣',
  '金門縣',
  '連江縣'
];

type IdCardIssueLocationFieldProps = {
  cityFieldName: string;
  issueTypeFieldName: string;
};

const IdCardIssueLocationField = (props: IdCardIssueLocationFieldProps) => {
  const { cityFieldName, issueTypeFieldName } = props;
  return (
    <div className="flex gap-1.5 items-center">
      <SelectField
        label="選擇"
        name={cityFieldName}
        width="87px"
        options={regionCityLocation.map((region) => ({ name: region, value: region }))}
      />
      <SelectField
        label="發行"
        name={issueTypeFieldName}
        width="82px"
        options={issueTypeList.map((issue) => ({ name: issue, value: issue }))}
      />
    </div>
  );
};

export default IdCardIssueLocationField;
