import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import classNames from 'classnames';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { PUERCHASE_NOTE } from '@/util/constants';

type NoticeScrollModalProps = {
  isOpen: boolean;
  buttonText?: string;
  close: () => void;
  onCheckout: () => void;
};

const NoticeScrollModal = ({ isOpen, buttonText, close, onCheckout }: NoticeScrollModalProps) => {
  const navigate = useNavigate();
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const target = e.target as HTMLDivElement;
    setIsScrolledToBottom(target.scrollHeight - target.scrollTop <= target.clientHeight + 1);
  };
  const handleOnCheckout = () => {
    if (isScrolledToBottom) {
      onCheckout();
    }
  };
  const handleOnClose = () => {
    setIsScrolledToBottom(false);
    close();
  };

  return (
    <>
      {isOpen && (
        <div className="fixed top-0 left-0 z-[1500] w-full h-full bg-[#3D3D3D99] flex justify-center items-center">
          <div className="relative p-6 bg-white max-w-[600px] max-h-[80vh] rounded-2xl flex flex-col">
            <CancelOutlinedIcon className="text-grey10 absolute top-6 right-6 cursor-pointer" onClick={handleOnClose} />
            <h3 className="mt-6 text-2xl text-dark-grey font-semibold mb-4">買賣須知</h3>
            <div className="overflow-auto whitespace-pre-line" onScroll={handleScroll}>
              {PUERCHASE_NOTE}
            </div>
            <div className="text-center" onClick={handleOnCheckout}>
              <button
                className={classNames(
                  'mt-4 py-2 w-[200px] rounded-full text-white mx-auto my-2 bg-grey10 cursor-default',
                  {
                    '!bg-navy-blue cursor-pointer': isScrolledToBottom
                  }
                )}
              >
                {buttonText || '同意並繼續'}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default NoticeScrollModal;
