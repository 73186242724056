import { ReactNode } from 'react';
import { createBrowserRouter } from 'react-router-dom';

import Cart from '@/pages/Cart';
import Certificate from '@/pages/Certificate';
import CompanyRegistration from '@/pages/CompanyRegistration';
// import Dashboard from '@/pages/Dashboard';
import HistoricalOrder from '@/pages/HistoricalOrder';
import LaunchedSetting from '@/pages/LaunchedSetting';
import Login from '@/pages/Login';
// import NewsPage from '@/pages/NewsPage';
import OperationRecord from '@/pages/OperationRecord';
import OperatorSignUp from '@/pages/OperatorSignup';
import ProductDetails from '@/pages/ProductDetails';
import Products from '@/pages/Products';
import Sales from '@/pages/Sales_v2';
import WishList from '@/pages/Wishlist';

import ProductDetail from './components/ProductDetail';
import NewProductDetails from './components/Products/NewProductDetails_v2';
import { ProtectedAuthRoute } from './components/ProtectedAuthRoute';
import { ProtectedCompanyRegisteredRoute } from './components/ProtectedCompanyRegisteredRoute';
import PasswordRecoveryForm from './pages/PasswordRecoveryForm';
// import AllProducts from './pages/AllProducts';
import PaymentInformation from './pages/PaymentInformation';
import AccountCredit from './pages/v2/AccountCredit';
import AccountInformation from './pages/v2/AccountInformation';
import CardReIssue from './pages/v2/CardReIssue';
import CardRenewal from './pages/v2/CardRenewal';
import CardRevoked from './pages/v2/CardRevoked';
import CartV2 from './pages/v2/CartV2';
import EnterpriseAccount from './pages/v2/EnterpriseAccount';
import MemberCenter from './pages/v2/MemberCenter';
import MemberProfile from './pages/v2/MemberProfile';
import MembershipUpgrade from './pages/v2/MembershipUpgrade';
import MyStore from './pages/v2/MyStore';
import OrderList from './pages/v2/OrderList';
import PasswordResetNewPass from './pages/v2/PasswordResetNewPass';
import PlatformUsage from './pages/v2/PlatformUsage';

type ProtectedAuthRoute = {
  children: ReactNode;
};

const ProtectedRoute = ({ children }: ProtectedAuthRoute) => (
  <ProtectedAuthRoute>
    <ProtectedCompanyRegisteredRoute>{children}</ProtectedCompanyRegisteredRoute>
  </ProtectedAuthRoute>
);

const publicRoutes = [
  {
    path: '/login',
    element: <Login />
  },
  {
    path: '/sign-up',
    element: <OperatorSignUp />
  },
  {
    path: '/password-recovery',
    element: <PasswordRecoveryForm />
  },
  {
    path: '/password-recovery/new-password',
    element: <PasswordResetNewPass />
  }
];
const protectedRoutes = [
  {
    path: '/new-product-details/:id',
    element: <NewProductDetails />
  },
  {
    path: '/v2',
    element: <MemberCenter />
  },
  {
    path: '/v2/operating-instruction',
    element: <PlatformUsage />
  },
  {
    path: '/v2/enterprise-account',
    element: <EnterpriseAccount />
  },
  {
    path: '/v2/card-renewal',
    element: <CardRenewal />
  },
  {
    path: '/v2/cart',
    element: <CartV2 />
  },
  {
    path: '/v2/membership-upgrade',
    element: <MembershipUpgrade />
  },
  {
    path: '/v2/card-reissue',
    element: <CardReIssue />
  },
  {
    path: '/v2/card-revoked',
    element: <CardRevoked />
  },
  {
    path: '/v2/account-information',
    element: <AccountInformation />
  },
  {
    path: '/v2/profile-update/:id',
    element: <MemberProfile />
  },
  {
    path: '/v2/account-carbon-credit',
    element: <AccountCredit />
  },
  {
    path: '/v2/order-list/:status',
    element: <OrderList />
  },
  {
    path: '/v2/my-store/:status',
    element: <MyStore />
  },
  {
    path: '/',
    element: <Products />
    //<Dashboard />
  },
  // {
  //   path: '/product-list',
  //   element: <AllProducts />
  // },
  {
    path: '/product-carbon/:carbonId',
    element: <ProductDetail />
  },
  {
    path: '/product-detail/:id',
    element: <ProductDetails />
  },
  // {
  //   path: '/latest-news',
  //   element: <NewsPage />
  // },
  {
    path: '/operation-record',
    element: <OperationRecord />
  },
  {
    path: '/historical-order',
    element: <HistoricalOrder />
  },
  {
    path: '/cart',
    element: <Cart />
  },
  {
    path: '/sales/:launched?',
    element: <Sales />
  },
  {
    path: '/sign-up',
    element: <OperatorSignUp />
  },
  {
    path: '/company-registration',
    element: <CompanyRegistration />
  },
  {
    path: '/wishlist',
    element: <WishList />
  },
  {
    path: '/certificate/:carbonId',
    element: <Certificate />
  },
  {
    path: '/launched-setting/:carbonId',
    element: <LaunchedSetting />
  },
  {
    path: '/payment-information/:orderId?',
    element: <PaymentInformation />
  }
];

const router = createBrowserRouter([
  ...publicRoutes,
  ...protectedRoutes.map((route) => ({ ...route, element: <ProtectedRoute>{route.element}</ProtectedRoute> }))
]);

export default router;
