import CancelIcon from '@mui/icons-material/Cancel';
import { useEffect, useRef, useState } from 'react';

import type { Filters } from '@/types/historical';

type Props<T> = {
  placeholder?: string;
  getData: (filters: Filters) => void;
  className?: string;
  watch?: T;
};

const OutlineRounedSearchBar = <T,>({ placeholder, getData, watch }: Props<T>) => {
  const inputElement = useRef<HTMLInputElement>(null);
  const [keyword, setKeyword] = useState('');
  const clearKeyword = () => {
    // console.log(inputElement.current?.value);
    inputElement.current?.value ? (inputElement.current.value = '') : '';
    setKeyword('');
    getData({ keyword: '' });
  };
  useEffect(() => {
    if (watch?.toString()) {
      clearKeyword();
    }
  }, [watch]);

  return (
    <div className="flex">
      <div className="relative rounded-md w-full">
        <input
          type="text"
          name="search"
          id="search"
          ref={inputElement}
          className="block bg-white w-full xl:w-[347px] py-2 pl-3 rounded-[26px] border border-light-grey pr-12 text-silverstone text-xs font-normal shadow-sm"
          placeholder={placeholder}
          onChange={(e) => {
            const keyword = e.target.value;
            setKeyword(keyword);
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              getData({ keyword });
            }
          }}
        />
        {keyword && (
          <div
            onClick={() => {
              clearKeyword();
            }}
            className=" py-2 absolute top-0 right-[32px] flex items-center pl-2 pr-3 cursor-pointer z-10 text-grey"
          >
            <CancelIcon fontSize="small" />
          </div>
        )}
        <div
          onClick={() => {
            getData({ keyword });
          }}
          className="border border-r-0 border-t-0 border-b-0 border-l-light-grey py-2 absolute top-0 right-0 flex items-center pl-2 pr-3 cursor-pointer"
        >
          <img src="/images/operation-record/search_icon.svg" width={16} height={16} alt="search" />
        </div>
      </div>
    </div>
  );
};
export default OutlineRounedSearchBar;
