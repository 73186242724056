import { tr } from 'date-fns/locale';
import { use } from 'i18next';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import HistoricalLayout from '@/components/Layout/HistoricalLayout';
import useOrder from '@/hooks/useOrder';
import { useOrderStore } from '@/store/order';
import { useTransactionBuyStore } from '@/store/transactionBuy';
import type { Filters } from '@/types/historical';
import { OrderStatus } from '@/types/historical';
const OrderList = () => {
  //  API
  const transactionBuyList = useTransactionBuyStore((state) => state.transactionBuyList);
  const updateTransactionBuyListByFilters = useTransactionBuyStore((state) => state.updateTransactionBuyListByFilters);
  //
  const { headersData, headers, transStatus, setHeaders } = useOrder();
  const { status } = useParams();
  const setPage = useOrderStore((state) => state.setPage);

  const updateUnpaidNum = useTransactionBuyStore((state) => state.updateUnpaidNum);
  const getData = async (filter: Filters) => {
    console.log('orderlist:get data');
    await updateTransactionBuyListByFilters(filter);
  };
  useEffect(() => {
    setPage('order-list');
    updateUnpaidNum();
  }, []);
  useEffect(() => {
    const statusCode: string = status ? transStatus(status) : '0';
    updateTransactionBuyListByFilters({
      status: statusCode
    });
    if (!Array.isArray(headers)) return;
    if (status === OrderStatus.HistoricalOrder.toString()) {
      setHeaders([...headersData, { title: '訂單狀態', key: 'orderStatus' }]);
    } else if (status === OrderStatus.Unpaid.toString()) {
      setHeaders([...headersData, { title: '付款資訊', key: 'paymentInfo' }]);
    } else {
      setHeaders(headersData);
    }
  }, [status]);

  return (
    <>
      {/* Fake Data */}
      {/* <HistoricalLayout title="購買清單" color="blue" headers={headers} orders={orders} getData={getData} /> */}
      {/* API Data */}
      <HistoricalLayout title="購買清單" color="blue" headers={headers} orders={transactionBuyList} getData={getData} />
    </>
  );
};
export default OrderList;
