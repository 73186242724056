import Alert from '@mui/material/Alert';
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';

// import { useState } from 'react';
import { useSnackbar } from '@/store/snackBar';

interface State extends SnackbarOrigin {
  open: boolean;
}

const GlobalSnack = () => {
  const { isOpen, message, vertical, horizontal, closeSnackbar } = useSnackbar();
  // const [state, setState] = useState<State>({
  //   open: false,
  //   vertical: 'top',
  //   horizontal: 'center'
  // });
  // const [msg, setMsg] = useState<string>('');
  // const { vertical, horizontal, open } = state;
  // const handleClick = (newState: SnackbarOrigin) => () => {
  //   setState({ ...newState, open: true });
  // };
  // const handleClose = () => {
  //   setState({ ...state, open: false });
  // };
  return (
    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      open={isOpen}
      onClose={closeSnackbar}
      autoHideDuration={1500}
      key={vertical + horizontal}
    >
      <Alert onClose={closeSnackbar} icon={false} className="!bg-success" sx={{ width: '100%', color: 'white' }}>
        {message}
      </Alert>
    </Snackbar>
  );
};
export default GlobalSnack;
