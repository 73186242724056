import { Fragment, ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

import { useUserStore } from '@/store/user';

type ProtectedAuthRoute = {
  children: ReactNode;
};

export const ProtectedAuthRoute = ({ children }: ProtectedAuthRoute) => {
  const isLogin = useUserStore((state) => state.isLogin);

  if (!isLogin()) {
    // user is not authenticated
    return <Navigate to="/login" />;
  }

  return <Fragment>{children}</Fragment>;
};
