import { yupResolver } from '@hookform/resolvers/yup';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import DriveFolderUploadOutlinedIcon from '@mui/icons-material/DriveFolderUploadOutlined';
import InsertInvitationOutlinedIcon from '@mui/icons-material/InsertInvitationOutlined';
import RadioButtonCheckedRoundedIcon from '@mui/icons-material/RadioButtonCheckedRounded';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import classNames from 'classnames';
import { useEffect, useMemo, useRef, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';

import CustomButton from '@/components/CustomButton';
import TextField from '@/components/Kit/Textfield';
import LightLayout from '@/components/Layout/LightLayout';
import TotalPayment from '@/components/TotalPayment';
import { CartDetailResonse, PatchedTransactionDetail } from '@/libs/api';
import apiClient from '@/libs/api/client';
import { CheckoutResult, useCartStore } from '@/store/cart';
import { useCompanyStore } from '@/store/company';
import { ModalType, runTask, useModalStore } from '@/store/modal';
import { useSnackbar } from '@/store/snackBar';
import { PAY_INFO } from '@/util/constants';
import { formatNumberByComma } from '@/util/helper';
import { useInnerHeight } from '@/util/useInnerHeight';

const PaymentInformation = () => {
  const navigate = useNavigate();

  const { orderId } = useParams();
  const innerHeight = useInnerHeight();
  // clone it when entry this page
  const company = useCompanyStore((state) => state.company);
  const getCompany = useCompanyStore((state) => state.getCompany);
  const cartDetail = useCartStore((store) => store.cartDetail);
  const getCartDetail = useCartStore((store) => store.getCartDetail);
  const [checkoutDetail, setCheckoutDetail] = useState<CheckoutResult['checkoutDetail']>(null);
  const certificateFile = useRef<HTMLInputElement | null>(null);
  const [fileName, setFileName] = useState('');
  const [fileSource, setFileSource] = useState<File | null>(null);

  const open = useModalStore((store) => store.open);

  // const [bank, setBank] = useState<'default' | 'other'>('default');
  const isCheckout = useMemo(() => checkoutDetail !== null, [checkoutDetail]);

  const schema = yup.object({
    bank: yup.string().required(),
    account_with_bank: yup.string().when(['bank'], ([bank], schema) => {
      return bank === 'other' ? schema.required('請輸入銀行名稱') : schema.notRequired();
    }),
    beneficiary_name: yup.string().when(['bank'], ([bank], schema) => {
      return bank === 'other' ? schema.required('請輸入帳戶名稱') : schema.notRequired();
    }),
    swift_code: yup.string().when(['bank'], ([bank], schema) => {
      return bank === 'other' ? schema.required('請輸入SWIFT CODE') : schema.notRequired();
    }),
    beneficiary_no: yup.string().when(['bank'], ([bank], schema) => {
      return bank === 'other' ? schema.required('請輸入帳戶號碼') : schema.notRequired();
    }),
    // payment_receipt: yup.string().required('請上傳轉帳憑證'),
    payment_receipt: yup.mixed(),
    paid_at: yup.string().required('請選擇轉帳日期'),
    transaction_detail: yup.string().required()
  });

  type OtherBankFormType = yup.InferType<typeof schema>;
  const formMethods = useForm<OtherBankFormType>({
    resolver: yupResolver(schema),
    defaultValues: {
      bank: 'default',
      paid_at: new Date().toISOString().split('T')[0]
    }
  });
  const {
    handleSubmit,
    resetField,
    register,
    getValues,
    setValue,
    formState: { errors }
  } = formMethods;
  const bank = formMethods.watch('bank');

  useEffect(() => {
    getCompany();
  }, []);
  useEffect(() => {
    if (orderId) {
      if (!/^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(orderId)) {
        navigate('/cart');
        return;
      }
      getCartDetail(orderId);
      setValue('transaction_detail', orderId);
    }
  }, [orderId]);
  useEffect(() => {
    resetField('account_with_bank');
    resetField('beneficiary_name');
    resetField('swift_code');
    resetField('beneficiary_no');
    if (bank === 'default') {
      setValue('account_with_bank', company?.account_with_bank || '');
      setValue('beneficiary_name', company?.beneficiary_name || '');
      setValue('swift_code', company?.swift_code || '');
      setValue('beneficiary_no', company?.beneficiary_no || '');
    }
  }, [bank]);

  const cartDetailComponent = useMemo(() => {
    return (
      <div className="bg-white rounded-[10px] p-4 min-w-[300px]">
        <p className="text-lg font-bold mb-1">轉帳資訊</p>
        <TextRow title="付款方式">
          <RadioButtonCheckedRoundedIcon className="text-blue20" />
          {PAY_INFO.METHOD}
        </TextRow>
        <TextRow title="付款期限">請於營業日當日下午15:00前完成轉帳</TextRow>
        <TextRow title="銀行名稱">
          {PAY_INFO.BANK_NAME}
          <CopyIcon text={PAY_INFO.BANK_NAME} />
        </TextRow>
        <TextRow title="帳戶名稱">
          {PAY_INFO.ACCOUNT_NAME}
          <CopyIcon text={PAY_INFO.ACCOUNT_NAME} />
        </TextRow>
        <TextRow title="帳戶號碼">
          {PAY_INFO.ACCOUNT}
          <CopyIcon text={PAY_INFO.ACCOUNT} />
        </TextRow>
        <TextRow title="SWIFT CODE">
          {PAY_INFO.SWIFT_CODE}
          <CopyIcon text={PAY_INFO.SWIFT_CODE} />
        </TextRow>
        <p className="text-lg font-bold mt-8 mb-1">訂單摘要</p>
        <TextRow title={`碳權商品(共 ${(cartDetail && cartDetail.product_list?.length) || 0} 件)`}>
          {(cartDetail?.product_amount && formatNumberByComma(cartDetail.product_amount.toString())) || 0} USD
        </TextRow>
        <TextRow title="手續費">{formatNumberByComma(cartDetail?.cost?.toString() as string)} USD</TextRow>
        <TextRow title="稅金5%">{formatNumberByComma(cartDetail?.tax?.toString() as string)} USD</TextRow>

        <div>
          {cartDetail?.product_amount && (
            <div className="flex justify-between mt-8">
              <p className="text-lg font-bold">總金額</p>
              <p className="text-2xl font-bold  font-istok-web">
                {formatNumberByComma(cartDetail?.total_amount?.toString() as string)} USD
              </p>
            </div>
          )}
          {/* <div className="px-7"> */}
          {/* <p className="text-grey text-sm font-bold mb-5 font-istok-web">3項(以下含稅金5%及手續費)</p> */}
          {/* {cartDetail?.product_list?.map((product) => (
              <TextRow
                key={product.name}
                title={product.name}
                value={`$ ${formatNumberByComma(product.amount.toString())}`}
              />
            ))} */}
          {/* </div> */}
        </div>
        {/* <div className="px-7"> */}
        {/* <TextRow title="手續費" value={`$ ${formatNumberByComma(cartDetail?.cost?.toString() as string)}`} />
          <TextRow title="稅金5%" value={`$ ${formatNumberByComma(cartDetail?.tax?.toString() as string)}`} /> */}
        {/* <div className="flex gap-4 justify-between">
            <p className="text-lg font-bold text-black">總付款金額</p>
            <p className="text-lg font-bold text-bright-red">
              TWD {formatNumberByComma(cartDetail?.total_amount?.toString() as string)}
            </p>
          </div> */}
        {/* </div> */}
      </div>
    );
  }, [cartDetail]);

  const BankDataComponent = useMemo(() => {
    return (
      <BankSelectContainer isActive={bank === 'default'} handleActive={() => setValue('bank', 'default')}>
        <div className="flex gap-2 items-center">
          <div className="bg-navy-blue rounded-full text-white whitespace-nowrap text-xs px-2 py-1">預設</div>
          <div className="text-lg font-bold pr-6">{company?.account_with_bank}</div>
        </div>
        <TextRow title="Beneficiary’s Name">
          <span className="ml-1">{company?.beneficiary_name}</span>
        </TextRow>
        <TextRow title="SWIFT CODE">
          <span className="ml-1">{company?.swift_code}</span>
        </TextRow>
        <TextRow title="Beneficiary’s A/C No">
          <span className="ml-1">{company?.beneficiary_no}</span>
        </TextRow>
      </BankSelectContainer>
    );
  }, [company, bank]);

  // const onCheckOut = useCallback(() => {
  //   open(ModalType.CheckOutConfirm, {
  //     buttons: [
  //       {
  //         text: '取消',
  //         isOutline: true
  //       },
  //       {
  //         text: '確認結帳',
  //         onClick: async () => {
  //           const result = await checkOutCart(cartDetailComponent);
  //           if (result.isSuccess) setCheckoutDetail(result.checkoutDetail);
  //         }
  //       }
  //     ]
  //   });
  // }, [cartDetailComponent]);
  // const onCheckOut = async () => {
  //   const result = await checkOutCart(cartDetailComponent);
  //   if (result.isSuccess) setCheckoutDetail(result.checkoutDetail);
  // };

  const handleOpenFile = () => {
    certificateFile?.current?.click();
  };
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    console.log({ file });
    setFileName(file?.name || '');
    if (file) {
      // setValue('payment_receipt', file)
      setFileSource(file);
    }
    if (e.target) e.target.value = '';
  };

  const onSubmit = handleSubmit(async (data) => {
    console.log(data);
    if (!fileSource) {
      alert('請上傳轉帳憑證');
      return;
    }
    // open(ModalType.PaymentReceiptSubmit, {
    //   buttons: [
    //     {
    //       text: '回到首頁',
    //       onClick: () => {
    //         console.log('回到首頁');
    //       }
    //     }
    //   ]
    // });
    await runTask(async () => {
      const dataKeys = [
        'account_with_bank',
        'beneficiary_name',
        'swift_code',
        'beneficiary_no',
        'paid_at',
        // 'payment_receipt',
        'transaction_detail'
      ];

      const formData = new FormData();

      dataKeys.forEach((key) => {
        formData.append(key, data?.[key as keyof typeof data]);
      });
      // formData.append('account_with_bank', data?.account_with_bank || '');
      // formData.append('beneficiary_name', data?.beneficiary_name || '');
      // formData.append('swift_code', data?.swift_code || '');
      // formData.append('beneficiary_no', data?.beneficiary_no || '');
      // formData.append('paid_at', data?.paid_at || '');
      // formData.append('payment_receipt', data?.payment_receipt || '');
      // formData.append('transaction_detail', data?.transaction_detail || '');
      if (fileSource) {
        formData.append('payment_receipt', fileSource);
      }
      await apiClient.trade.tradeTransactionPayRecordPartialUpdate(
        orderId as string,
        formData as unknown as PatchedTransactionDetail
      );
      setTimeout(() => {
        open(ModalType.PaymentReceiptSubmit, {
          buttons: [
            {
              text: '回到首頁',
              onClick: () => {
                console.log('回到首頁');
                navigate('/');
              }
            }
          ]
        });
      }, 100);
    });
  });

  return (
    <LightLayout>
      <div className="pt-4">
        <p className="text-3xl whitespace-nowrap block text-navy-blue mb-2">
          <span className="mr-3 font-bold">|</span>付款資訊
        </p>
        {/* {JSON.stringify(errors)} */}
        <div
          className="yellowScroll overflow-auto pr-3 grid grid-cols-3 gap-6 min-w-[1000px]"
          style={{ height: innerHeight - 160 }}
        >
          {/* 轉帳資訊 */}
          {cartDetailComponent}
          {/* 上傳轉帳憑證 */}
          <div className="col-span-2 bg-white rounded-[10px] flex flex-col justify-between p-4 min-w-[600px]">
            <form onSubmit={onSubmit}>
              <div className="flex gap-8">
                <div className="flex-1">
                  <p className="text-lg font-bold mb-1">上傳轉帳憑證</p>
                  <p className="text-grey mb-2">Step 1. 選擇轉帳銀行</p>

                  {BankDataComponent}
                  <BankSelectContainer isActive={bank === 'other'} handleActive={() => setValue('bank', 'other')}>
                    <p
                      className={classNames(
                        { 'text-grey': bank !== 'other' },
                        { 'text-dark-grey font-bold': bank === 'other' }
                      )}
                    >
                      使用其他銀行帳號
                    </p>
                    {bank === 'other' && (
                      <div className="flex flex-col gap-2 mt-2">
                        <TextField
                          id="account_with_bank"
                          label="Account With Bank"
                          placeholder="Enter your bank account here"
                          register={register}
                          resetField={resetField}
                          errors={errors}
                        />
                        <TextField
                          id="beneficiary_name"
                          label="Beneficiary’s Name"
                          placeholder="Enter Beneficiary name here"
                          register={register}
                          resetField={resetField}
                          errors={errors}
                        />
                        <TextField
                          id="swift_code"
                          label="SWIFT CODE"
                          placeholder="Example : CITIUS12345"
                          register={register}
                          resetField={resetField}
                          errors={errors}
                        />
                        <TextField
                          id="beneficiary_no"
                          label="Beneficiary’s A/C No."
                          placeholder="Enter Beneficiary’s Account Number here"
                          register={register}
                          resetField={resetField}
                          errors={errors}
                        />
                      </div>
                    )}
                  </BankSelectContainer>
                </div>
                <div className="flex-1">
                  <p className="text-grey mt-[32px] mb-2">Step 2. 選擇轉帳日期與上傳憑證</p>
                  <div className="mt-2 mb-2">
                    <label className="text-grey30 mb-2">轉帳日期</label>
                  </div>
                  <ReactDatePicker
                    dateFormat="yyyy/MM/dd"
                    selected={getValues('paid_at') ? new Date(getValues('paid_at')) : new Date()}
                    onChange={(date) => {
                      date && setValue('paid_at', date?.toISOString()?.split('T')[0], { shouldValidate: true });
                    }}
                    className="border border-white30 rounded-lg w-full p-3 !pl-3 hover:border-blue20 focus:border-blue20 focus:shadow focus:shadow-[0_0_8px_0_rgba(0, 145, 233, 0.20)]"
                    wrapperClassName="w-full"
                    maxDate={new Date()}
                    showYearDropdown
                    dateFormatCalendar="MMMM"
                    yearDropdownItemNumber={40}
                    scrollableYearDropdown
                    icon={<InsertInvitationOutlinedIcon />}
                    calendarIconClassname="right-0 text-grey10"
                    showIcon
                    calendarClassName="shadow-lg px-4 !rounded-lg"
                  />
                  <div className="mt-4 mb-2">
                    <label className="text-grey30">上傳轉帳憑證</label>
                  </div>
                  <div
                    onClick={handleOpenFile}
                    className={classNames(
                      'flex flex-col justify-center items-center rounded-2xl p-4 w-full border border-white30 border-dashed bg-vista-white cursor-pointer hover:border-blue20',
                      {
                        'border-warning-red': errors.payment_receipt
                      }
                    )}
                  >
                    <DriveFolderUploadOutlinedIcon className="text-grey10" />
                    <p className="text-dark-grey my-2">{fileName || '選擇檔案'}</p>
                    <p className="text-sm text-grey10">支援的檔案類型：.jpg、.jpeg、.png。</p>
                  </div>
                  {errors && errors['payment_receipt'] && (
                    <p className="text-xms text-bright-red text-center mt-1">
                      {JSON.stringify(errors['payment_receipt']?.message)}
                    </p>
                  )}
                  <input
                    onChange={handleFileChange}
                    type="file"
                    className="hidden"
                    ref={certificateFile}
                    accept="image/png,image/jpg,image/jpeg"
                  />
                  <p className="text-lg font-bold mt-4 mb-2">注意事項</p>
                  <div className="text-xs text-grey flex flex-col gap-2 leading-5">
                    <div>
                      1. 請於訂單成立後之當日平台營業時間內(<span className="text-blue20">09:00-15:00</span>
                      )，將訂單顯示之金額匯款至平台指定帳戶。
                    </div>
                    <div>
                      2. 請<span className="text-blue20">保留匯款相關單據</span>
                      直至本公司確認訂單款項到帳無虞，並於完成匯款後
                      <span className="text-blue20">填寫匯款資訊及上傳匯款單據</span>
                      ，本公司將於訂單成立當日17:00前確認入帳並寄發確認通知信予會員，並於兩個營業日內(T+2)執行交付土星碳權憑證。
                    </div>
                    <div>3. 訂單成立當日17:00前未接獲本公司通知訂單款項到帳，可與本公司聯繫查詢。</div>
                    <div>
                      4.
                      若會員未於當日平台營業時間內將訂單顯示之金額全額給付至本公司指定帳戶，本公司得無條件取消該筆訂單。
                    </div>
                  </div>
                  <div className="text-center mt-4">
                    <button
                      className={classNames('w-[60%] py-2 rounded-full text-white mx-auto my-2 bg-navy-blue', {
                        // ['bg-navy-blue']: cartDetail && cartDetail.product_list?.length,
                        // ['bg-grey']: !cartDetail || cartDetail.product_list?.length === 0
                      })}
                    >
                      確認
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          {isCheckout && cartDetail?.total_amount && (
            <div className="box-shadow bg-white rounded-[10px] mt-5 h-[900px] xl:h-[800px] 2xl:h-[735px] flex flex-col justify-between py-5">
              <TotalPayment checkoutDetail={checkoutDetail} totalPrice={cartDetail?.total_amount} />
            </div>
          )}
        </div>
        {/* <div className="flex justify-end pt-5">
          <Link to="/cart">
            <CustomButton variant="outline" className="rounded-[10px] py-2 px-12 flex items-center text-xl gap-3">
              <img src="/images/certificate/left-arrow.svg" alt="download" />
              回上一頁
            </CustomButton>
          </Link>
        </div> */}
      </div>
    </LightLayout>
  );
};

export default PaymentInformation;

interface TextRowProps {
  title: string;
  children?: React.ReactNode;
}

const TextRow = ({ title, children }: TextRowProps) => {
  return (
    <div className="flex gap-4 justify-between my-4">
      <p className=" text-grey w-[30%] font-istok-web xl:whitespace-nowrap text-xs xl:text-base">{title}</p>
      <div className="text-dark-grey w-[70%] text-end flex justify-end items-center gap-1 text-mdbase">{children}</div>
    </div>
  );
};

interface CopyIconProps {
  text: string;
}
const CopyIcon = ({ text }: CopyIconProps) => {
  const { openSnackbar } = useSnackbar();
  const onCopy = (text: string) => {
    navigator.clipboard.writeText(text);
    openSnackbar('已複製到剪貼簿');
  };
  return (
    <ContentCopyRoundedIcon onClick={() => onCopy(text)} className="text-grey10 cursor-pointer" fontSize="small" />
  );
};
interface BankSelectContainerProps {
  children: React.ReactNode;
  isActive: boolean;
  handleActive?: () => void;
}

const BankSelectContainer = ({ children, isActive, handleActive }: BankSelectContainerProps) => {
  const checkIconPosition = 'absolute top-4 right-4';
  return (
    <div
      className={classNames('border border-white30 rounded-2xl p-4 relative my-2 ease-in duration-150', {
        'bg-vista-white': isActive
      })}
    >
      {children}
      {isActive ? (
        <CheckCircleIcon className={classNames('text-navy-blue', checkIconPosition)} />
      ) : (
        <RadioButtonUncheckedIcon
          className={classNames('cursor-pointer text-grey10', checkIconPosition)}
          onClick={handleActive}
        />
      )}
    </div>
  );
};
