// import { LinearProgress, linearProgressClasses, Menu, MenuItem } from '@mui/material';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { Menu, MenuItem } from '@mui/material';
// import Avatar from '@mui/material/Avatar';
// import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
// import { styled } from '@mui/material/styles';
// import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import { useEffect } from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import { useCompanyStore } from '@/store/company';
import { useInventoryStore } from '@/store/inventory';
import { useUserStore } from '@/store/user';
import { OrderStatus } from '@/types/historical';

// import { formatNumberByComma } from '@/util/helper';

// const BorderLinearProgress = styled(LinearProgress)(() => ({
//   height: 9,
//   borderRadius: 5,
//   width: '142px',

//   [`&.${linearProgressClasses.colorPrimary}`]: {
//     backgroundColor: '#FFD600',
//     borderRadius: 5
//   },
//   [`& .${linearProgressClasses.bar}`]: {
//     borderRadius: 5,
//     backgroundColor: '#005487'
//   }
// }));
type NavItem = {
  title: string;
  path: string;
};

export default function UserOptionsDropdown() {
  const [company, employee] = useCompanyStore((state) => [state.company, state.employee]);
  const getOrdersInfo = useInventoryStore((state) => state.getOrdersInfo);
  useEffect(() => {
    getOrdersInfo();
  }, []);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  // const ordersInfo = useInventoryStore((state) => state.ordersInfo);
  const logout = useUserStore((state) => state.logout);
  const [navData, setNavData] = useState<NavItem[]>([
    {
      title: '會員中心',
      path: '/v2/account-information'
    },
    {
      title: '購買清單',
      path: `/v2/order-list/${OrderStatus.Unpaid}`
    },
    {
      title: '我的賣場',
      path: `/v2/my-store/${OrderStatus.Unpaid}`
    },
    // {
    //   title: '歷史訂單',
    //   path: '/historical-order'
    // },
    {
      title: '操作記錄',
      path: '/operation-record'
    }
  ]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const logoutHandler = async () => {
    await logout();
    window.location.replace('/login');
  };

  return (
    // <CustomWidthTooltip title={<UserMenu />} arrow>
    //   <img
    //     alt="sacurn"
    //     src="/images/navbar/member.svg"
    //     className="2xl:w-10.5 2xl:h-10.5 w-8 h-8 cursor-pointer pt-1"
    //     width={42}
    //     height={42}
    //   />
    // </CustomWidthTooltip>
    <>
      <IconButton
        onClick={handleClick}
        size="small"
        aria-controls={open ? 'account-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
      >
        {/* <Avatar sx={{ width: 32, height: 32 }}>M</Avatar> */}
        <AccountCircleOutlinedIcon className="text-yellow" />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            pb: 1,
            px: 2,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1
            },
            '& .MuiButtonBase-root': {
              display: 'flex',
              px: 4,
              py: 2
            },
            '& .MuiMenuItem-root': {
              justifyContent: 'center',
              py: 1
            },
            '&::before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0
            }
          }
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {/* <div className="pt-3 pb-4 flex flex-col items-center gap-2">
          <p className="text-lg font-normal text-black">X Holdings・Musk</p>
          <div className="border-4 border-pale-yellow rounded-full w-[67px] h-[67px]">
            <img
              alt="sacurn"
              src="/images/navbar/musk.jpeg"
              className="w-full h-full object-cover rounded-full"
              width={67}
              height={67}
            />
          </div>
          <div className="relative p-0.7 rounded-[20px] flex items-center bg-pale-yellow">
            <BorderLinearProgress
              variant="determinate"
              value={(ordersInfo.acc_amount || 0 / ordersInfo?.upgrade?.points) * 100}
            />
          </div>
          <p className="text-sm font-normal text-black">
            {formatNumberByComma(ordersInfo.acc_point as number)}/{formatNumberByComma(ordersInfo?.upgrade?.points)}
          </p>
          <p className="text-navy-blue text-sm"> 管理者</p>
        </div>
        <Divider /> */}
        <div className="text-center flex flex-col items-center gap-1 p-1 border-b border-transparent-grey pb-3">
          <p>{employee?.last_name || ''}</p>
          <p className="text-xs text-grey10">{company?.name || ''}</p>
          <div className="text-xs text-white bg-navy-blue rounded-full w-fit px-2 py-1">
            {employee?.group_name || ''}
          </div>
        </div>

        {navData.map((navItem: NavItem) => {
          return (
            <Link key={navItem.title} to={navItem.path}>
              <MenuItem onClick={handleClose} className=" hover:text-navy-blue active:text-navy-blue">
                {navItem.title}
              </MenuItem>
            </Link>
          );
        })}
        {/* <Link to="/historical-order">
          <MenuItem className=" hover:text-navy-blue active:text-navy-blue">歷史訂單</MenuItem>
        </Link>
        <Link to="/operation-record">
          <MenuItem className=" hover:text-navy-blue active:text-navy-blue">操作記錄</MenuItem> 
        </Link>*/}
        <MenuItem onClick={logoutHandler} className=" hover:text-navy-blue active:text-navy-blue">
          登出
        </MenuItem>
      </Menu>
    </>
  );
}

// const UserMenu = () => {
//   return (
//     <Menu
//       anchorEl={anchorEl}
//       id="account-menu"
//       open={open}
//       onClose={handleClose}
//       onClick={handleClose}
//       PaperProps={{
//         elevation: 0,
//         sx: {
//           overflow: 'visible',
//           filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
//           mt: 1.5,
//           '& .MuiAvatar-root': {
//             width: 32,
//             height: 32,
//             ml: -0.5,
//             mr: 1
//           },
//           '&::before': {
//             content: '""',
//             display: 'block',
//             position: 'absolute',
//             top: 0,
//             right: 14,
//             width: 10,
//             height: 10,
//             bgcolor: 'background.paper',
//             transform: 'translateY(-50%) rotate(45deg)',
//             zIndex: 0
//           }
//         }
//       }}
//       transformOrigin={{ horizontal: 'right', vertical: 'top' }}
//       anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
//     >
//       <MenuItem onClick={handleClose}>
//         <Avatar /> Profile
//       </MenuItem>
//       <MenuItem onClick={handleClose}>
//         <Avatar /> My account
//       </MenuItem>
//       <Divider />
//       <MenuItem onClick={handleClose}>
//         <ListItemIcon>
//           <PersonAdd fontSize="small" />
//         </ListItemIcon>
//         Add another account
//       </MenuItem>
//       <MenuItem onClick={handleClose}>
//         <ListItemIcon>
//           <Settings fontSize="small" />
//         </ListItemIcon>
//         Settings
//       </MenuItem>
//       <MenuItem onClick={handleClose}>
//         <ListItemIcon>
//           <Logout fontSize="small" />
//         </ListItemIcon>
//         Logout
//       </MenuItem>
//     </Menu>
//   );
// };

// const UserMenu = () => {
//   const ordersInfo = useInventoryStore((state) => state.ordersInfo);
//   const logout = useUserStore((state) => state.logout);

//   const logoutHandler = async () => {
//     await logout();
//     window.location.replace('/login');
//   };

//   return (
//     <div className="pt-5 flex flex-col items-center gap-2">
//       <p className="text-lg font-normal text-black">X Holdings・Musk</p>
//       <div className="border-4 border-pale-yellow rounded-full w-[67px] h-[67px]">
//         <img
//           alt="sacurn"
//           src="/images/navbar/musk.jpeg"
//           className="w-full h-full object-cover rounded-full"
//           width={67}
//           height={67}
//         />
//       </div>
//       <div className="relative p-0.7 rounded-[20px] flex items-center bg-pale-yellow">
//         <BorderLinearProgress
//           variant="determinate"
//           value={(ordersInfo.acc_amount || 0 / ordersInfo?.upgrade?.points) * 100}
//         />
//       </div>
//       <p className="text-sm font-normal text-black">
//         {formatNumberByComma(ordersInfo.acc_point as number)}/{formatNumberByComma(ordersInfo?.upgrade?.points)}
//       </p>
//       <p className="text-navy-blue text-sm"> 管理者</p>
//       <div>
//         <Link to="/v2/account-information">
//           <MenuItem
//             sx={{ color: 'black', display: 'flex', justifyContent: 'center', fontSize: '18px' }}
//             className="text-black hover:text-navy-blue active:text-navy-blue flex justify-center"
//           >
//             會員中心
//           </MenuItem>
//         </Link>
//         <Link to="/historical-order">
//           <MenuItem
//             sx={{ color: 'black', display: 'flex', justifyContent: 'center', fontSize: '18px' }}
//             className="text-black hover:text-navy-blue active:text-navy-blue"
//           >
//             歷史訂單
//           </MenuItem>
//         </Link>
//         <Link to="/operation-record">
//           <MenuItem
//             sx={{ color: 'black', display: 'flex', justifyContent: 'center', fontSize: '18px' }}
//             className="text-black hover:text-navy-blue active:text-navy-blue"
//           >
//             操作記錄
//           </MenuItem>
//         </Link>
//         <MenuItem
//           onClick={logoutHandler}
//           sx={{ color: 'black', display: 'flex', justifyContent: 'center', fontSize: '18px' }}
//           className="text-black hover:text-navy-blue active:text-navy-blue"
//         >
//           登出
//         </MenuItem>
//       </div>
//     </div>
//   );
// };
