import Navbar from '@/components/Navbar';

interface IProps {
  children: React.ReactNode;
}

const LightLayout = ({ children }: IProps) => {
  return (
    <div className="h-screen overflow-hidden bg-neutral-150">
      <Navbar className="relative z-30 !bg-navy-blue h-[70px]" />
      <div className="px-10">{children}</div>
    </div>
  );
};

export default LightLayout;
