import classNames from 'classnames';
import React, { Fragment, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// import formatDate from '@/helpers/formatDate';
import { useCompanyStore } from '@/store/company';
// import { useInventoryStore } from '@/store/inventory';
import { useMembershipStepsStore } from '@/store/memberShipSteps';
// import { usePlatformStore } from '@/store/platform';
import { MembershipStep } from '@/type';

// import { formatNumberByComma } from '@/util/helper';
import Navbar from '../Navbar';
import AccountSteps from '../v2/AccountSteps';
// import RangeSlider from './RangeSlider';

interface IProps {
  children: React.ReactNode;
  background?: 'primary' | 'secondary';
  variant?: 'secondary';
}

const MemberLayout = ({ children, variant }: IProps) => {
  const { pathname } = useLocation();
  // const navigate = useNavigate();
  // const membership = useMembershipStepsStore((state) => state.step);
  const getCompanyInfo = useCompanyStore((state) => state.getCompany);
  // const [company] = useCompanyStore((state) => [state.company, state.getCompany]);
  // const ordersInfo = useInventoryStore((state) => state.ordersInfo);
  // const toggleCardComparison = usePlatformStore((state) => state.toggleCardComparison);

  useEffect(() => {
    const step = pathname.split('-').pop()?.toUpperCase();
    let current_membership_step: MembershipStep | undefined = undefined;
    if (step && ['RENEWAL', 'REISSUE', 'REVOKED'].includes(step)) {
      current_membership_step = step as MembershipStep;
    }
    if (current_membership_step) {
      useMembershipStepsStore.setState({ step: MembershipStep[current_membership_step] });
    } else {
      useMembershipStepsStore.setState({ step: undefined });
    }
  }, []);

  useEffect(() => {
    (async () => {
      await getCompanyInfo();
    })();
  }, []);

  return (
    <Fragment>
      <Navbar className="!bg-navy-blue py-4" />
      <div
        className={classNames(
          "bg-[url('../public/v2/bg.png')] w-full min-h-[calc(100vh-75px)] 2xl:min-h-[calc(100vh-74px)] bg-no-repeat bg-cover",
          {
            "bg-[url('../public/v2/secondary.png')]":
              pathname === '/v2/card-renewal' || pathname === '/v2/membership-upgrade'
          }
        )}
      >
        <div className="flex flex-col justify-center items-center p-4">
          {variant !== 'secondary' && (
            <>
              <div className="min-w-[1000px]">
                <AccountSteps />
              </div>
              <div className="flex justify-center max-w-[1000px]">{children}</div>
            </>
          )}
          {variant === 'secondary' && <div className="flex justify-center max-w-[1000px]">{children}</div>}
        </div>
      </div>
    </Fragment>
  );
};

export default MemberLayout;

// const MEMBERSHIP_STEPS: MembershipTypes[] = [
//   {
//     id: 1,
//     title: '會員卡續卡',
//     slug: MembershipStep.RENEWAL,
//     path: MembershipStepsPath.RENEWAL,
//     icon: '/v2/layout/card.svg'
//   },
//   {
//     id: 2,
//     title: '會員卡補發',
//     slug: MembershipStep.REISSUE,
//     path: MembershipStepsPath.REISSUE,
//     icon: '/v2/layout/help.svg'
//   },
//   {
//     id: 3,
//     title: '會員卡廢止',
//     slug: MembershipStep.REVOKED,
//     path: MembershipStepsPath.REVOKED,
//     icon: '/v2/layout/cancel.svg'
//   }
// ];
