import { ElementType } from 'react';

export type TableHeader = {
  title: string;
  key: string;
};

export type SideBarItem = {
  title: string;
  icon: ElementType;
  status: OrderStatus;
};
export enum OrderStatus {
  Unpaid = '0',
  PaymentConfirmationInProgress = '1',
  OrderCompleted = '4',
  OrderNotEstablished = '2,3',
  HistoricalOrder = '0,2,3,4'
}
export type Order = {
  id: number;
  orderNumber: string;
  total: number;
  fee: number;
  tax: number;
  payExpireTime?: string;
  paidTime?: string;
  compeletedTime?: string;
  cancelTime?: string;
  paymentInfo?: string;
  orderStatus: OrderStatus;
  creditProducts: CreditProduct[];
};
export type CreditProduct = {
  id: number;
  sid: string;
  name: string;
  unitPrice: number;
  amount: number;
  total: number;
};

export type Filters = {
  page?: number;
  range?: string;
  status?: string;
  keyword?: string;
};
