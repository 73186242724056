import { useState } from 'react';

import SelectField from './SelectField';

const yearArray = Array.from({ length: 125 }, (_, index) => 1900 + index);
const months = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];
const dates = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23',
  '24',
  '25',
  '26',
  '27',
  '28',
  '29',
  '30',
  '31'
];

type DateSelectFieldProps = {
  yearFieldName: string;
  monthFieldName: string;
  dateFieldName: string;
};

const DateSelectField = (props: DateSelectFieldProps) => {
  const { yearFieldName, monthFieldName, dateFieldName } = props;
  const [dateList, setDateList] = useState<string[]>(dates);

  return (
    <>
      <SelectField
        label="年"
        name={yearFieldName}
        width="87px"
        options={yearArray.map((year) => ({ name: year.toString(), value: year.toString() }))}
      />
      /
      <SelectField
        label="月"
        name={monthFieldName}
        width="82px"
        options={months.map((month) => ({ name: month, value: month }))}
        onChange={(value) => {
          if (!value) return;
          if (value === '2') {
            setDateList(Array.from({ length: 29 }, (_, i) => (i + 1).toString()));
          } else if (['4', '6', '9', '11'].includes(value)) {
            setDateList(Array.from({ length: 30 }, (_, i) => (i + 1).toString()));
          } else {
            setDateList(Array.from({ length: 31 }, (_, i) => (i + 1).toString()));
          }
        }}
      />
      /
      <SelectField
        label="日"
        name={dateFieldName}
        width="82px"
        options={dateList.map((date) => ({ name: date, value: date }))}
      />
    </>
  );
};

export default DateSelectField;
