import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { styled } from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Button from '@/components/Kit/Button';
import Navbar from '@/components/Navbar';
import OutlineRounedSearchBar from '@/components/OutlineRounedSearchBar';
import SalesAllTable from '@/components/Sales/SalesAllTable';
import SalesLaunchedTable from '@/components/Sales/SalesLaunchedTable';
import { BASE_URL } from '@/constant';
import type { Filters } from '@/store/stockList';
import { useStockListStore } from '@/store/stockList';

const StyledTabs = styled(Tabs)({
  '& .MuiTabs-indicator': {
    backgroundColor: '#005487'
  },
  '& .Mui-selected': {
    color: '#005487 !important',
    fontWeight: 'bold'
  }
});
interface StyledTabProps {
  label: string;
  to: string;
}
const StyledTab = styled((props: StyledTabProps) => {
  const navigate = useNavigate();
  return <Tab onClick={() => navigate(props.to)} disableRipple {...props} />;
})(() => ({
  color: '#747474'
}));

const Sales = () => {
  const { launched } = useParams();
  const [keyword, setKeyword] = useState<string>('');

  const stockList = useStockListStore((store) => store.stockList);
  const onMarketList = useStockListStore((store) => store.onMarketList);
  const onMarketListCount = useStockListStore((store) => store.onMarketListCount);
  const getAllOnMarketListCount = useStockListStore((store) => store.getAllOnMarketListCount);
  const updateOnMarketList = useStockListStore((store) => store.updateOnMarketList);
  const getStockList = useStockListStore((store) => store.getStockList);
  const [tab, setTab] = useState(0);
  const a11yProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`
    };
  };
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };
  const handleSearchGetData = useMemo(() => {
    if (tab === 0) {
      return ({ keyword }: Filters) => getStockList(keyword);
    } else {
      return ({ keyword }: Filters) => updateOnMarketList({ keyword });
    }
  }, [stockList, onMarketList]);

  useEffect(() => {
    if (launched === 'launched') {
      setTab(1);
      return;
    }
    getAllOnMarketListCount();
  }, []);

  return (
    <div className="w-full h-screen overflow-hidden bg-neutral-250">
      {/* navbar */}
      <Navbar className="fixed top-0 left-0 right-0 !bg-navy-blue h-[70px] z-[100]" />
      <div className="h-[98%] p-6 pt-[70px] overflow-hidden">
        <div className="flex items-center mb-4 mt-4 w-full gap-4">
          <h1 className="text-2xl min-w-[300px] text-navy-blue mr-auto">
            <span className="mr-2 font-semibold">|</span>
            我的庫存
          </h1>
          <OutlineRounedSearchBar
            placeholder="請輸入碳權名稱或是關鍵字搜尋"
            getData={handleSearchGetData}
            watch={tab}
          />

          {stockList?.length > 0 && tab === 0 && (
            <a
              className=""
              href={`${BASE_URL}/inventory?download=1${keyword === '' ? '' : `&keyword=${keyword}`}`}
              target="_blank"
              download
            >
              <Button className="!bg-grey flex items-center gap-1 hover:!bg-grey10 active:!bg-dark-grey">
                <FileDownloadOutlinedIcon />
                Download
              </Button>
            </a>
          )}
        </div>
        <div className="border-b border-white30 mb-4">
          <StyledTabs value={tab} onChange={handleTabChange}>
            <StyledTab to="/sales" label={`所有(${stockList?.length || 0})`} {...a11yProps(0)} />
            <StyledTab to="/sales/launched" label={`上架中(${onMarketListCount || 0})`} {...a11yProps(1)} />
          </StyledTabs>
        </div>
        {tab === 0 && <SalesAllTable />}
        {tab === 1 && <SalesLaunchedTable setTab={setTab} />}
      </div>
    </div>
  );
};

export default Sales;
