import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import * as yup from 'yup';

import CustomButton from '@/components/CustomButton';
import { useForgotPasswordStore } from '@/store/forgotPassword';

type PasswordResetFormType = {
  accountNumber?: string;
  email: string;
};

const schema = yup
  .object({
    accountNumber: yup.string(),
    email: yup.string().email().required('請輸入正確的email格式')
  })
  .required();

const PasswordRecoveryFilling = () => {
  const setUserEmail = useForgotPasswordStore((state) => state.setUserEmail);
  const setIsTermsReadModalOpen = useForgotPasswordStore((state) => state.setIsRequireTermsReadModalOpen);
  const setIsTermsModalOpen = useForgotPasswordStore((state) => state.setIsTermsModalOpen);
  const setIsDoNotAgreeTermsModalOpen = useForgotPasswordStore((state) => state.setIsDoNotAgreeTermsModalOpen);
  const isAgreedToTerms = useForgotPasswordStore((state) => state.isAgreedToTerms);
  const setIsAgreedToTerms = useForgotPasswordStore((state) => state.setIsAgreedToTerms);
  const currentStep = useForgotPasswordStore((state) => state.currentStep);
  const setCurrentStep = useForgotPasswordStore((state) => state.setCurrentStep);
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<PasswordResetFormType>({ resolver: yupResolver(schema) });

  const onSubmit = handleSubmit((data) => {
    if (!isAgreedToTerms) {
      setIsTermsReadModalOpen(true);
      return;
    }
    setUserEmail(data.email);
    setCurrentStep(currentStep + 1);
  });

  return (
    <form onSubmit={onSubmit}>
      <div className=" w-[90%] mx-auto flex flex-col gap-[33px] py-5 ">
        <div className="w-full py-[38px] flex justify-center  rounded-2.5xl bg-white shadow-completed-box">
          <div className="flex gap-5 text-  sm lg:text-base xl:text-xl items-center ">
            <label htmlFor="" className="font-istok-web text-navy-blue font-bold">
              <span className="text-bright-red"> *</span>
              email
            </label>
            <div className="relative">
              <input
                type="text"
                className="font-istok-web text-navy-blue text-xl font-bold tracking-[0.6px] border border-navy-blue py-2 pl-2 w-[610px] rounded-md "
                placeholder={'請輸入完整email'}
                {...register('email')}
              />
              {errors.email && (
                <p className="text-bright-red text-sm absolute bottom-[-20px]">{errors.email.message}</p>
              )}
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-8 2xl:gap-13">
          <div className="flex gap-[50px] min-h-[431px]">
            <div className="shadow-completed-box rounded-2.5xl bg-white px-9 py-13 pt-15 w-[50%] flex flex-col justify-between">
              <p className="font-istok-web pb-20 text-justify text-dark-grey text-xl leading-[34px] tracking-[0.6px] font-bold">
                <span className="text-bright-red font-bold">*</span>本人已於合理期間(至少三日)詳細審閱個人
                <span
                  className="text-bright-red font-bold relative border-b cursor-pointer"
                  onClick={() => setIsTermsModalOpen(true)}
                >
                  「Sacurn服務條款」
                </span>
                內容，並充分瞭解且確認同意遵守，並已詳閱Sacurn服務條款中以顯著字體表示之重要約定事項，有疑問之處業經本人向貴司提出詢問並業經貴司說明及解答，對重要約定事項已充分理解並同意其內容。
              </p>
              <div className=" flex justify-center gap-10 font-bold ">
                <div className="w-max relative">
                  {isAgreedToTerms && (
                    <img src="/images/password-reset/confirm.svg" className="absolute -top-.5 right-6" />
                  )}
                  <CustomButton
                    variant="primary"
                    className={classNames(
                      'font-inter rounded-full px-10 xl:px-18 h-12 text-sm lg:text-base xl:text-xl mt-3 border-[1.68px] font-bold bg-white !text-navy-blue ',
                      { 'border-bright-red': isAgreedToTerms, 'border-navy-blue': !isAgreedToTerms }
                    )}
                    type="button"
                    onClick={() => setIsAgreedToTerms(!isAgreedToTerms)}
                  >
                    我同意
                  </CustomButton>
                </div>

                <CustomButton
                  variant="primary"
                  className="font-inter rounded-full px-8 lg:px-10 xl:px-15 h-12 text-sm lg:text-base xl:text-xl mt-3 font-bold border-[1.68px] bg-white !text-navy-blue "
                  onClick={() => setIsDoNotAgreeTermsModalOpen(true)}
                  type="button"
                >
                  我不同意
                </CustomButton>
              </div>
            </div>
            <div className="font-istok-web py-11 pt-15 text-xl font-bold tracking-[0.6px] flex flex-col gap-1 w-[50%] text-dark-grey">
              <h2 className="">提醒您</h2>
              <ul className="list-disc px-9 leading-[34px]">
                <li>若您忘記密碼，需請您填寫您的註冊信箱。</li>
                <li>完成身分驗證後，平台將寄信至您的信箱，請您點擊信件中的重設密碼連結後，重新設定密碼。</li>
                <li>密碼重設連結僅30分鐘內有效，請您成功送出申請後，儘速完成重設密碼。</li>
              </ul>
            </div>
          </div>

          <div className=" flex justify-center gap-10 font-bold">
            <Link to="/">
              <CustomButton
                variant="primary"
                className="rounded-[14px] px-[58px] h-10 text-xl border-[1.4px] bg-white !text-navy-blue "
              >
                取消申請
              </CustomButton>
            </Link>
            <CustomButton variant="primary" className="rounded-[14px] border-[1.4px] px-17 h-10 text-xl" type="submit">
              下一步
            </CustomButton>
          </div>
        </div>
      </div>
    </form>
  );
};

export default PasswordRecoveryFilling;
