import SearchIcon from '@mui/icons-material/Search';

import { PlatformAccordianTypes } from '@/type';

import PlatformAccordian from './PlatformAccordian';

const PlatformInstructions = () => {
  return (
    // <div className=" mt-[40px] w-[88%] rounded-lg bg-trans-white mt-4 p-4 pt-5  md:w-[80%] lg:w-[86%] [@media(min-width:1400px)]:w-[90%]">
    <div className="rounded-lg bg-trans-white p-4">
      {/* <div className="flex justify-end pr-4">
        <div className="w-[40%] border text-grey-ghoose text-xs rounded-full bg-white pl-4 flex justify-between p-2">
          <input
            type="text"
            placeholder="輸入想搜尋的關鍵字"
            className="w-[60%] outline-none text-xs leading-[14.52px] tracking-[7%] font-bold"
          />
          <div className="border-l-2 w-[20%] flex justify-end ">
            <SearchIcon className="text-[26px]" />
          </div>
        </div>
      </div> */}
      <div className=" mt-3 overflow-y-scroll yellowScroll h-[500px] [@media(min-width:1400px)]:h-[690px]  [@media(min-width:1700px)]:h-[710px]">
        <div className="w-full p-5 pt-0">
          {INSTRUCTION_CARD_DATA.map(({ id, title, items }: PlatformAccordianTypes, index) => {
            return <PlatformAccordian key={index} currentIndex={id} title={title} items={items} />;
          })}
        </div>
      </div>
    </div>
  );
};

export default PlatformInstructions;

export const INSTRUCTION_CARD_DATA: PlatformAccordianTypes[] = [
  // {
  //   id: 1,
  //   title: '平台使用說明',
  //   items: [
  //     {
  //       name: '如何查詢舊訂單？',
  //       description: ''
  //     },
  //     {
  //       name: '如何取消訂單？',
  //       description: ''
  //     }
  //   ]
  // },
  {
    id: 2,
    title: '權限設定說明',
    items: [
      {
        name: '如何更改權限？',
        description: '可由管理員至企業帳號總覽，選擇欲修改權限之帳號，進入資料修改畫面進行操作權限的修改。'
      }
      // {
      //   name: '權限的區分？',
      //   description: '請參照權限區分表。'
      // }
    ]
  },
  {
    id: 3,
    title: '帳號常見問題',
    items: [
      {
        name: '如何修改會員資料？',
        description: '如欲修改自己的帳號資料，可到帳號資訊>個人資訊>變更資料進行修改，但不可修改帳號與權限。'
      },
      {
        name: '忘記密碼如何重新設定？',
        description: '如忘記密碼可透過登入頁面的 Forgot Password進行密碼的重新設定。'
      }
    ]
  },
  {
    id: 4,
    title: '碳權交易相關',
    items: [
      {
        name: '已從購物車下訂碳權，要如何完成交易?',
        description:
          '於購物車下訂之碳權須在線下匯款後返回系統，並上傳匯款憑證交由本公司進行審核，完成審核後將會Email交易結果至您留存的信箱內。'
      },
      {
        name: '待付款之訂單要如何開啟並上傳匯款憑證?',
        description: `1. 登入後點擊平台網頁的右上角，打開選單。
        2. 選擇購買清單，進入購買清單的待付款分頁。
        3. 找到該筆訂單後，點擊最右側的黃色訂單按鈕。
        4. 進入該筆訂單的匯款資訊頁面。
        5. 填寫匯款銀行資訊，並上傳匯款憑證。
        6. 點擊確認按鈕即可提交匯款憑證，實際交易結果將Email至信箱內。v`
      },
      {
        name: '已提交匯款憑證之訂單什麼時候才會知道交易是否成立之結果?',
        description: `本公司將於訂單成立當日17:00前確認入帳並寄發確認通知信予會員，並於兩個營業日內(T+2)執行交付土星碳權憑證。
        訂單成立當日17:00前未接獲本公司通知訂單款項到帳，可與本公司聯繫查詢。`
      },
      {
        name: '如何上架自己的碳權?',
        description: `1. 登入後點擊平台網頁的上方功能列選擇「My Stock」。
        2. 進入庫存列表後，點擊其中一筆碳權最右側的「上架碳權」按鈕。
        3. 進入上架編輯頁，輸入上架數量、單價、最低購買數量。
        4. 確認並同意購買須之後即可上架碳權。`
      },
      {
        name: '如何下架自己的碳權?',
        description: `1. 登入後點擊平台網頁的上方功能列選擇「My Stock」。
        2. 選擇左上角的「上架中」分頁。
        3. 點擊其中一筆碳權最右側的「停止交易」按鈕。
        4. 確定停止交易後即可下架碳權。`
      }
    ]
  }
];
