import { useState } from 'react';

import { TransactionBuySerializers } from '@/libs/api';
import type { Order, TableHeader } from '@/types/historical';
import { OrderStatus } from '@/types/historical';
const headersData = [
  { title: '訂單號碼', key: 'orderNumber' },
  { title: '商品名稱', key: 'productName' },
  { title: '單價(USD)', key: 'unitPrice' },
  { title: '數量(噸)', key: 'amount' },
  { title: '總金額(USD)', key: 'total' }
  // { title: '訂單狀態', key: 'orderStatus' }
  // { title: '付款資訊', key: 'paymentInfo' }
];
// const orderFakeData: TransactionBuySerializers[] = [
//   {
//     order_no: 'A123456780',
//     total: 1000,
//     status: 0,
//     fee: 200,
//     tax: 25000,
//     pay_at: '2024-09-01 12:00:00',
//     deadline: '2024-04-29T05:54:27.647Z',
//     complete_at: '2024-04-29T05:54:27.647Z',
//     cancel_at: '2024-04-29T05:54:27.647Z',
//     orders: [
//       {
//         company_code: 'Mr12345670',
//         name: 'Kasigau Corridor II REDD+ Forest Conservation Carbon avoidance Andes Inorganic Soil Carbon Andes Inorganic Soil Carbon Soil Carbon Adffdsaas Iasd',
//         price: 500,
//         quantity: 2,
//         total: 1000
//       },
//       {
//         company_code: 'Mr12345671',
//         name: 'Kasigau Corridor II REDD+ Forest Conservation Carbon avoidance Andes Inorganic Soil Carbon Andes Inorganic Soil Carbon Soil Carbon Adffdsaas Iasd',
//         price: 500,
//         quantity: 2,
//         total: 1000
//       }
//     ]
//   },
//   {
//     order_no: 'A123456781',
//     total: 1000,
//     status: 0,
//     fee: 200,
//     tax: 25000,
//     pay_at: '2024-09-01 12:00:00',
//     deadline: '2024-04-29T05:54:27.647Z',
//     complete_at: '2024-04-29T05:54:27.647Z',
//     cancel_at: '2024-04-29T05:54:27.647Z',
//     orders: [
//       {
//         company_code: 'Mr12345670',
//         name: 'Kasigau Corridor II REDD+ Forest Conservation Carbon avoidance Andes Inorganic Soil Carbon Andes Inorganic Soil Carbon Soil Carbon Adffdsaas Iasd',
//         price: 500,
//         quantity: 2,
//         total: 1000
//       },
//       {
//         company_code: 'Mr12345671',
//         name: 'Kasigau Corridor II REDD+ Forest Conservation Carbon avoidance Andes Inorganic Soil Carbon Andes Inorganic Soil Carbon Soil Carbon Adffdsaas Iasd',
//         price: 500,
//         quantity: 2,
//         total: 1000
//       }
//     ]
//   }
// ];

interface TransDataItem {
  tagText: string;
  tagColor: string;
  timeText: string;
  timeKey: string;
}

type TransData = {
  [key: string]: TransDataItem;
};
const transData: TransData = {
  [OrderStatus.Unpaid]: {
    tagText: '待付款',
    tagColor: 'bg-warning-red',
    timeText: '付款截止時間',
    timeKey: 'deadline'
  },
  [OrderStatus.PaymentConfirmationInProgress]: {
    tagText: '',
    tagColor: '',
    timeText: '付款時間',
    timeKey: 'pay_at'
  },
  [OrderStatus.OrderCompleted]: {
    tagText: '已成立',
    tagColor: 'bg-navy-blue',
    timeText: '交易完成日期',
    timeKey: 'complete_at'
  },
  [OrderStatus.OrderNotEstablished]: {
    tagText: '未成立',
    tagColor: 'bg-[#414141]',
    timeText: '交易取消日期',
    timeKey: 'cancel_at'
  }
};

const useOrder = () => {
  const [headers, setHeaders] = useState<TableHeader[]>(headersData);
  // TODO: orders可能會放store
  const [orders, setOrders] = useState<TransactionBuySerializers[]>([]);
  const transStatus = (orderStatus: string) => {
    switch (orderStatus) {
      case OrderStatus.Unpaid:
        return OrderStatus.Unpaid;
      case OrderStatus.PaymentConfirmationInProgress:
        return OrderStatus.PaymentConfirmationInProgress;
      case OrderStatus.OrderCompleted:
        return '4';
      case OrderStatus.OrderNotEstablished:
        return '2,3';
      default:
        return '0';
    }
  };
  return {
    headersData,
    transData,
    headers,
    orders,
    setOrders,
    setHeaders,
    transStatus
  };
};
export default useOrder;
