import SortIcon from '@mui/icons-material/Sort';
import classNames from 'classnames';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { InventoryOnMarket } from '@/libs/api';
import { ModalType, useModalStore } from '@/store/modal';
import { useStockListStore } from '@/store/stockList';
import { carbonColorClass, carbonTypeChToEn, formatNumberByComma } from '@/util/helper';
import { useInnerHeight } from '@/util/useInnerHeight';

type SalesLaunchedTableProps = {
  setTab: (tab: number) => void;
};

const SalesLaunchedTable = ({ setTab }: SalesLaunchedTableProps) => {
  const innerHeight = useInnerHeight();
  const navigate = useNavigate();
  const filter = useStockListStore((store) => store.filters);
  const onMarketList = useStockListStore((store) => store.onMarketList);
  const open = useModalStore((state) => state.open);
  const updateOnMarketList = useStockListStore((store) => store.updateOnMarketList);
  const updateStockOffShelve2 = useStockListStore((store) => store.updateStockOffShelve2);

  const TABLE_HEAD = ['碳權名稱', '種類', 'Vintage', '上架數量', 'USD/噸', '最低購買數量', '操作'];
  const tbodyTdStyle = 'p-4';

  const pauseOrder = (item: InventoryOnMarket) => {
    open(ModalType.MakeStockOffShelve, {
      buttons: [
        {
          text: '取消',
          isOutline: true
        },
        {
          text: '確定',
          onClick: () => {
            updateStockOffShelve2(item.id, <></>);
          }
        }
      ]
    });
  };
  const goToSales = () => {
    setTab(0);
    navigate('/sales');
  };
  const handleSort = () => {
    if (filter.sortBy === 'name') {
      updateOnMarketList({ sortBy: '' });
    } else {
      updateOnMarketList({ sortBy: 'name' });
    }
  };

  useEffect(() => {
    updateOnMarketList();
  }, []);

  return (
    <>
      {onMarketList.length > 0 ? (
        <div className="yellowScroll w-full overflow-auto overflow-x-hidden px-2" style={{ height: innerHeight - 200 }}>
          <table className="w-full border-separate border-spacing-y-4 mt-[-16px] table-auto">
            <thead className="sticky z-10 top-[0] bg-neutral-250">
              <tr>
                {TABLE_HEAD?.map((item, index) => (
                  <th
                    key={index}
                    className={classNames(
                      'p-4 text-center text-grey text-sm whitespace-nowrap font-normal  bg-white first:rounded-l-xl last:rounded-r-xl',
                      {
                        '!w-[40%]': index === 0,
                        '!text-left': index === 0
                      }
                    )}
                  >
                    {item}
                    {index === 0 && (
                      <SortIcon
                        onClick={handleSort}
                        className="text-grey ml-1 cursor-pointer hover:text-grey10 active:text-grey30"
                      />
                    )}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {onMarketList.map((item) => (
                <tr key={item.id} className="bg-white outline outline-1 outline-white30 rounded-lg">
                  <td
                    className={classNames(
                      tbodyTdStyle,
                      'cursor-pointer hover:underline hover:text-navy-blue decoration-navy-blue underline-offset-2'
                    )}
                  >
                    <span
                      onClick={() =>
                        navigate(`/new-product-details/${item.carbon_credit}?type=${carbonTypeChToEn(item.carbon_tag)}`)
                      }
                      className="font-bold line-clamp-2"
                    >
                      {item.name}
                    </span>
                  </td>
                  <td className={tbodyTdStyle}>
                    <span
                      className={classNames('mx-auto block w-fit rounded-full text-xs text-white py-1 px-2', {
                        [carbonColorClass(item.carbon_tag)]: true
                      })}
                    >
                      {item.carbon_tag}
                    </span>
                  </td>
                  <td className={tbodyTdStyle}>
                    <span className="text-center block">{item.vintage}</span>
                  </td>
                  <td className={tbodyTdStyle}>
                    <span className="text-center block">{formatNumberByComma(item.quantity ? +item.quantity : 0)}</span>
                  </td>
                  <td className={tbodyTdStyle}>
                    <span className="text-center block">${formatNumberByComma(item.price ? +item.price : 0)}</span>
                  </td>
                  <td className={tbodyTdStyle}>
                    <span className="text-center block">{item.min_order_quantity}</span>
                  </td>
                  <td className={tbodyTdStyle}>
                    <span
                      onClick={() => pauseOrder(item)}
                      className="text-center block text-navy-blue cursor-pointer hover:text-light-blue"
                    >
                      停止交易
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="flex flex-col justify-center items-center mt-5">
          <img src="/images/sales/emptyOrder.png" alt="目前尚未有上架中的碳權  " />
          <p className="text-grey mt-2">目前尚未有上架中的碳權</p>
          <button
            onClick={goToSales}
            className="mt-6 py-2 w-[200px] rounded-full text-white mx-auto my-2 bg-navy-blue hover:bg-blue20"
          >
            立即新增
          </button>
        </div>
      )}
    </>
  );
};
export default SalesLaunchedTable;
