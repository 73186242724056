import { yupResolver } from '@hookform/resolvers/yup';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import classNames from 'classnames';
import { ChangeEvent, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import Button from '@/components/Kit/Button';
import TextField from '@/components/Kit/Textfield';
import LightLayout from '@/components/Layout/LightLayout';
import NoticeScrollModal from '@/components/Modal/NoticeScrollModal';
import { ModalType, runTask, useModalStore } from '@/store/modal';
import { useSnackbar } from '@/store/snackBar';
import { useStockListStore } from '@/store/stockList';
import { carbonColorClass, formatNumberByComma } from '@/util/helper';
import { useInnerHeight } from '@/util/useInnerHeight';

type CarbonItemProps = {
  title: string;
  value?: string | number | null;
};
const CarbonItem = ({ title, value }: CarbonItemProps) => {
  return (
    <div className="flex items-center gap-2 mb-4 max-w-[400px]">
      <div className="text-grey">{title}</div>
      <div className="ml-auto text-grey">{value?.toString() || ''}</div>
    </div>
  );
};
const LaunchedSetting = () => {
  const navigate = useNavigate();
  const { openSnackbar } = useSnackbar();

  const open = useModalStore((store) => store.open);
  const selectStock = useStockListStore((state) => state.selectStock);
  const updateStockOnSale = useStockListStore((state) => state.updateStockOnSale);
  const [confirmModal, setConfirmModal] = useState(false);
  const innerHeight = useInnerHeight();

  const schema = yup
    .object({
      launch_num: yup
        .string()
        .required('上架數量不可為空')
        .min(1, '上架數量不可小於1')
        .max(selectStock?.available_sale_quantity || 0)
        .matches(/^[0-9]*$/, '請輸入正確的數字'),
      price: yup.string().required('此為必填欄位').min(1),
      min_order_quantity: yup.string().required('此為必填欄位').min(1, '最低購買數量不可小於1')
    })
    .required();

  type FormTypes = yup.InferType<typeof schema>;
  const [submitData, setSubmitData] = useState<FormTypes | null>(null);

  const {
    register,
    reset,
    resetField,
    formState: { errors },
    setValue,
    handleSubmit
  } = useForm({
    resolver: yupResolver(schema)
  });

  const handleCloseAlert = () => {
    open(ModalType.LaunchedSettingLeave, {
      buttons: [
        {
          text: '取消',
          isOutline: true
        },
        {
          text: '確認',
          onClick: () => {
            navigate('/sales');
          }
        }
      ]
    });
  };
  const openConfirm = handleSubmit(async (data) => {
    console.log({ data });

    setSubmitData(data);
    setConfirmModal(true);
  });
  const onSubmit = async () => {
    if (!submitData) return;
    const { launch_num, price, min_order_quantity } = submitData;
    await runTask(async () => {
      if (selectStock?.carbon_credit) {
        const isSuccess = await updateStockOnSale(
          selectStock?.carbon_credit,
          +launch_num,
          +price,
          +min_order_quantity,
          <></>
        );
        if (isSuccess) {
          openSnackbar('此碳權商品已上架');
          navigate('/sales/launched');
        }
      }
    });
  };

  useEffect(() => {
    if (!selectStock) {
      console.log('select stock is null');
      // navigate('/sales');
    }
  }, [selectStock]);
  return (
    <LightLayout>
      <div className="p-4 mt-4 yellowScroll overflow-auto" style={{ height: innerHeight - 86 }}>
        <div className="flex items-center gap-2">
          <Button
            onClick={handleCloseAlert}
            className="bg-transparent border border-navy-blue !text-navy-blue rounded-[3px] p-1  flex items-center text-sm gap-1"
          >
            <ArrowBackIcon />
            返回
          </Button>

          <h2 className="text-navy-blue text-[28px]">
            <span className="text-navy-blue px-2 font-semibold">|</span>上架碳權設定
          </h2>
        </div>
        <div className="flex justify-center items-center">
          <div className="bg-white rounded-2xl max-w-[800px] min-w-[600px] p-6">
            <div className="bg-white20 p-4 rounded-2xl border border-white30">
              <div
                className={classNames('rounded-2xl text-white py-1 px-2 text-xs w-fit mb-2', {
                  [carbonColorClass(selectStock?.carbon_tag, 'bg')]: true
                })}
              >
                {selectStock?.carbon_tag || ''}
              </div>
              <h1 className="text-lg font-semibold text-dark-grey mb-4">{selectStock?.name}</h1>
              <CarbonItem title="Vintage" value={selectStock?.vintage} />
              <CarbonItem title="平均單價(USD)" value={formatNumberByComma(selectStock?.price || 0)} />
              <CarbonItem title="持有數量(噸)" value={selectStock?.available_sale_quantity} />
            </div>
            <form className="flex flex-col gap-4 mt-6" onSubmit={openConfirm}>
              <TextField
                id="launch_num"
                label="上架數量(噸)"
                placeholder="請輸入想要上架的數量"
                register={register}
                resetField={resetField}
                errors={errors}
                type="number"
                setValue={setValue}
                defaultValue={selectStock?.available_sale_quantity || 0}
                max={selectStock?.available_sale_quantity}
              />
              <TextField
                id="price"
                label="每噸單價(USD)"
                placeholder="請輸入每噸的單價"
                register={register}
                resetField={resetField}
                errors={errors}
                type="number"
                setValue={setValue}
                defaultValue={selectStock?.price?.toFixed(0) || 0}
              />
              <TextField
                id="min_order_quantity"
                label="最低購買數量(噸)"
                placeholder="請輸入最低購買數量"
                register={register}
                resetField={resetField}
                errors={errors}
                setValue={setValue}
                type="number"
                defaultValue={1}
              />
              <div className="flex justify-end mt-4 gap-3">
                <button
                  type="button"
                  onClick={handleCloseAlert}
                  className="border border-navy-blue !text-navy-blue bg-transparent px-4 py-2 rounded-xl"
                >
                  取消
                </button>
                <button type="submit" className="bg-navy-blue px-4 py-2 rounded-xl text-white">
                  確認
                </button>
              </div>
              <NoticeScrollModal
                isOpen={confirmModal}
                close={() => setConfirmModal(false)}
                onCheckout={onSubmit}
                buttonText="同意並上架"
              />
            </form>
          </div>
        </div>
      </div>
    </LightLayout>
  );
};
export default LaunchedSetting;
