import AddCircleIcon from '@mui/icons-material/AddCircle';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { blue, grey } from '@mui/material/colors';
import classNames from 'classnames';
import { useCallback, useMemo, useState } from 'react';

import { ExtendedCart as CartItemType } from '@/libs/api';
import { useCartStore } from '@/store/cart';
import { ModalType, useModalStore } from '@/store/modal';
import { OrderStatus } from '@/type';
// import { MIN_CART_QTY } from '@/util/constants';
import { formatNumberByComma } from '@/util/helper';
import isValidNumber from '@/util/isValidNumber';

interface CartItemIProps extends CartItemType {
  selected: boolean;
  onSelectedChange: (selected: boolean) => void;
}
const CartItem = (props: CartItemIProps) => {
  const {
    selected,
    id,
    name,
    cover_image,
    remaining_quantity,
    order,
    order_deleted,
    company_code,
    carbon_tag,
    min_order_quantity,
    onSelectedChange
  } = props;

  const [qty, setQty] = useState(props.quantity || +min_order_quantity);

  const updateCartItemQty = useCartStore((store) => store.updateCartItemQty);
  const deleteCartItem = useCartStore((store) => store.deleteCartItem);
  const open = useModalStore((store) => store.open);

  const price = props.price || 0;

  const onDeleteCartItem = useCallback(() => {
    open(ModalType.DeleteCartItem, {
      buttons: [
        { text: '取消', isOutline: true },
        {
          text: '確定',
          onClick: () => deleteCartItem(id)
        }
      ]
    });
  }, [id]);

  const isOffShelve = useMemo(() => order_deleted === OrderStatus.OffShelve, []);
  const isCannotBuy = remaining_quantity === '0';

  const onQuantityAdjust = useCallback(
    (newQty: number) => {
      if (isOffShelve) return;
      if (newQty >= +min_order_quantity && newQty <= parseInt(remaining_quantity)) {
        setQty(newQty);
        updateCartItemQty(id, {
          quantity: newQty,
          order
        });
      }
    },
    [qty]
  );

  return (
    <div
      className={classNames('py-4 rounded-lg direction-ltr shadow-cart-item px-6 border-l-[10px] bg-white', {
        'border-light-green': carbon_tag === '綠碳',
        'border-light-blue': carbon_tag === '藍碳',
        'border-brand-yellow': carbon_tag === '黃碳',
        'border-brand-other': carbon_tag === '其他'
      })}
      onClick={() => {
        if (isOffShelve) return;
        onSelectedChange(!selected);
      }}
    >
      <div className="flex">
        <div className="ml-auto">
          <CloseIcon className="text-grey10 cursor-pointer" onClick={onDeleteCartItem} />
        </div>
      </div>
      <div className="flex items-center justify-between">
        <div className="flex items-center justify-between max-w-[60%]">
          <div className="min-w-[20px]">
            {selected ? (
              <CheckCircleIcon sx={{ color: blue[800] }} />
            ) : (
              <RadioButtonUncheckedIcon sx={{ color: grey[500] }} />
            )}
          </div>
          <div className="w-[114px] aspect-square ml-4">
            <img src={cover_image} className={classNames('w-full h-full object-cover rounded-[10px]')} alt="sacurn" />
          </div>
          <div className="ml-5 flex flex-col justify-between h-full w-[180px] min-w-[180px] xl:min-w-[280px]">
            <p className={classNames('font-bold text-lg leading-[20px]  mr-2 my-2')}>{name}</p>
            <p className="mb-2 text-sm text-grey10">企業代號 : {company_code}</p>
            <p className="text-sm text-grey">{formatNumberByComma(price.toString() as string)} USD /噸</p>
          </div>
        </div>
        <div className="flex justify-between items-center gap-2">
          <div className="flex flex-wrap xl:flex-nowrap justify-center items-center gap-3">
            <div>
              <p
                className={classNames(
                  'text-xs xl:text-sm mb-2 whitespace-nowrap font-medium text-grey leading-9 text-center',
                  {
                    'text-bright-red': isCannotBuy,
                    'text-grey': !isCannotBuy && !selected
                  }
                )}
              >
                {isCannotBuy ? '剩下 0 噸無法交易' : `剩下 ${remaining_quantity} 噸可購`}
              </p>
              <div className="flex items-center gap-1.2 mb-2" onClick={(e) => e.stopPropagation()}>
                <RemoveCircleIcon
                  className={classNames('cursor-pointer text-grey10', qty == +min_order_quantity && 'text-white30')}
                  sx={{ fontSize: '22px' }}
                  onClick={() => onQuantityAdjust(qty - 1)}
                />
                <input
                  className="min-w-[80px] max-w-14 rounded-md border border-[#B3B4B4] bg-transparent text-center text-bright-blue text-base leading-normal tracking-[0.695px] font-bold flex items-center justify-center"
                  type="number"
                  value={qty}
                  onChange={(e) => {
                    if (isValidNumber(e.target.value)) {
                      onQuantityAdjust(parseInt(e.target.value));
                    }
                  }}
                />
                <AddCircleIcon
                  className={classNames(
                    'cursor-pointer text-grey10',
                    qty == parseInt(remaining_quantity) && 'text-white30'
                  )}
                  sx={{}}
                  onClick={() => onQuantityAdjust(qty + 1)}
                />
              </div>
              {+min_order_quantity > 1 && (
                <p
                  className={classNames('text-xs xl:text-sm mb-2 whitespace-nowrap font-medium text-grey text-center')}
                >
                  最低購買數量 {min_order_quantity}
                </p>
              )}
            </div>
          </div>

          {/* <button className="mr-7">
          <img
            src="/images/cart/ic_delete.svg"
            width={23}
            height={27}
            className="w-6 h-7"
            alt="sacurn"
            onClick={onDeleteCartItem}
          />
        </button> */}
          {/* <DeleteIcon fontSize="medium" sx={{ color: grey[500] }} onClick={onDeleteCartItem} className="cursor-pointer" /> */}
        </div>
      </div>
      <hr className="border-b border-[#fafafa] my-3" />
      <p className="text-xl font-bold text-black whitespace-nowrap min-w-[120px] text-right">
        {formatNumberByComma(qty * price)} USD
      </p>
    </div>
  );
};

export default CartItem;
