/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `1` - Upgrade
 * * `2` - Extend
 * * `3` - Reissue
 * * `4` - Revoke
 */
export enum ActionEnum {
    '_1' = 1,
    '_2' = 2,
    '_3' = 3,
    '_4' = 4,
}
