import dayjs from 'dayjs';
import { create } from 'zustand';

import { TransactionBuySerializers } from '@/libs/api';
import apiClient from '@/libs/api/client';
import type { Filters } from '@/types/historical';
import calcRange from '@/util/calcRange';

import { runTask } from './modal';

export type TransactionBuyState = {
  transactionBuyList: TransactionBuySerializers[];
  unpaidNum: number;
  filters: Filters;
  updateUnpaidNum: () => void;
  updateTransactionBuyListByFilters: (filters: Filters) => void;
  getTransactionBuyList: (...arg: Parameters<typeof apiClient.trade.tradeTransactionBuyList>) => void;
};

export const useTransactionBuyStore = create<TransactionBuyState>((set, get) => ({
  transactionBuyList: [],
  unpaidNum: 0,
  filters: {
    range: undefined,
    status: '0'
  },
  updateUnpaidNum: async () => {
    let num = 0;
    await runTask(async () => {
      const filters = get().filters;
      if (!filters.range) {
        const startDate = dayjs().subtract(14, 'day').toDate();
        const endDate = dayjs().toDate();
        filters.range = `${calcRange(startDate, endDate)}`;
      }
      const response = await apiClient.trade.tradeTransactionBuyList('', 1, filters.range, '0');
      num = response.results?.length || 0;
    });
    set({ unpaidNum: num });
  },
  updateTransactionBuyListByFilters: (filters) => {
    set({
      filters: {
        ...get().filters,
        ...filters
      }
    });
    get().getTransactionBuyList();
  },
  getTransactionBuyList: async () => {
    await runTask(async () => {
      const filters = get().filters;
      if (!filters.range) {
        const startDate = dayjs().subtract(14, 'day').toDate();
        const endDate = dayjs().toDate();
        filters.range = `${calcRange(startDate, endDate)}`;
      }
      const response = await apiClient.trade.tradeTransactionBuyList(
        filters.keyword,
        filters.page,
        filters.range,
        filters.status
      );
      set({ transactionBuyList: response.results });
    });
  }
}));
