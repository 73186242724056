import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import Button from '@/components/Kit/Button';
import Navbar from '@/components/Navbar';
import { BASE_URL } from '@/constant';
import { useCertificateStore } from '@/store/certificate';
import { ModalType, useModalStore } from '@/store/modal';
const Certificate = () => {
  const { carbonId } = useParams();

  const applyCertificate = useCertificateStore((state) => state.applyCertificate);
  const openModal = useModalStore((state) => state.open);
  const [isPdfLoaded, setIsPdfLoaded] = useState(false);

  const downloadImg = () => {
    const a = document.createElement('a');
    a.href = `${BASE_URL}/carbon_credit/certificate/?carbon_credit=${carbonId}#navpanes=0&statusbar=0`;
    a.target = '_blank';
    a.download = 'certificate.pdf';
    a.click();
  };
  return (
    <div>
      <Navbar className="relative z-30 !bg-navy-blue h-[70px]" />
      <div className="my-7 px-8">
        <div className="flex items-center gap-2 mb-4">
          <Link to="/sales">
            <Button className="bg-transparent border border-navy-blue !text-navy-blue rounded-[3px] p-1  flex items-center text-sm gap-1">
              <ArrowBackIcon />
              返回
            </Button>
          </Link>

          <h2 className="text-navy-blue text-[28px]">
            <span className="text-navy-blue px-2 font-semibold">|</span>憑證認證書
          </h2>
        </div>
        <div className="flex flex-col justify-center items-center mt-2">
          {/* <div className="w-[1033px] h-[730px] shadow-certificate-shadow"> */}
          <div className="h-[70vh] min-h-[600px] aspect-[1033/730] shadow-certificate-shadow">
            <iframe
              src={`${BASE_URL}/carbon_credit/certificate/?carbon_credit=${carbonId}#toolbar=0&navpanes=0&statusbar=0&view=FitH`}
              onLoad={() => setIsPdfLoaded(true)}
              className="w-full h-full"
            >
              你的瀏覽器不支援 iframe
            </iframe>
          </div>
          <div className="flex justify-end items-end w-full mt-4">
            <div className="flex justify-between w-[56%]">
              {isPdfLoaded && (
                // <CustomButton
                //   onClick={() =>
                //     openModal(ModalType.StartApplyCertificate, {
                //       buttons: [
                //         {
                //           text: '確認申請',
                //           onClick: () => applyCertificate(carbonId)
                //         }
                //       ]
                //     })
                //   }
                //   className="rounded-[3px] py-2 px-12 flex items-center text-xl gap-2.5"
                // >
                //   下載證書
                //   <img src="/images/certificate/download-icon.svg" alt="download" />
                // </CustomButton>
                <Button
                  onClick={downloadImg}
                  className="!bg-grey flex items-center gap-1 hover:!bg-grey10 active:!bg-dark-grey"
                >
                  <FileDownloadOutlinedIcon />
                  Download
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Certificate;
