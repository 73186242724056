import 'react-datepicker/dist/react-datepicker.css';

import dateFormat from 'dateformat';
import ReactDatePicker from 'react-datepicker';
import { Controller, FieldValues, Path, useFormContext } from 'react-hook-form';

type DatePickerFieldProps<T extends FieldValues> = {
  name: Path<T>;
};

const DatePickerField = <T extends FieldValues>(props: DatePickerFieldProps<T>) => {
  const { name } = props;

  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <ReactDatePicker
          dateFormat="yyyy/MM/dd"
          placeholderText="YYYY-MM-DD"
          selected={value ? new Date(value) : null}
          onChange={(date) => {
            if (date) {
              onChange(dateFormat(date, 'yyyy-mm-dd'));
            }
          }}
          className="font-istok-web block text-field w-[187px] h-[33px] bg-white rounded-[45px] shadow-input-field-shadow text-[13px] font-bold not-italic leading-normal px-[14px]"
          maxDate={new Date()}
          showYearDropdown
          dateFormatCalendar="MMMM"
          yearDropdownItemNumber={100}
          scrollableYearDropdown
        />
      )}
    />
  );
};

export default DatePickerField;
