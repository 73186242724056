import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { FieldValues, Path, useFormContext } from 'react-hook-form';

type SelectFieldProps<T extends FieldValues> = {
  name: Path<T>;
  label: string;
  options: { name: string; value: string }[];
  width: string;
  onChange?: (value: string) => void;
};

const SelectField = <T extends FieldValues>(props: SelectFieldProps<T>) => {
  const { name, label, options, width, onChange } = props;
  const { register, watch } = useFormContext();

  const formRegister = register(name);
  const value = watch(name);

  return (
    <FormControl
      sx={{
        width: width,
        '& .MuiInputBase-input': {
          color: '#000'
        }
      }}
      size="small"
    >
      <Select
        {...formRegister}
        onChange={(e: SelectChangeEvent<string>) => {
          formRegister.onChange(e);
          if (onChange) onChange(e.target.value);
        }}
        value={value ? value : ''}
        IconComponent={KeyboardArrowDownIcon}
        sx={{
          backgroundColor: '#fff',
          boxShadow: '0px 21.893px 63.415px 0px #F0F0F0, 0px 6.04px 23.403px 0px rgba(0, 0, 0, 0.05)',
          borderRadius: '45px',
          fontSize: '13px',
          fontWeight: 700,
          height: '33px',
          '& .MuiSvgIcon-root': { color: '#888' },
          '.MuiOutlinedInput-notchedOutline': { border: 0 },
          '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
            border: 0
          },
          '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: 0
          },
          '& legend': { display: 'none' },
          '& fieldset': { top: 0 },
          '& .MuiSelect-select .notranslate::after': {
            content: `"${label || 'Location'}"`,
            opacity: 1,
            color: '#000',
            fontWeight: 700,
            fontSize: '13px'
          }
        }}
        // changed menu item color
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left'
          },
          sx: {
            marginTop: '10px',
            '& .MuiMenuItem-root': {
              left: '0 !important',
              // width: '100px',
              color: '#545454',
              fontWeight: 700,
              fontSize: '13px',
              backgroundColor: '#FFFFFF4D',
              padding: '5px 0'
            },
            // remove background color from hover state
            '& .MuiMenuItem-root:hover': {
              backgroundColor: 'transparent'
            },
            // remove background color from selected state
            '& .Mui-selected': {
              backgroundColor: 'transparent'
            },
            '& .MuiMenu-paper': {
              padding: '0 1rem',
              border: '2px solid #DFDFDF',
              borderRadius: '5px'
            }
          }
        }}
      >
        {options.map((option) => {
          return (
            <MenuItem
              key={option.value}
              value={option.value}
              sx={{
                '&.MuiMenuItem-root.Mui-selected': {
                  backgroundColor: 'transparent'
                }
              }}
            >
              {option.name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default SelectField;
