import { useCallback, useEffect, useState } from 'react';

export const useInnerHeight = () => {
  const [innerHeight, setInnerHeight] = useState<number>(window.innerHeight);

  const handleResize = () => {
    setInnerHeight(window.innerHeight);
  };
  const clearResized = useCallback(() => {
    window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return clearResized;
  }, []);

  return innerHeight;
};
