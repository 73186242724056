import React from 'react';

import MemberLayout from '@/components/Layout/MemberLayout';

import EnterpriseAccountInfo from './EnterpriseAccountinfo';
const EnterpriseAccount = () => {
  return (
    <MemberLayout>
      <EnterpriseAccountInfo />
    </MemberLayout>
  );
};

export default EnterpriseAccount;
