import { CarbonTag } from '@/type';

export const getDaysOldDate = (days: number) => {
  const date = new Date();
  date.setDate(date.getDate() - days);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return `${year}-${month}-${day}`;
};

export const convertDateToFormat = (date: Date) => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return `${year}-${month}-${day}`;
};

export const convertFormatToDate = (date: string) => {
  const [year, month, day] = date.split('-');
  return new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
};

export const formatNumberByComma = (value: string | number) => {
  const updatedValue = value?.toString();
  const sanitizedValue = updatedValue?.replace(/,/g, '');
  return Number(sanitizedValue)?.toLocaleString();
};

export const ellipsisText = (text: string, length: number) => {
  return text.length > length ? text.slice(0, length) + '...' : text;
};

export const carbonColorClass = (carbonTag: string | undefined, target: string = 'bg') => {
  let colorText: string;
  switch (carbonTag) {
    case CarbonTag.Yellow:
      colorText = 'brand-yellow';
      break;
    case CarbonTag.Blue:
      colorText = 'light-blue';
      break;
    case CarbonTag.Green:
      colorText = 'light-green';
      break;
    case CarbonTag.Other:
    default:
      colorText = 'brand-other';
  }
  return `${target}-${colorText}`;
};
export const carbonTypeChToEn = (carbonTag: string | undefined) => {
  switch (carbonTag) {
    case CarbonTag.Yellow:
      return 'brand-yellow';
    case CarbonTag.Blue:
      return 'blue';
    case CarbonTag.Green:
      return 'green';
    case CarbonTag.Other:
      return 'other';
    default:
      return 'other';
  }
};
