import { Icon } from '@mui/material';
import classNames from 'classnames';

import { useOrderStore } from '@/store/order';
import { useTransactionBuyStore } from '@/store/transactionBuy';
import type { SideBarItem } from '@/types/historical';
import { OrderStatus } from '@/types/historical';

type Props = {
  item: SideBarItem;
  isActive: boolean;
  color: 'blue' | 'green';
  handleStatusChange: (newStatus: OrderStatus) => void;
};

const HistoricalSidbarItem = ({ item, isActive, color, handleStatusChange }: Props) => {
  const page = useOrderStore((state) => state.page);
  const unpaidNum = useTransactionBuyStore((state) => state.unpaidNum);
  return (
    <div
      onClick={() => handleStatusChange(item.status)}
      className={classNames(
        'text-grey px-3 py-2 rounded-md text-base xl:text-mdbase flex gap-2 items-center cursor-pointer hover:bg-light-gray hover:text-navy-blue transition-all duration-200 whitespace-nowrap',
        {
          '!bg-navy-blue !text-white': isActive && color === 'blue',
          '!bg-light-green !text-white': isActive && color === 'green',
          'hover:!text-light-green': !isActive && color === 'green'
        }
      )}
    >
      <Icon component={item.icon} />
      <h1>{item.title}</h1>
      {item.title === '待付款' && page === 'order-list' && unpaidNum !== 0 && (
        <div className="bg-warning-red !text-white px-[6px] rounded-full text-xs">{unpaidNum}</div>
      )}
    </div>
  );
};
export default HistoricalSidbarItem;
