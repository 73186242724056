/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { RestAuthDetail } from '../models/RestAuthDetail';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class LogoutService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Calls Django logout method and delete the Token object
     * assigned to the current User object.
     *
     * Accepts/Returns nothing.
     * @returns RestAuthDetail
     * @throws ApiError
     */
    public logoutCreate(): CancelablePromise<RestAuthDetail> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/logout/',
        });
    }

}
