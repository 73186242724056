import { yupResolver } from '@hookform/resolvers/yup';
import { difference } from 'lodash';
import { useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { useCompanyStore } from '@/store/company';
import { useUserStore } from '@/store/user';
import {
  CompanyRegistrationSteps,
  COUNTY_LIST,
  REGION_AREA_LIST,
  REGISTRATION_COMPLETED_STATUS,
  REGISTRATION_PENDING_STATUS,
  URBAN_AREA_LIST
} from '@/util/constants';

import CompanyTextInputField from './CompanyTextInputField';
import CustomButton from './CustomButton';
import FilesUploadField, { FileItem } from './FilesUploadField';
import SelectField from './SelectField';

const maxFileSize = 2; // Max 2MB per file
interface IProps {
  nextStep: (val: number) => void;
}

const schema = yup
  .object({
    name: yup.string().required('Name is required'),
    registration_number: yup
      .string()
      .required('統一編號是必填的')
      .length(8, '統一編號必須是8位數')
      .matches(/^\d{8}$/, '統一編號必須是8位數')
      .test('is-valid', '無效的統一編號', function (value) {
        const weights = [1, 2, 1, 2, 1, 2, 4, 1];
        const nums = value?.split('').map(Number);
        let sum = 0;
        for (let i = 0; i < 8; i++) {
          const product = nums[i] * weights[i];
          sum += Math.floor(product / 10) + (product % 10);
        }
        return sum % 10 === 0 || (sum + nums[6]) % 10 === 0;
      }),
    representative: yup
      .string()
      .required('Representative is required')
      .matches(/^[\u4e00-\u9fa5]+$/, 'Representative must be in Chinese'),
    capital: yup
      .number()
      .typeError('Capital must be a number')
      .required('Capital is required')
      .positive('Capital must be a positive number')
      .integer('Capital must be an integer'),
    founding_date: yup
      .string()
      .default(() => new Date().toISOString().slice(0, 10))
      .required('請輸入正確企業設立日期'),
    tel: yup
      .string()
      .required('tel number is required')
      .matches(/^(09\d{8}|0[1-8]\d{7,8})$/, '例如：0x-00001111 或 09xx-000111'),
    tel_extension: yup.string().optional(),
    finance_contact_person_name: yup.string().required('finance_contact_person_name is required'),
    finance_contact_person_number: yup
      .string()
      .required('Financial contact number is required')
      .matches(/^(09\d{8}|0[1-8]\d{7,8})$/, '例如：0x-00001111 或 09xx-000111'),
    finance_contact_person_extension: yup.string().optional(),
    address: yup.object({
      postcode: yup.string().required(),
      city: yup.string().required(),
      district: yup.string().required(),
      detailAddress: yup.string().required()
    }),
    contact_address: yup.object({
      postcode: yup.string().required(),
      city: yup.string().required(),
      district: yup.string().required(),
      detailAddress: yup.string().required()
    }),
    registration_document: yup
      .mixed<FileItem[]>()
      .default([])
      .test('fileSize', `檔案大小超過${maxFileSize}MB`, (items) => {
        if (items.length > 0) {
          for (const item of items) {
            if (item instanceof File && item.size > maxFileSize * 1024 * 1024) {
              return false;
            }
          }
        }
        return true;
      })
      .test('fileName', '重複上傳同一份檔案', (items) => {
        if (items.length > 0) {
          const fileNameList = items
            .map((item) => (item instanceof File ? item.name : ''))
            .filter((name) => name !== '');
          // ref: https://stackoverflow.com/questions/19655975/check-if-an-array-contains-duplicate-values
          return fileNameList.length === new Set(fileNameList).size;
        }
        return true;
      })
      .test('filesLength', '請上傳營業登記文件', (items) => items.length >= 1)
  })
  .required();

type CompanyInfoFormTypes = yup.InferType<typeof schema>;

const CompanyInfoForm = ({ nextStep }: IProps) => {
  const [isChecked, setIsChecked] = useState(false);

  const formMethods = useForm<CompanyInfoFormTypes>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: '',
      registration_number: '',
      capital: undefined,
      founding_date: '',
      tel: '',
      tel_extension: '',
      finance_contact_person_name: '',
      finance_contact_person_number: '',
      finance_contact_person_extension: '',
      address: {
        postcode: '',
        city: '',
        district: '',
        detailAddress: ''
      },
      contact_address: {
        postcode: '',
        city: '',
        district: '',
        detailAddress: ''
      },
      registration_document: []
    }
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue
  } = formMethods;

  console.log('errors', errors);
  console.log('values', formMethods.watch());

  const company = useCompanyStore((state) => state.company);
  const getCompanyId = useUserStore((state) => state.getCompanyIdUserBelongTo);
  const createCompany = useCompanyStore((state) => state.createCompany);
  const updateCompany = useCompanyStore((state) => state.updateCompany);
  const getCompany = useCompanyStore((state) => state.getCompany);

  const addressCity = formMethods.watch('address.city');
  const contractAddressCity = formMethods.watch('contact_address.city');

  useEffect(() => {
    async function init() {
      const companyData = await getCompany();
      if (!companyData) return;
      if (companyData.status === REGISTRATION_COMPLETED_STATUS || companyData.status === REGISTRATION_PENDING_STATUS) {
        return nextStep(CompanyRegistrationSteps.REGISTRATION_COMPLETED);
      }

      console.log('companyData', companyData);
      for (const [keyName] of Object.entries(getValues())) {
        const key = keyName as keyof CompanyInfoFormTypes;
        const value = companyData[key];
        if (value) {
          // Put data from api into react hook form
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          setValue(key, value as any);
        }
      }

      if (
        companyData.address &&
        companyData.contact_address &&
        JSON.stringify(companyData.address) === JSON.stringify(companyData.contact_address)
      ) {
        setIsChecked(true);
      }
    }

    init();
  }, []);

  const onSyncAddress = (isChecked: boolean) => {
    setIsChecked(isChecked);
    if (isChecked) {
      setValue('contact_address', getValues('address'));
    }
  };

  const onSubmit = handleSubmit(async (data) => {
    const formData = new FormData();

    for (const [key, value] of Object.entries(data)) {
      console.log(`${key}: ${value}`);
      const keyName = key as keyof typeof data;

      if (!data[keyName]) continue;

      if (typeof data[keyName] === 'object') {
        formData.append(key, JSON.stringify(value));
      } else {
        formData.append(key, value.toString());
      }
    }

    formData.delete('registration_document');
    if (difference(data.registration_document, company.registration_document || []).length > 0) {
      data.registration_document.forEach((doc) => formData.append('registration_document', doc));
    }

    const companyId = getCompanyId();
    let isSuccess = false;
    if (companyId) {
      isSuccess = await updateCompany(formData);
    } else {
      isSuccess = await createCompany(formData);
    }
    if (isSuccess) nextStep(CompanyRegistrationSteps.REPRESENTATIVE_INFO_FORM);
  });

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={onSubmit}>
        <div className="flex mt-15 justify-center gap-10">
          <div className="flex flex-col gap-5">
            <CompanyTextInputField
              name="name"
              label="企業名稱"
              errors={errors}
              errorMessage="會員名稱即為提領的金融帳號戶名，請正確填寫"
              placeholder="企業名稱即為交易用的金融帳號戶名，請正確填寫"
              labelStyle="!min-w-[200px]"
            />
            <CompanyTextInputField
              name="registration_number"
              label="統一編號"
              placeholder="請輸入統一編號"
              errors={errors}
              errorMessage="請輸入正確統一編號"
              labelStyle="!min-w-[200px]"
            />
            <CompanyTextInputField
              name="representative"
              label="代表人中文姓名"
              errors={errors}
              labelStyle="!min-w-[200px]"
              errorMessage="請輸入中文姓名"
            />
            <CompanyTextInputField
              name="capital"
              label="實收資本額"
              errors={errors}
              errorMessage="請輸入資本額數字"
              labelStyle="!min-w-[200px]"
            />
            <div className="flex gap-3 items-baseline">
              <label className="text-black text-base not-italic font-normal leading-normal text-right min-w-[200px]">
                核准設立日期 :
              </label>
              <div>
                <ReactDatePicker
                  dateFormat="yyyy/MM/dd"
                  selected={getValues('founding_date') ? new Date(getValues('founding_date')) : new Date()}
                  onChange={(date) => {
                    date && setValue('founding_date', date?.toISOString()?.split('T')[0], { shouldValidate: true });
                  }}
                  className="font-istok-web block text-field w-[368px] h-[33px] bg-white rounded-[45px] shadow-input-field-shadow text-[13px] font-bold not-italic leading-normal px-[14px] py-3"
                  maxDate={new Date()}
                  showYearDropdown
                  dateFormatCalendar="MMMM"
                  yearDropdownItemNumber={40}
                  scrollableYearDropdown
                />
                {errors.founding_date?.message && (
                  <p className="text-xs mt-1 ml-2 text-bright-red">{errors.founding_date.message}</p>
                )}
              </div>
            </div>
            <CompanyTextInputField
              name="tel"
              label="聯絡電話"
              placeholder="例：市話 XX-XXXXXXXX 或 手機 XXXXXXXXXX"
              errors={errors}
              errorMessage="請輸入正確聯絡電話"
              labelStyle="!min-w-[200px]"
            />
            <CompanyTextInputField
              name="tel_extension"
              label="分機"
              errors={errors}
              errorMessage="請輸入正確分機"
              className="!w-[118px]"
              labelStyle="!min-w-[200px]"
            />
            <CompanyTextInputField
              name="finance_contact_person_name"
              label="財務聯絡人姓名"
              errors={errors}
              errorMessage="請輸入財務聯絡人姓名"
              labelStyle="!min-w-[200px]"
            />
            <CompanyTextInputField
              name="finance_contact_person_number"
              label="財務連絡電話"
              placeholder="例：市話 XX-XXXXXXXX 或 手機 XXXXXXXXXX"
              errors={errors}
              errorMessage="請輸入正確財務連絡電話"
              labelStyle="!min-w-[200px]"
            />
            <CompanyTextInputField
              name="finance_contact_person_extension"
              label="分機"
              errors={errors}
              errorMessage="請輸入正確分機"
              className="!w-[118px]"
              labelStyle="!min-w-[200px]"
            />
          </div>

          <div className="2xl:mr-24 flex flex-col justify-between">
            <div>
              <div className="flex gap-3 items-center">
                <label className="text-black text-base not-italic font-normal leading-normal text-right min-w-[121px]">
                  公司登記地址 :
                </label>
                <div className="flex gap-1.5">
                  <SelectField
                    name="address.city"
                    width="93px"
                    label="縣市"
                    options={COUNTY_LIST.map((county) => ({
                      name: county.value,
                      value: county.value
                    }))}
                    onChange={(city) => {
                      if (!city) return;

                      const region = REGION_AREA_LIST.find((item) => item.city === city);

                      if (region) {
                        setValue('address.district', region.slug);
                        setValue('address.postcode', region.value);
                      }

                      if (isChecked) onSyncAddress(isChecked);
                    }}
                  />
                  <SelectField
                    name="address.district"
                    width="99px"
                    label="鄉鎮市區"
                    options={
                      URBAN_AREA_LIST.find((item) => item.slug === addressCity)?.value.map((district) => ({
                        name: district,
                        value: district
                      })) || []
                    }
                    onChange={(district) => {
                      if (!district) return;

                      const region = REGION_AREA_LIST.find(
                        (item) => item.city === addressCity && item.slug === district
                      );

                      if (region) setValue('address.postcode', region.value);

                      if (isChecked) onSyncAddress(isChecked);
                    }}
                  />
                  <input
                    type="text"
                    placeholder="郵遞區號"
                    className="font-istok-web block text-field w-[93px] h-[33px] bg-white rounded-[45px] shadow-input-field-shadow text-[13px] font-bold not-italic leading-normal px-[14px]"
                    {...register('address.postcode')}
                  />
                </div>
              </div>
              <div className="flex gap-3 items-baseline mt-1.5">
                <label className="text-black text-base not-italic font-normal leading-normal text-right min-w-[121px]"></label>
                <div>
                  <Controller
                    name="address.detailAddress"
                    render={({ field }) => (
                      <input
                        type="text"
                        className="font-istok-web block text-field w-[368px] h-[33px] bg-white rounded-[45px] shadow-input-field-shadow text-[13px] font-bold not-italic leading-normal px-[14px]"
                        placeholder="詳細地址"
                        {...field}
                        onChange={(e) => {
                          field.onChange(e);
                          if (isChecked) onSyncAddress(isChecked);
                        }}
                      />
                    )}
                  />

                  {(errors.address?.city ||
                    errors.address?.district ||
                    errors.address?.postcode ||
                    errors.address?.detailAddress) && (
                    <p className="text-xs mt-1 ml-2 text-bright-red">( 紅色框格請務必填寫 )</p>
                  )}
                </div>
              </div>
              <div className="flex gap-[5px] items-center mt-5 mb-[5px]">
                <label className="text-black text-base not-italic font-normal leading-normal text-right min-w-[132px]"></label>
                <input
                  type="checkbox"
                  checked={isChecked}
                  onChange={(e) => onSyncAddress(e.target.checked)}
                  className="w-[14px] h-[14px] accent-[#3F84AE]"
                />
                <label className="text-black text-base not-italic font-normal leading-normal">同公司登記地址</label>
              </div>
              <div className="flex">
                <label className="text-black text-base not-italic font-normal leading-normal text-right min-w-[121px]">
                  聯絡地址 :
                </label>
                <div className="flex gap-1.5 ml-3">
                  <SelectField
                    name="contact_address.city"
                    width="93px"
                    label="縣市"
                    options={COUNTY_LIST.map((county) => ({
                      name: county.value,
                      value: county.value
                    }))}
                    onChange={(city) => {
                      if (!city) return;

                      const region = REGION_AREA_LIST.find((item) => item.city === city);
                      if (region) {
                        setValue('contact_address.district', region.slug);
                        setValue('contact_address.postcode', region.value);
                      }
                      setIsChecked(false);
                    }}
                  />

                  <SelectField
                    name="contact_address.district"
                    width="99px"
                    label="鄉鎮市區"
                    options={
                      URBAN_AREA_LIST.find((item) => item.slug === contractAddressCity)?.value.map((district) => ({
                        name: district,
                        value: district
                      })) || []
                    }
                    onChange={(district) => {
                      if (!district) return;

                      const region = REGION_AREA_LIST.find(
                        (item) => item.city === contractAddressCity && item.slug === district
                      );
                      if (region) setValue('contact_address.postcode', region.value);

                      setIsChecked(false);
                    }}
                  />

                  <input
                    type="text"
                    placeholder="郵遞區號"
                    className="font-istok-web block text-field w-[93px] h-[33px] bg-white rounded-[45px] shadow-input-field-shadow text-[13px] font-bold not-italic leading-normal px-[14px]"
                    {...register('contact_address.postcode')}
                  />
                </div>
              </div>
              <div className="flex gap-3 items-baseline mt-1.5">
                <label className="text-black text-base not-italic font-normal leading-normal text-right min-w-[121px]"></label>
                <div>
                  <Controller
                    name="contact_address.detailAddress"
                    render={({ field }) => (
                      <input
                        type="text"
                        className="font-istok-web block text-field w-[368px] h-[33px] bg-white rounded-[45px] shadow-input-field-shadow text-[13px] font-bold not-italic leading-normal px-[14px]"
                        placeholder="詳細地址"
                        {...field}
                        onChange={(e) => {
                          field.onChange(e);
                          setIsChecked(false);
                        }}
                      />
                    )}
                  />

                  {(errors.contact_address?.city ||
                    errors.contact_address?.district ||
                    errors.contact_address?.postcode ||
                    errors.contact_address?.detailAddress) && (
                    <p className="text-xs mt-1 ml-2 text-bright-red">( 紅色框格請務必填寫 )</p>
                  )}
                </div>
              </div>
              <div className="flex gap-3 mt-5">
                <label className="text-black text-base not-italic font-normal leading-normal text-right min-w-[121px]">
                  營業登記文件 :
                </label>
                <FilesUploadField
                  name="registration_document"
                  title=" 營業登記文件檔上傳，限小於<span>2MB</span>的PDF檔案。"
                  guideDownload={{
                    fileLinkPath: '/pdf/registration-rules.pdf',
                    text: '「了解營業登記文件上傳規範」'
                  }}
                  fileSizeLimit={2}
                  acceptFileTypes="application/pdf"
                />
              </div>
            </div>

            <CustomButton
              type="submit"
              className="text-white bg-navy-blue px-4.5 py-0.7 font-bold rounded-md ml-auto mt-20"
            >
              儲存 | 下一步
            </CustomButton>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

export default CompanyInfoForm;
