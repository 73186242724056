import classNames from 'classnames';
import { el } from 'date-fns/locale';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import Button from '@/components/Kit/Button';
import SalesPieGraph from '@/components/SalesPieGraph';
import StockItemBar from '@/components/StockItemBar';
import { StockItem, useStockListStore } from '@/store/stockList';
import { CarbonTag } from '@/type';
import { carbonColorClass, carbonTypeChToEn, formatNumberByComma } from '@/util/helper';
import { useInnerHeight } from '@/util/useInnerHeight';
const TABLE_HEAD = ['種類(％)', '碳權名稱', 'Vintage', '總數量(噸)', '上架數量', '操作'];
const AREA_COLORS = ['#6B90B4', '#B29B7F', '#E0D495', '#D7B396', '#83A3B6', '#BCB595', '#AEAEAE', '#B08460'];

const linkStyle =
  'whitespace-nowrap text-navy-blue hover:underline decoration-navy-blue underline-offset-2 cursor-pointer';

const SalesAllTable = () => {
  const navigate = useNavigate();
  const [stockItemDetailId, setStockItemDetailId] = useState<StockItem['id'] | null>(null);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const innerHeight = useInnerHeight();
  const stockList = useStockListStore((store) => store.stockList);
  const getStockList = useStockListStore((store) => store.getStockList);
  const updateSeletStock = useStockListStore((store) => store.updateSeletStock);

  const isAllLaunched = useCallback(
    (stockItem: StockItem) => {
      return stockItem.quantity && typeof stockItem.available_sale_quantity === 'number'
        ? stockItem.quantity === stockItem.quantity - stockItem.available_sale_quantity
        : false;
    },
    [stockList]
  );
  const goSetting = async (stockItem: StockItem) => {
    if (isAllLaunched(stockItem)) return;
    updateSeletStock(stockItem);
    navigate(`/launched-setting/${stockItem.carbon_credit}`);
  };
  const carbonChartColors = useMemo(() => {
    if (!stockList) return [];

    // return stockList?.map((x) => {
    //   if (x.carbon_tag === CarbonTag.Blue) return '#1D70BD';
    //   if (x.carbon_tag === CarbonTag.Green) return '#68A362';
    //   if (x.carbon_tag === CarbonTag.Yellow) return '#C98E3A';
    //   if (x.carbon_tag === CarbonTag.Other) return '#FAC91C';
    //   else return '';
    // });
    return stockList?.reduce((acc, cur) => {
      let color = '';
      if (cur.carbon_tag === CarbonTag.Blue) color = '#1D70BD';
      if (cur.carbon_tag === CarbonTag.Green) color = '#68A362';
      if (cur.carbon_tag === CarbonTag.Yellow) color = '#C98E3A';
      if (cur.carbon_tag === CarbonTag.Other) color = '#FAC91C';
      if (color && !acc.find((el) => el === color)) {
        acc.push(color);
      }
      return acc;
    }, [] as string[]); // Provide an initial value for the accumulator
  }, [stockList]);

  useEffect(() => {
    getStockList();
  }, []);
  return (
    <div className="flex gap-4 xl:gap-6">
      {/* sidebar */}
      <div className="yellowScroll overflow-auto min-w-[300px] pr-2" style={{ height: innerHeight - 200 }}>
        <div className=" bg-white rounded-xl h-fit py-6 px-4 flex flex-col justify-center items-center">
          <h2 className="font-bold self-start">碳權種類</h2>
          <SalesPieGraph activeButton={1} colors={carbonChartColors} />
          <h2 className="font-bold self-start">地區</h2>
          <SalesPieGraph activeButton={2} colors={AREA_COLORS} />
        </div>
      </div>

      {/* table and search area */}
      <div className="w-full">
        {/* sales table */}
        <div
          className="yellowScroll w-full overflow-auto overflow-x-hidden flex-1 px-2"
          style={{ height: innerHeight - 200 }}
        >
          <div className="inline-block min-w-full align-middle pb-8">
            <table className="min-w-full border-separate sales-table border-spacing-y-4 my-[-16px] table-auto">
              <thead className="sticky z-10 top-[-2px] bg-neutral-250">
                <tr className="!bg-neutral-250">
                  {TABLE_HEAD?.map((item, index) => (
                    <th
                      key={item}
                      className={classNames('text-left whitespace-nowrap font-normal bg-neutral-250 px-2', {
                        '!w-[10%]': index === 0,
                        '!w-[40%]': index === 1
                      })}
                    >
                      <span
                        className={classNames('text-sm flex items-center 2xl:text-lg  text-grey tracking-[0.54px]', {
                          'justify-center': index === 3 || index === 4 || index === 5,

                          '!justify-end': item === '總數量'
                        })}
                      >
                        {item}
                      </span>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {stockList?.map((stockItem, idx) => (
                  <Fragment key={stockItem.id}>
                    <tr
                      onClick={() => setActiveIndex(idx)}
                      className={classNames('bg-white outline outline-1 outline-white30 h-auto rounded-lg', {
                        '!bg-light-gray': stockItemDetailId === stockItem.id,
                        'border-bright-blue': idx === activeIndex,
                        'border-white': idx !== activeIndex
                      })}
                    >
                      <td className="text-center">
                        {/* badge */}
                        <span
                          className={classNames(
                            'text-center text-white rounded-[10px] 2xl:text-lg text-sm p-2',
                            carbonColorClass(stockItem.carbon_tag, 'bg')
                          )}
                        >
                          {stockItem.ratio}%
                        </span>
                      </td>
                      <td className={`py-3 pr-2 xl:w-[362px]`}>
                        {/* prod detail */}
                        <div className="flex flex-col space-y-1 xl:space-y-4">
                          {/* prod name */}
                          <span
                            onClick={() =>
                              navigate(
                                `/new-product-details/${stockItem.carbon_credit}?type=${carbonTypeChToEn(stockItem.carbon_tag)}`
                              )
                            }
                            className="text-lg font-semibold text-dark-grey !leading-[19px] cursor-pointer hover:underline line-clamp-2 hover:text-navy-blue decoration-navy-blue underline-offset-2"
                          >
                            {stockItem.name}
                          </span>
                          {/* detail */}
                          <div className="flex items-center gap-[23px]">
                            {/* seriol no */}
                            <div className="flex items-center gap-1">
                              <span className="text-xs whitespace-nowrap font-medium text-grey">序號:</span>
                              <span className="text-sm font-medium text-grey10">{stockItem.serial_number}</span>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="py-2 px-2 text-dark-grey font-medium text-sm 2xl:text-lg">{stockItem.vintage}</td>
                      <td className="py-2 px-2 text-dark-grey text-sm 2xl:text-lg">
                        <span className="text-center block">{formatNumberByComma(stockItem.quantity || '')}</span>
                      </td>
                      <td className="py-2 px-2 text-dark-grey text-sm 2xl:text-lg">
                        <span className="text-center block">
                          {stockItem.quantity && typeof stockItem.available_sale_quantity === 'number'
                            ? stockItem.quantity - stockItem.available_sale_quantity
                            : '-'}
                        </span>
                      </td>
                      <td className="py-2 text-dark-grey text-sm 2xl:text-lg 2xl:w-[140px]">
                        <div className="flex gap-2 justify-center pr-4">
                          <Link className={linkStyle} to={`/certificate/${stockItem.carbon_credit}`}>
                            下載證書
                          </Link>
                          <span className="block text-grey10">|</span>
                          <span
                            onClick={() => goSetting(stockItem)}
                            className={classNames(linkStyle, {
                              [`!text-white30 hover:no-underline cursor-default`]: isAllLaunched(stockItem)
                            })}
                          >
                            上架碳權
                          </span>
                        </div>
                      </td>
                    </tr>
                  </Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SalesAllTable;
