import { create } from 'zustand';
type SnackbarState = {
  isOpen: boolean;
  vertical: 'top' | 'bottom';
  horizontal: 'left' | 'center' | 'right';
  message: string;
  openSnackbar: (message: string) => void;
  closeSnackbar: () => void;
};
export const useSnackbar = create<SnackbarState>((set) => ({
  isOpen: false,
  vertical: 'top',
  horizontal: 'center',
  message: '',
  openSnackbar: (message: string) => {
    set({ isOpen: true, message });
  },
  closeSnackbar: () => {
    set({ isOpen: false, message: '' });
  }
}));
