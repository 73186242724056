import { Tooltip } from '@material-ui/core';
import ArrowRightOutlinedIcon from '@mui/icons-material/ArrowRightOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import classNames from 'classnames';
import dateFormat from 'dateformat';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import useOrder from '@/hooks/useOrder';
import { TransactionBuySerializers, TransactionSellSerializers } from '@/libs/api';
import { useOrderStore } from '@/store/order';
import type { Order, TableHeader } from '@/types/historical';
import { OrderStatus } from '@/types/historical';
import { formatNumberByComma } from '@/util/helper';
import { useInnerHeight } from '@/util/useInnerHeight';

type Props = {
  headers: TableHeader[];
  // orders: Order[];
  orders: TransactionBuySerializers[] | TransactionSellSerializers[];
};
// Styles
const orderRow = 'outline outline-1 outline-[#dfdfdf] rounded-lg';
const orderItem = 'px-2 xl:px-4 py-6 bg-white rounded-lg';
const subOrderItem = 'pb-3 px-4 bg-white rounded-lg';
const statusTag = ' text-white  rounded-[20px] shadow-sm text-center text-xs w-fit px-4 py-1';

const OrderTable = ({ headers, orders }: Props) => {
  const { status } = useParams();
  const innerHeight = useInnerHeight();
  const navigate = useNavigate();

  const nowPage = useOrderStore((state) => state.page);
  const { transData } = useOrder();
  // 設定各別展開tr
  const [expandIdxList, setExpandIdxList] = useState<(number | never)[]>([]);

  const colLength = {
    5: 'grid-cols-7',
    6: 'grid-cols-8'
  };

  const handleExpand = (idx: number) => {
    if (expandIdxList.includes(idx)) {
      setExpandIdxList(expandIdxList.filter((item) => item !== idx));
    } else {
      setExpandIdxList([...expandIdxList, idx]);
    }
  };

  const getTimeValue = <T,>(order: T) => {
    if (status) {
      const key = transData[status]['timeKey'];
      return order[key as keyof T] || null;
    }
    return null;
  };
  return (
    <div
      className="w-full min-w-[800px] overflow-y-auto yellowScroll pr-2 xl:pr-4"
      style={{ height: innerHeight - 180 }}
    >
      <table className="w-full border-separate border-spacing-y-3 table-auto px-1">
        <thead className="sticky z-10 top-[0] bg-neutral-250">
          <tr className={classNames('grid', colLength[headers.length as keyof typeof colLength])}>
            {headers.map(
              (header) =>
                header.key === 'productName' ? (
                  <th className="px-4 text-grey whitespace-nowrap font-normal text-left col-span-3" key={header.key}>
                    {header.title}
                  </th>
                ) : (
                  <th className="px-4 text-grey whitespace-nowrap font-normal text-left" key={header.key}>
                    {header.title}
                  </th>
                )
              // <th className="px-4 text-grey whitespace-nowrap font-normal text-left" key={header.key}>
              //   {header.title}
              // </th>
            )}
          </tr>
        </thead>
        <tbody>
          {orders.map((order, idx) => (
            <tr
              className={classNames(
                orderRow,
                'grid mb-4 bg-white',
                colLength[headers.length as keyof typeof colLength]
              )}
              key={order.order_no}
            >
              <td className={classNames(orderItem, '!pr-0')} onClick={() => handleExpand(idx)}>
                <div className="flex flex-wrap items-center cursor-pointer font-bold text-xs xl:text-sm">
                  {order.order_no}
                  <ArrowRightOutlinedIcon
                    className={classNames('text-pale-yellow basis-5', {
                      'transform rotate-90': expandIdxList.includes(idx)
                    })}
                  />
                </div>
              </td>
              <td className={classNames(orderItem, 'col-span-3')}>
                <span className="font-bold">{order.orders.length} 項碳權商品</span>
                <br />
                <span className="text-navy-blue text-xs">
                  {status != null ? transData[status]['timeText'] : ''} :
                  {order.status != null && (
                    <>
                      {getTimeValue(order)
                        ? dateFormat(new Date(`${getTimeValue(order) as string}`), 'yyyy-mm-dd HH:MM:ss')
                        : '-'}
                    </>
                  )}
                </span>
              </td>
              <td className={orderItem}>-</td>
              <td className={orderItem}>-</td>
              <td className={classNames(orderItem, 'font-bold text-right w-[100px] mx-auto')}>
                $
                {nowPage === 'order-list'
                  ? formatNumberByComma((order as TransactionBuySerializers)?.total_include_tax_fee)
                  : formatNumberByComma(order.total)}
              </td>
              {/* 付款資訊 */}
              {nowPage === 'order-list' && status === OrderStatus.Unpaid && (
                <td className={orderItem}>
                  <div
                    onClick={() => navigate(`/payment-information/${order.order_no}`)}
                    className="bg-pale-yellow text-white flex items-center justify-center rounded-full w-12 h-12 shadow-sm cursor-pointer mx-auto"
                  >
                    <ArticleOutlinedIcon />
                  </div>
                </td>
              )}
              {/* 訂單狀態 */}
              {status === OrderStatus.HistoricalOrder && (
                <td className={orderItem}>
                  <div
                    className={classNames(statusTag, order.status != null ? transData[order.status]['tagColor'] : '')}
                  >
                    {order.status != null ? transData[order.status]['tagText'] : ''}
                  </div>
                </td>
              )}
              {/* Sub Row */}
              <td
                className={classNames(orderItem, 'expanded-row-content !p-0 bg-white', {
                  '!hidden': !expandIdxList.includes(idx)
                })}
              >
                <table>
                  <tbody>
                    {order.orders.map((creditProduct, idx) => (
                      <tr
                        key={idx}
                        className={classNames('grid mb-4', colLength[headers.length as keyof typeof colLength])}
                      >
                        <td className={subOrderItem}></td>
                        <td className={classNames(subOrderItem, 'col-span-3 leading-5')}>
                          <Tooltip title={creditProduct.name} placement="bottom" arrow>
                            <span className="line-clamp-2">{creditProduct.name}</span>
                          </Tooltip>
                          {'company_code' in creditProduct && (
                            <p className="text-xs text-hit-grey mt-1">企業代號：{creditProduct.company_code}</p>
                          )}
                        </td>
                        <td className={subOrderItem}>${formatNumberByComma(creditProduct.price)}</td>
                        <td className={subOrderItem}>{formatNumberByComma(creditProduct.quantity)}</td>
                        <td className={classNames(subOrderItem, 'text-right w-[100px] mx-auto')}>
                          ${formatNumberByComma(creditProduct.total)}
                        </td>
                        <td className={subOrderItem}></td>
                      </tr>
                    ))}
                    {'fee' in order && (
                      <tr className={classNames('grid mb-4', colLength[headers.length as keyof typeof colLength])}>
                        <td className={subOrderItem}></td>

                        <td className={classNames(subOrderItem, 'col-span-3')}>手續費</td>
                        <td className={classNames(subOrderItem, 'col-span-2')}></td>

                        <td className={classNames(subOrderItem, 'text-right w-[100px] mx-auto')}>
                          ${formatNumberByComma(order.fee)}
                        </td>
                      </tr>
                    )}
                    {'tax' in order && (
                      <tr className={classNames('grid mb-2', colLength[headers.length as keyof typeof colLength])}>
                        <td className={subOrderItem}></td>
                        <td className={classNames(subOrderItem, 'col-span-3')}>稅金</td>
                        <td className={classNames(subOrderItem, 'col-span-2')}></td>
                        <td className={classNames(subOrderItem, 'text-right w-[100px] mx-auto')}>
                          ${formatNumberByComma(order.tax)}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
export default OrderTable;
